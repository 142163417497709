// public routes
export const FreeUserRoutes = [
  "/",
  "/faq",
  "/privacy-policy",
  "/terms-and-condition",
  "/my-profile",
  "/settings/prefrences",
  "/my-fitness-journey",
  "/settings/notifications",
  "/settings/change-password",
  "/favorite-recipes",
  "/favorites-videos",
  "/workoutvideos",
  "/favorite-recipes/:folderName",
  "/about",
  "/login",
  "/signup",
  "/blog",
  "/blogs/:blogId",

  "/recipes",
  "/recipe/:id",
  "/store",
  "/checkout",
  "/checkout/:id",
  "/success",
  "/cancel",
  "/my-fitness-journey/my-saved-recipes",
  "/my-fitness-journey/my-favorite-videos",
  "/my-fitness-journey/my-progress-photos",
  "/recipes/latest_recipes",
  "/recipes/featured_recipes",
  "/recipes/budget_meals",
  "/recipes/easy_breakfast_ideas",
  "/recipes/healthy_desert_recipes",
  "/recipes/healthy_snack_ideas,healthy_desert_recipes,quick_and_Easy_recipes",
  "/recipes/quick_and_Easy_recipes",
  "/recipes/healthy_snack_ideas",
  "/recipes/healthy_drinks_recipes",
  "/recipes/easy_vegan_recipes",
  "/recipes/healthy_pancakes_recipes",
  "/recipes/party_food_recipes",
  "/recipes/trending_recipes",
  "/workoutvideos/Latest_Workouts",
  "/workoutvideos/Most_Popular",
  "/workoutvideos/HIIT",
  "/workoutvideos/Abs",
  "/workoutvideos/Booty",
  "/workoutvideos/Dumbbell",
  "/workoutvideos/10_Mins",
  "/workoutvideos/20_Mins+",
  "/workoutvideos/Standing_Workouts",
  "/workoutvideos/No_Jumping",
  "/workoutvideos/No_Planks",
  "/workoutvideos/Burpee_Free",
  "/workoutvideos/Wrist_Friendly",
  "/my-fitness-journey/my-journal",
  "/my-fitness-journey/workout-history",
  "/coporate-services",
  "/workout-program",
];
// premium user routes
export const PremiumUserRoutes = [
  ...FreeUserRoutes,

  "/workout-program/:workoutprogram-category",
  "/my-fitness-journey/my-schedule",
  "/workout-program/:name/:id",
  "/workout-program/:name/:id",
  "/my-fitness-journey/my-team",
  "/my-fitness-journey/my-team/create-team",
  "/my-fitness-journey/my-team/team-created",
  "/my-fitness-journey/my-team/team-directory",
  "/my-fitness-journey/my-achievements",
  "/my-fitness-journey",
  "/teams/:id",
  "/teams",
];

export const EliteUserRoutes = [
  ...PremiumUserRoutes,
  "/workoutvideos/Yoga",
  "/workoutvideos/Pilates",
];
