import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import empty from "../../assets/images/empty-folder.e57de881.svg";
import LazyLoad from "react-lazyload";
import StarRating from "../recipes-components/star-rating";
import {
  addRecipeInFolder,
  addfavRecipe,
  getSavedFolders,
  getfavRecipe,
  multiSeclectedRecipesInMultipeFolders,
} from "../../redux/recipes/recipeActions";
import RecipesSekelton from "../recipes-components/recipes-sekelton";
import { FaRegCircleCheck } from "react-icons/fa6";
import RecipeFolderSkeleton from "../recipes-components/recipe-folder-skeleton";
import ImageSkeleton from "./ImageSkeleton";
import { getprofile } from "../../redux/slices/auth";
const API_URL = process.env.REACT_APP_API_BASE_URL;

const Card = ({
  id,
  item,
  index,
  loading,
  coverPicture,
  onClick,
  favVar,
  dietary_restriction,
}) => {

  
  const [open, setOpen] = useState(false);
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const [showInput, setShowInput] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [savedFolderName, setSavedFolderName] = useState("");
  const [isFavorited, setIsFavorited] = useState(null);
  const [folderNames, setFolderNames] = useState([]);
  // const [selectedFolder, setSelectedFolder] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  
  const savedFolders = useSelector(
    (state) => state?.recipe?.savedFolder?.folders
  );

  useEffect(() => {
    const storedJsonString = localStorage.getItem("favoriteRecipes");
    const storedArray = JSON.parse(storedJsonString);
    const Favorited = storedArray?.some((recipe) => recipe?._id === item?._id);
    setIsFavorited(Favorited);
  }, [item?._id]);
  
  // useEffect(() => {
  //   dispatch(getSavedFolders());
  // }, [dispatch]);
  
  useEffect(() => {
    if (savedFolders) {
      setFolderNames(savedFolders);
    }
  }, [savedFolders]);
  
  const handleInputChange = (event) => {
    setFolderName(event.target.value);
  };
  
  const handleSave = () => {
    if (folderName) {
      setSavedFolderName(folderName);
      setFolderNames([...folderNames, folderName]);
      setShowInput(false);
      // setFolderName(""); // Clear the input field
    }
  };
  
  // const handleSelectedFolder = (folder) => {
    //   const isSelected = selectedFolder.includes(folder);
    //   if (isSelected) {
      //     setSelectedFolder(selectedFolder.filter((selected) => selected !== folder));
      //   } else {
        //     setSelectedFolder([...selectedFolder, folder]);
        //   }
        // };
        
        const dietaryDisplay = dietary_restriction?.map((tag, index) => {
          const cleanedTag = tag.replace(/_/g, " ");
          return (
            <div
            key={index}
            className="group relative cursor-pointer bg-[#d5e6ff] backdrop-blur-sm bg-opacity-50 rounded-full w-6 h-6 hover:w-20 flex items-center justify-center overflow-hidden"
            >
        <span className="text-[12px] font-semibold transition-all duration-100 ease-in-out group-hover:opacity-0">
          {cleanedTag.charAt(0).toUpperCase()}
        </span>
        <span className="absolute text-[12px] font-semibold opacity-0 transition-all duration-100 ease-in-out group-hover:opacity-100">
          {cleanedTag}
        </span>
      </div>
    );
  });
  
  const [selectedFolders, setSelectedFolders] = useState([]);
  
  const handleSelectedFolder1 = (folder) => {
    if (selectedFolders.includes(folder)) {
      setSelectedFolders(
        selectedFolders.filter((selected) => selected !== folder)
      );
    } else {
      setSelectedFolders([...selectedFolders, folder]);
    }
  };
  
  const handleAddFolder = () => {
    if (selectedFolders.length > 0) {
      dispatch(
        multiSeclectedRecipesInMultipeFolders({
          recipeId: [id],
          folderName: selectedFolders,
        })
      );
    } else {
      dispatch(addRecipeInFolder({ recipeId: id, folderName: folderName }));
    }
    
    setFolderName("");
    setOpen(false);
  };
  
  const handleAddFolderBtn = () => {
    setOpen(true);
    
    dispatch(getSavedFolders());
  };
  
  const profile = useSelector((state) => state?.auth?.profile?.content?.avatar);
  console.log("🚀 ~ profile:", profile)

// const dispatch = useDispatch();
useEffect(() => {
  dispatch(getprofile());
}, [dispatch]);
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex   items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 lg:w-[50%] w-[80%] text-left shadow-xl transition-all sm:my-8 sm:p-6">
                  <div>
                    <h2 className="text-[22px] text-[#000000]">
                      Add to Folder
                    </h2>
                    <div className="flex items-center mt-5 gap-4 pb-3 border-b-[1px] ">
                      <img
                        src={`https://api.mykilo.co.uk/cover/${item?.recipe_Cover_picture}`}
                        className="w-24 h-24 "
                        alt="cover pic"
                      />
                      <h2>{item?.recipe_title}</h2>
                    </div>
                    {!savedFolders ? (
                      <RecipeFolderSkeleton /> // Render the skeleton while loading
                    ) : (
                      <>
                        {folderNames?.length > 0 ? (
                          <div>
                            {folderNames?.map((folder, index) => (
                              <div key={index}>
                                <h3
                                  className={`my-4 cursor-pointer flex items-center justify-between p-2 rounded ${
                                    selectedFolders.includes(folder.folderName)
                                      ? "bg-gray-100 rounded-lg"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleSelectedFolder1(folder.folderName)
                                  }
                                >
                                  {folder.folderName}
                                  {selectedFolders?.includes(
                                    folder.folderName
                                  ) && (
                                    <FaRegCircleCheck
                                      size={25}
                                      color="#9A2A26"
                                    />
                                  )}
                                </h3>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="flex flex-col gap-5 items-center justify-center py-6">
                            <img
                              src={`https://api.mykilo.co.uk/avatar/${profile}`}
                              width={150}
                              height={50}
                              alt="Empty Folder"
                            />
                            <p className="text-[16px] text-center text-[#868a93]">
                              Looks like you don't have any folders yet!
                              <br /> Create a new folder to get started
                            </p>
                          </div>
                        )}
                      </>
                    )}
                    {/* {showInitialMessage && !savedFolders?.length && (
                      <div className="flex flex-col gap-5 items-center justify-center py-6">
                        <img
                          src={require("../../assets/images/recipe-cooking-avatar.png")}
                          width={200}
                          alt="Empty Folder"
                        />
                        <p className="text-[16px] text-center text-[#868a93]">
                          Looks like you don't have any folders yet!
                          <br /> Create a new folder to get started
                        </p>
                      </div>
                    )} */}

                    {/* <div>
                      {isLoading ? (
                        <RecipeFolderSkeleton />
                      ) : (
                        folderNames.map((folder, index) => (
                          <div key={index}>
                            <h3
                              className={`my-4 flex items-center justify-between p-2 rounded ${
                                selectedFolders.includes(folder)
                                  ? "bg-gray-100 rounded-lg"
                                  : ""
                              }`}
                              onClick={() => handleSelectedFolder1(folder)}
                            >
                              {folder}
                              {selectedFolders.includes(folder) && (
                                <FaRegCircleCheck size={25} color="#9A2A26" />
                              )}
                            </h3>
                          </div>
                        ))
                      )}
                    </div> */}
                    {showInput && (
                      <div className="flex justify-between items-start my-4 border-2 p-2 rounded">
                        <input
                          type="text"
                          placeholder="Folder Name"
                          className="w-full focus:outline-none"
                          // value={folderName}
                          onChange={handleInputChange}
                        />
                        <button
                          className={`text-[14px] text-white rounded-full px-4 py-1 ${
                            folderName ? "bg-primary" : "bg-[#C4C4C4]"
                          }`}
                          onClick={handleSave}
                        >
                          Save
                        </button>
                      </div>
                    )}

                    <div className="flex justify-between items-center">
                      <button
                        className="border-[1px] text-[14px] text-[#303033] border-[#363639] rounded-full px-4 py-1"
                        onClick={() => {
                          setShowInput(true);
                          setShowInitialMessage(false);
                        }}
                      >
                        + New Folder
                      </button>
                      <div className="flex items-center gap-5">
                        <h4
                          className="text-[14px] text-[#303033] cursor-pointer"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </h4>
                        <button
                          className={`bg-primary text-[14px] hover:bg-primary text-white rounded-full px-4 py-1 ${
                            folderName ? "bg-primary" : "bg-[#C4C4C4] "
                          } ${
                            savedFolderName ? "bg-primary" : "bg-[#C4C4C4]"
                          } ${selectedFolders ? "bg-primary" : "bg-[#C4C4C4]"}`}
                          onClick={handleAddFolder}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <div
        key={index}
        className="w-[295px] min-h-[457px] bg-white rounded-xl overflow-hidden shadow-md relative my-4"
      >
        <div className="absolute top-0 left-0 flex justify-between items-center m-4 bg-[#fafafa99] overflow-hidden rounded-full w-[30px] hover:w-auto backdrop-blur-md mr-[2px] transition-all pr-2">
          <StarRating id={item?._id || item?.recipeId} item={item} />
        </div>
        <div className="absolute top-0 right-0 mt-2 flex items-center">
          <div className="bg-[#fafafa99] rounded-[36px] backdrop-blur-md p-[4px] mr-[10px]">
            <img
              src={require("../../assets/images/plus.png")}
              alt="plus"
              className="cursor-pointer"
              onClick={handleAddFolderBtn}
            />
          </div>
          <div className="bg-[#fafafa99] rounded-[36px] flex items-center justify-center backdrop-blur-md p-1 mr-[19px]">
            <svg
              onClick={async () => {
                const response = await handleFavorite(
                  item?._id || item?.recipeId,
                  dispatch,
                  isFavorited,
                  setIsFavorited
                );
                if (response) {
                  setIsFavorited(!isFavorited);
                }
              }}
              className={
                "h-5 w-5 cursor-pointer " + (isFavorited ? "fill-red-600" : "")
              }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 6.00019C10.2006 3.90317 7.24493 3.2753 4.95162 5.17534C2.65832 7.07538 2.3796 10.3061 4.15009 12.5772C5.62222 14.4654 10.0771 18.4479 11.5372 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                stroke={isFavorited ? "none" : "#000000"}
                strokeWidth={isFavorited ? 0 : 2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="flex flex-col justify-center py-3 absolute bottom-[80px] left-[10px]">
          <div className="flex flex-col justify-center gap-3 px-2 py-3">
            {dietaryDisplay}
          </div>
        </div>

        {loading ? (
          // <RecipesSekelton />
          <ImageSkeleton />
        ) : (
          // <></>
          // <LazyLoad height={390}>
          <div className="min-h-[390px]">
            <img
              src={`${API_URL}/cover/${coverPicture}`}
              className="rounded-[20px] cursor-pointer min-h-[390px]  object-cover"
              alt="product"
              onClick={onClick}
            />
          </div>

          // </LazyLoad>
        )}

        <div className="px-4 pt-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h1
                className="text-gray-900  font-medium text-lg cursor-pointer leading-6 flex-initial font-manrope capitalize"
                onClick={onClick}
              >
                {item?.recipe_title?.replace(/_/g, " ") ||
                  item?.recipeTitle?.replace(/_/g, " ")}

                {/* {item?.recipe_title?.replace(/_/g, " ") || item?.recipeHeading} */}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;

const handleFavorite = async (id, dispatch, isFavorited, setIsFavorited) => {
  const data = await dispatch(addfavRecipe(id));
  if (data.payload.success) {
    const response = await dispatch(getfavRecipe());
    if (response?.payload?.success) {
      setIsFavorited(!isFavorited);
    }
  }
  return data;
};

// import React, { Fragment, useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Dialog, Transition } from "@headlessui/react";
// import empty from "../../assets/images/empty-folder.e57de881.svg";
// import LazyLoad from "react-lazyload";
// import StarRating from "../recipes-components/star-rating";
// import {
//   addRecipeInFolder,
//   addfavRecipe,
//   getSavedFolders,
//   getfavRecipe,
// } from "../../redux/recipes/recipeActions";
// import RecipesSekelton from "../recipes-components/recipes-sekelton";
// import { FaRegCircleCheck } from "react-icons/fa6";
// const API_URL = process.env.REACT_APP_API_BASE_URL;

// const Card = ({
//   id,
//   item,
//   index,
//   loading,
//   coverPicture,
//   onClick,
//   favVar,
//   dietary_restriction,
// }) => {
//   const [open, setOpen] = useState(false);
//   const [showInitialMessage, setShowInitialMessage] = useState(true);
//   const [showInput, setShowInput] = useState(false);
//   const [folderName, setFolderName] = useState("");
//   const [savedFolderName, setSavedFolderName] = useState("");
//   const [isFavorited, setIsFavorited] = useState(null);
//   const [folderNames, setFolderNames] = useState([]);
//   const [selectedFolder, setSelectedFolder] = useState([]);

//   const dispatch = useDispatch();

//   const savedFolders = useSelector((state) => {
//     return state?.recipe?.savedFolder?.folders;
//   });

//   useEffect(() => {
//     const storedJsonString = localStorage.getItem("favoriteRecipes");
//     const storedArray = JSON.parse(storedJsonString);
//     const Favorited = storedArray?.some((recipe) => recipe?._id === item?._id);
//     setIsFavorited(Favorited);
//   }, []);

//   useEffect(() => {
//     dispatch(getSavedFolders())
//   }, [dispatch])

//   const handleInputChange = (event) => {
//     setFolderName(event.target.value);
//   };

//   const handleSave = () => {
//     if (folderName) {
//       // Save the folder name and update state
//       setSavedFolderName(folderName);
//       setFolderNames([...folderNames, folderName]);
//       setShowInput(false);
//       setFolderName(""); // Clear the input field
//     }
//   };

//   const handleSelectedFolder = (folder) => {
//     // Check if the folder is already selected
//     const isSelected = selectedFolder.includes(folder);

//     // If selected, remove it from the selectedFolder state, otherwise add it
//     if (isSelected) {
//       setSelectedFolder(
//         selectedFolder.filter((selected) => selected !== folder)
//       );
//     } else {
//       setSelectedFolder([...selectedFolder, folder]);
//     }
//   };

//   const handleAddFolder = () => {
//     dispatch(addRecipeInFolder({ recipeId: id, folderName: folderNames }));
//     setOpen(false);
//   };

//   const dietaryDisplay = dietary_restriction?.map((tag, index) => {
//     const cleanedTag = tag.replace(/_/g, " ");
//     return (
//       <div
//         key={index}
//         className="group relative cursor-pointer bg-[#d5e6ff] backdrop-blur-sm bg-opacity-50 rounded-full w-6 h-6 hover:w-20 flex items-center justify-center overflow-hidden"
//       >
//         <span className="text-[12px] font-semibold transition-all duration-100 ease-in-out group-hover:opacity-0">
//           {cleanedTag.charAt(0).toUpperCase()}
//         </span>
//         <span className="absolute text-[12px] font-semibold opacity-0 transition-all duration-100 ease-in-out group-hover:opacity-100">
//           {cleanedTag}
//         </span>
//       </div>
//     );
//   });
//   const [selectedFolders, setSelectedFolders] = useState([]);

//   const handleSelectedFolder1 = (folder) => {
//     if (selectedFolders.includes(folder)) {
//       setSelectedFolders(
//         selectedFolders.filter((selected) => selected !== folder)
//       );
//     } else {
//       setSelectedFolders([...selectedFolders, folder]);
//     }
//   };

//   return (
//     <>
//       <Transition.Root show={open} as={Fragment}>
//         <Dialog as="div" className="relative z-10" onClose={setOpen}>
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0"
//             enterTo="opacity-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//           </Transition.Child>

//           <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
//             <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
//               <Transition.Child
//                 as={Fragment}
//                 enter="ease-out duration-300"
//                 enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//                 enterTo="opacity-100 translate-y-0 sm:scale-100"
//                 leave="ease-in duration-200"
//                 leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//                 leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//               >
//                 <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 w-[50%] text-left shadow-xl transition-all sm:my-8 sm:p-6">
//                   <div>
//                     <h2 className="text-[22px] text-[#000000]">
//                       Add to Folder
//                     </h2>
//                     <div className="flex items-center mt-5 gap-4 pb-3 border-b-[1px] ">
//                       <img
//                         src={`https://api.mykilo.co.uk/cover/${item?.recipe_Cover_picture}`}
//                         className="w-24 h-24 rounded-full "
//                         alt="cover pic"
//                       />
//                       <h2>{item?.recipe_title}</h2>
//                     </div>
//                     {showInitialMessage && !savedFolderName && (
//                       <div className="flex flex-col gap-5 items-center justify-center py-6">
//                         <img
//                           src={require("../../assets/images/recipe-cooking-avatar.png")}
//                           width={200}
//                           alt="Empty Folder"
//                         />
//                         <p className="text-[16px] text-center text-[#868a93]">
//                           Looks like you don't have any folders yet!
//                           <br /> Create a new folder to get started
//                         </p>
//                       </div>
//                     )}
//                     {showInput && (
//                       <div className="flex justify-between items-start my-4 border-2 p-2 rounded">
//                         <input
//                           type="text"
//                           placeholder="Folder Name"
//                           className="w-full focus:outline-none"
//                           value={folderName}
//                           onChange={handleInputChange}
//                         />
//                         <button
//                           className={`text-[14px] text-white rounded-full px-4 py-1 ${
//                             folderName ? "bg-black" : "bg-[#C4C4C4]"
//                           }`}
//                           onClick={handleSave}
//                         >
//                           Save
//                         </button>
//                       </div>
//                     )}
//                     {/* {savedFolderName && (
//                       <div className="my-4 p-2 rounded">
//                         <h3 className="text-[16px] font-semibold">
//                            {savedFolderName}
//                         </h3>
//                       </div>
//                     )} */}

//                     <div>
//                       {folderNames.map((folder, index) => (
//                         <div key={index}>
//                           <h3
//                             className={`my-4 flex items-center justify-between p-2 rounded ${
//                               selectedFolders.includes(folder)
//                                 ? "bg-gray-100 rounded-lg"
//                                 : ""
//                             }`}
//                             onClick={() => handleSelectedFolder1(folder)}
//                           >
//                             {folder}
//                             {selectedFolders.includes(folder) && (
//                               <FaRegCircleCheck size={25} color="#9A2A26" />
//                             )}
//                           </h3>
//                         </div>
//                       ))}

//                       {/* {savedFolders?.map((folder, index) => (
//                         <div key={index}>
//                           <h3
//                             className={`my-4 flex items-center justify-between p-2 rounded ${
//                               selectedFolders.includes(folder)
//                                 ? "bg-gray-100 rounded-lg"
//                                 : ""
//                             }`}
//                             onClick={() => handleSelectedFolder1(folder)}
//                           >
//                             {folder}
//                             {selectedFolders.includes(folder) && (
//                               <FaRegCircleCheck size={25} color="#9A2A26" />
//                             )}
//                           </h3>
//                         </div>
//                       ))} */}
//                     </div>

//                     <div className="flex justify-between items-center">
//                       <button
//                         className="border-[1px] text-[14px] text-[#303033] border-[#363639] rounded-full px-4 py-1"
//                         onClick={() => {
//                           setShowInput(true);
//                           setShowInitialMessage(false);
//                         }}
//                       >
//                         + New Folder
//                       </button>
//                       <div className="flex items-center gap-5">
//                         <h4
//                           className="text-[14px] text-[#303033] cursor-pointer"
//                           onClick={() => setOpen(false)}
//                         >
//                           Cancel
//                         </h4>
//                         <button
//                           className={`bg-[#C4C4C4] text-[14px] text-white rounded-full px-4 py-1 ${
//                             folderName ? "bg-black" : "bg-[#C4C4C4]"
//                           } ${savedFolderName ? "bg-black" : "bg-[#C4C4C4]"}`}
//                           onClick={handleAddFolder}
//                         >
//                           Save
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </Dialog.Panel>
//               </Transition.Child>
//             </div>
//           </div>
//         </Dialog>
//       </Transition.Root>

//       <div
//         key={index}
//         className="w-[295px] h-[457px] bg-white rounded-xl overflow-hidden shadow-md relative my-4"
//       >
//         <div className="absolute top-0 left-0 flex justify-between items-center m-4 bg-[#fafafa99] overflow-hidden rounded-full w-[30px] hover:w-auto backdrop-blur-md mr-[2px] transition-all pr-2">
//           <StarRating item={item} />
//         </div>
//         <div className="absolute top-0 right-0 m-4 flex items-center">
//           <div className="bg-[#fafafa99] rounded-[36px] backdrop-blur-md p-[4px] mr-[19px]">
//             <img
//               src={require("../../assets/images/plus.png")}
//               alt="plus"
//               className="cursor-pointer"
//               onClick={() => setOpen(true)}
//             />
//           </div>
//           <div className="bg-[#fafafa99] rounded-[36px] flex items-center justify-center backdrop-blur-md p-1 mr-[19px]">
//             <svg
//               onClick={async () => {
//                 const response = await handleFavorite(
//                   item?._id,
//                   dispatch,
//                   isFavorited,
//                   setIsFavorited
//                 );
//                 if (response) {
//                   setIsFavorited(!isFavorited);
//                 }
//               }}
//               className={
//                 "h-5 w-5 cursor-pointer " + (isFavorited ? "fill-red-600" : "")
//               }
//               viewBox="0 0 24 24"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 fillRule="evenodd"
//                 clipRule="evenodd"
//                 d="M12 6.00019C10.2006 3.90317 7.24493 3.2753 4.95162 5.17534C2.65832 7.07538 2.3796 10.3061 4.15009 12.5772C5.62222 14.4654 10.0771 18.4479 11.5372 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
//                 stroke={isFavorited ? "none" : "#000000"}
//                 strokeWidth={isFavorited ? 0 : 2}
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//               />
//             </svg>
//           </div>
//         </div>

//         <div className="flex flex-col justify-center py-3 absolute bottom-[80px] left-[10px]">
//           <div className="flex flex-col justify-center gap-3 px-2 py-3">
//             {dietaryDisplay}
//           </div>
//         </div>

//         {loading ? (
//           <RecipesSekelton />
//         ) : (
//           <LazyLoad height={200}>
//             <img
//               src={`${API_URL}/cover/${coverPicture}`}
//               className="rounded-[20px] cursor-pointer"
//               style={{ height: "390px", objectFit: "cover" }}
//               alt="product"
//               onClick={onClick}
//             />
//           </LazyLoad>
//         )}

//         <div className="px-4 pt-2">
//           <div className="flex items-center justify-between">
//             <div className="flex items-center">
//               <h1
//                 className="text-gray-900 font-medium text-lg cursor-pointer leading-6 flex-initial font-manrope capitalize"
//                 onClick={onClick}
//               >
//                 {item?.recipe_title?.replace(/_/g, " ")}
//               </h1>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Card;

// const handleFavorite = async (id, dispatch, isFavorited, setIsFavorited) => {
//   const data = await dispatch(addfavRecipe(id));
//   if (data.payload.success) {
//     const response = await dispatch(getfavRecipe());
//     if (response?.payload?.success) {
//       setIsFavorited(!isFavorited);
//     }
//   }
//   return data;
// };
