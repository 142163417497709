import React from "react";
import vegan from "../../assets/images/vegan-chicken.webp";

import MainLayout from "../layout/main-layout";
const blogData = {
  image: vegan,
  title: "Chocolate sweet potato pancakes",
  description:
    "Who says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these deliciousWho says sweet potato is only for Autumn? Boost your breakfast nutrition with these delicious sweet potato pancakes....",
};

const BlogDetailComponent = () => {
  return (
    <>
      <MainLayout>
        <div className="">
          <div className=" ">
            <img src={blogData.image} className="" />
            <div className="px-3 py-4">
              <h2 className="text-2xl font-bold py-3">{blogData.title} </h2>
              <p className="text-base text-[#303033]">
                {blogData.description}{" "}
              </p>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default BlogDetailComponent;
