import React, { useState, useEffect } from "react";
import evaluationbg from "../../assets/images/evaluationbg2.png";
import logo from "../../assets/images/logoblack.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { englishFormData, frenchFormData, italianFormData } from "../../utils/healthEvaluationFormData";
// import CustomizedDialogs from "../pop";
import PaymentDialog from "./PaymentDialog";
import { useSelector } from "react-redux";
const HealthEvaluationForm = () => {
  const selectedLanguage = useSelector((state) => state?.languageSlice?.selectedLanguage);
  const seriousTranslations = {
    english: {
      verySerious: "Very serious",
      notSerious: "Not serious"
    },
    italian: {
      verySerious: "Molto serio",
      notSerious: "Non serio"
    },
    french: {
      verySerious: "Très sérieux",
      notSerious: "Pas sérieux"
    }
  };
  const mySeriousTranslation = seriousTranslations[selectedLanguage] || seriousTranslations?.english;
  const translation = {
    english: {
      selectAll: "Select All"
    },
    italian: {
      selectAll: "Seleziona tutto"
    },
    french: {
      selectAll: "Tout sélectionner"
    }
  };
 
  const suggestions = {
    negative: {
      english: "Negative values are not valid. Consider revising your input.",
      french: "Les valeurs négatives ne sont pas valides. Envisagez de réviser votre saisie.",
      italian: "I valori negativi non sono validi. Considera di rivedere il tuo input.",
    },
    belowRange: {
      english: "Your intake is below the recommended range. Consider increasing your intake.",
      french: "Votre apport est en dessous de la plage recommandée. Envisagez d'augmenter votre apport.",
      italian: "Il tuo consumo è al di sotto dell'intervallo raccomandato. Considera di aumentare il tuo consumo.",
    },
    withinRange: {
      english: "Your intake is within the recommended range. Keep it up!",
      french: "Votre apport est dans la plage recommandée. Continuez comme ça!",
      italian: "Il tuo consumo è nell'intervallo raccomandato. Continua così!",
    },
    aboveRange: {
      english: "You are slightly above the recommended intake. Monitor your consumption to avoid overdoing it.",
      french: "Vous êtes légèrement au-dessus de l'apport recommandé. Surveillez votre consommation pour éviter les excès.",
      italian: "Sei leggermente al di sopra dell'apporto raccomandato. Monitora il tuo consumo per evitare di esagerare.",
    },
    optimal: {
      english: "Excellent! You are hitting the optimal mark.",
      french: "Excellent ! Vous atteignez la marque optimale.",
      italian: "Eccellente! Stai raggiungendo il livello ottimale.",
    },
    exceeding: {
      english: "You are exceeding the recommended intake. Consider reducing it for balance.",
      french: "Vous dépassez l'apport recommandé. Envisagez de le réduire pour un meilleur équilibre.",
      italian: "Stai superando l'apporto raccomandato. Considera di ridurlo per un miglior equilibrio.",
    },
    tooHigh: {
      english: "Your intake is too high. Significant reduction is advised.",
      french: "Votre apport est trop élevé. Une réduction significative est recommandée.",
      italian: "Il tuo consumo è troppo elevato. Si consiglia una riduzione significativa.",
    }
  };
  
  const [currentStep, setCurrentStep] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [formData,setFormData]=useState(englishFormData)
  console.log("🚀 ~ HealthEvaluationForm ~ formData:", formData)
  useEffect(() => {
    // Set formData based on the selected language
    if (selectedLanguage === 'english') {
      setFormData(englishFormData);
    } else if (selectedLanguage === 'french') {
      setFormData(frenchFormData);
    } else if (selectedLanguage === 'italian') {
      setFormData(italianFormData);
    }
  }, [selectedLanguage]);
  const steps = formData?.steps;
  const [open, setOpen] = useState(false);
  const [selectAll, setSelectAll] = useState({});
  const [arr, setArr] = useState([]);
  const [plan, setPlan] = useState(14.99);

  const initialValues = steps?.reduce((acc, step) => {
    step.fields.forEach((field) => {
      if (field.type === "checkbox") {
        acc[field.name] = [];
      } else {
        acc[field.name] = "";
      }
    });
    return acc;
  }, {});
  const mapQuestionsToAnswers = (formData, answers) => {
    const result = [];
    const getSuggestions = (number, selectedLanguage) => {
      // Ensure input is a valid number
      if (typeof number !== "number" || isNaN(number)) return "";
    
      // Helper function to get translated text
      const getTranslation = (key) => suggestions[key][selectedLanguage];
    
      // Suggestions based on the value of number
      if (number < 0) {
        return getTranslation('negative');
      } else if (number >= 0 && number < 3) {
        return getTranslation('belowRange');
      } else if (number >= 3 && number <= 5) {
        return getTranslation('withinRange');
      } else if (number > 5 && number < 7) {
        return getTranslation('aboveRange');
      } else if (number === 7) {
        return getTranslation('optimal');
      } else if (number > 7 && number <= 10) {
        return getTranslation('exceeding');
      } else if (number > 10) {
        return getTranslation('tooHigh');
      }
    
      // Default case
      return "";
    };

    formData?.steps?.forEach((step) => {
      step.fields.forEach((field) => {
        const answer = answers[field.name];

        if (answer !== undefined) {
          if (Array.isArray(answer)) {
            result.push({
              question: field.label,
              answer: answer.join(", "),
              mainName: field.mainName,
            });
          } else {
            // Check if the answer is a number
            if (!isNaN(answer) && answer !== "") {
              const numericalAnswer = parseFloat(answer);
              const suggestions = getSuggestions(numericalAnswer,selectedLanguage);

              result.push({
                question: field.label,
                mainName: field.mainName,
                answer: answer,
                suggestions: suggestions, // Add suggestions based on numerical value
              });
            } // Check if the answer is a number or a select option
            else if (field.type === "select") {
              const optionIndex = field.options.indexOf(answer);
              const recommendation = field.recommendations
                ? field.recommendations[optionIndex]
                : "";
              result.push({
                question: field.label,
                mainName: field.mainName,
                answer: answer,
                suggestions: recommendation,
              });
            } else {
              result.push({
                question: field.label,
                mainName: field.mainName,
                answer: answer,
              });
            }
          }
        }
      });
    });

    return result;
  };

  // const mapQuestionsToAnswers = (formData, answers) => {
  //   const result = [];

  //   formData.steps.forEach((step) => {
  //     step.fields.forEach((field) => {
  //       const answer = answers[field.name];

  //       if (answer !== undefined) {
  //         if (Array.isArray(answer)) {
  //           result.push({
  //             question: field.label,
  //             answer: answer.join(", "),
  //             mainName: field.mainName,
  //           });
  //         } else {
  //           result.push({
  //             question: field.label,
  //             mainName: field.mainName,
  //             answer: answer,
  //           });
  //         }
  //       }
  //     });
  //   });

  //   return result;
  // };

  // Define validation schemas
  // const validationSchemas = steps.reduce((acc, step, index) => {
  //   const shape = step.fields.reduce((s, field) => {
  //     if (field.type === "number")
  //       s[field.name] = Yup.number().integer().min(1).required(field.error);
  //     if (field.type === "text")
  //       s[field.name] = Yup.string().required(field.error);
  //     if (field.type === "checkbox")
  //       s[field.name] = Yup.array()
  //         .min(1, `${field.error}`)
  //         .required(`${field.error}`);
  //     if (field.type === "radio")
  //       s[field.name] = Yup.string().required(`${field.error}`);
  //     return s;
  //   }, {});
  //   acc[index] = Yup.object().shape(shape);
  //   return acc;
  // }, {});
  const validationSchemas = steps?.reduce((acc, step, index) => {
    const shape = step.fields.reduce((s, field) => {
      if (
        field.type === "number" &&
        field.name !== "age" &&
        field.name !== "alcohol_spend" &&
        field.name !== "takeaways_spend" &&
        field.name !== "energy_spend"
      ) {
        s[field.name] = Yup.number()
          .integer()
          .min(1)
          .max(7, "Value must be between 1 and 7") // Set max value constraint
          .required(field.error);
      }
      if (field.type === "text" && field.name !== "age") {
        s[field.name] = Yup.string().required(field.error);
      }
      if (field.type === "checkbox") {
        s[field.name] = Yup.array()
          .min(1, `${field.error}`)
          .required(`${field.error}`);
      }
      if (field.type === "radio") {
        s[field.name] = Yup.string().required(`${field.error}`);
      }
      return s;
    }, {});
    acc[index] = Yup.object().shape(shape);
    return acc;
  }, {});

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemas[currentStep],
    onSubmit: (values) => {
      if (currentStep === steps.length - 1) {
        const arr2 = mapQuestionsToAnswers(formData, values);
        setArr(arr2);
        // Final submission
        setPaymentModal(true);
        setOpen(true);
      } else {
        // Move to the next step
        setCurrentStep(currentStep + 1);
      }
    },
  });

  const handleNext = () => {
    formik.handleSubmit();
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSelectAllChange = (fieldName, checked) => {
    setSelectAll((prev) => ({
      ...prev,
      [fieldName]: checked,
    }));
    if (checked) {
      formik.setFieldValue(
        fieldName,
        formData?.steps[currentStep].fields.find(
          (field) => field.name === fieldName
        )?.options || []
      );
    } else {
      formik.setFieldValue(fieldName, []);
    }
  };

  const handleIndividualChange = (fieldName, option, checked) => {
    if (checked) {
      formik.setFieldValue(fieldName, [...formik.values[fieldName], option]);
    } else {
      formik.setFieldValue(
        fieldName,
        formik.values[fieldName].filter((val) => val !== option)
      );
    }
  };

  const CurrentStep = steps[currentStep];
  const translations = {
    english: {
      finishAndPay: "Finish and Pay",
      next: "Next",
      previous: "Previous",
    },
    french: {
      finishAndPay: "Terminer et payer",
      next: "Suivant",
      previous: "Précédent",
    },
    italian: {
      finishAndPay: "Concludi e Paga",
      next: "Avanti",
      previous: "Precedente",
    },
  };
  const translationSelect = translation[selectedLanguage] || translation?.english;
  const nextButtonText  = currentStep === steps?.length - 1
  ? translations[selectedLanguage]?.finishAndPay || translations.english.finishAndPay
  : translations[selectedLanguage]?.next || translations.english.next;
  const previousButtonText = translations[selectedLanguage]?.previous || translations.english.previous;
  return (
    <div className="">
      <div
        className="bg-center flex justify-center items-center bg-no-repeat h-screen bg-cover"
        style={{
          backgroundImage: `url(${evaluationbg})`,
        }}
      >
        <div>
          <div className="bg-[#72727224]  md:py-10 2xl:py-16 md:w-[700px] w-[310px] py-10 lg:py-12 lg:w-[980px] 2xl:max-w-[1060px] rounded-[30px] overflow-visible relative">
            <div className="shadow-2xl shadow-black mx-16 rounded-[30px] py-12">
              <div className="flex items-center justify-center">
                <img src={logo} alt="logo" />
              </div>

              <div className="mt-5 lg:px-12 md:px-14 px-2">
                <PaymentDialog
                  open={paymentModal}
                  setOpen={setPaymentModal}
                  plan={plan}
                  formdata={arr}
                  selectedLanguage={selectedLanguage}
                />
                <form onSubmit={formik.handleSubmit} className="z-50">
                  <h2 className="lg:text-[20px] md:text-2xl text-xl font-semibold mb-4">
                    {CurrentStep.title}
                  </h2>
                  <div className="bg-white rounded-lg px-3 py-5 md:px-10 md:py-8 lg:px-14 lg:py-10">
                    {CurrentStep?.fields?.map((field) => (
                      <div key={field.name}>
                        <h2
                          className="font-medium lg:text-base 2xl:text-base md:text-base text-[10px] lg:mt-4 md:mt-3 mt-2"
                          htmlFor={field.name}
                        >
                          {field.label}
                        </h2>

                        {field?.type === "select" ? (
                          <select
                            required
                            name={field.name}
                            value={formik.values[field.name]}
                            onChange={formik.handleChange}
                            className="border w-full outline-none h-10 mt-2"
                            onBlur={formik.handleBlur}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {field?.options?.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        ) : field?.type === "radio" ? (
                          <div className="flex flex-col lg:flex-row md:flex-row lg:items-end md:items-end items-start lg:gap-12 mt-2 md:gap-10 lg:mt-6 md:mt-5">
                            <h2
                              className={`${
                                field.name === "commitment"
                                  ? "block text-sm lg:text-base md:text-base"
                                  : "hidden"
                              }`}
                            >
                              {mySeriousTranslation.notSerious}
                            </h2>
                            {field?.options?.map((option) => (
                              <div
                                className="flex flex-col lg:gap-5 md:gap-4 gap-1 lg:text-base md:text-base text-sm items-center"
                                key={option}
                              >
                                <label>{option}</label>
                                <input
                                  type="radio"
                                  name={field.name}
                                  value={option}
                                  className="w-6 h-6 accent-[#9C3022]"
                                  checked={formik.values[field.name] === option}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            ))}
                            <h2
                              className={`${
                                field.name === "commitment"
                                  ? "block text-sm lg:text-base md:text-base"
                                  : "hidden"
                              }`}
                            >
                              {mySeriousTranslation.verySerious}
                            </h2>
                          </div>
                        ) : field.type === "checkbox" ? (
                          <div className="grid grid-cols-3 mt-2 gap-y-2 justify-center">
                            {field?.options?.map((option) => (
                              <label
                                key={option}
                                className="flex items-start gap-x-2 lg:text-base md:text-sm text-[10px] "
                              >
                                <input
                                  type="checkbox"
                                  name={field.name}
                                  value={option}
                                  checked={formik.values[field.name].includes(
                                    option
                                  )}
                                  onChange={(e) =>
                                    handleIndividualChange(
                                      field.name,
                                      option,
                                      e.target.checked
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  className="w-4 h-4 flex accent-[#9C3022] mt-1"
                                />
                                {option}
                              </label>
                            ))}
                            <label className="flex items-center gap-x-2 lg:text-base md:text-sm text-[10px] ">
                              <input
                                type="checkbox"
                                name={`selectAll-${field.name}`}
                                checked={
                                  selectAll[field.name] ||
                                  formik.values[field.name].length ===
                                    (field.options?.length || 0)
                                }
                                onChange={(e) =>
                                  handleSelectAllChange(
                                    field.name,
                                    e.target.checked
                                  )
                                }
                                className={`w-4 ${formik.errors[field.name] ? "border-red-400":""} h-4 flex accent-[#9C3022]`}
                              />
                            {translationSelect.selectAll}
                            </label>
                          </div>
                        ) : field.type === "file" ? (
                          <input
                            type="file"
                            name={field.name}
                            className={`${formik.errors[field.name] ? "border-red-400":""}`}
                            onChange={(event) =>
                              formik.setFieldValue(
                                field.name,
                                event.currentTarget.files[0]
                              )
                            }
                            onBlur={formik.handleBlur}
                          />
                        ) : (
                          <input
                            type={field.type}
                            className={`border ${formik.errors[field.name] ? "border-red-400":""} w-full h-10 mt-2 outline-none px-2`}
                            name={field.name}
                            min="0"
                            required
                            value={formik.values[field.name]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        )}
                        {formik.touched[field.name] &&
                        formik.errors[field.name] ? (
                          <div className="text-red-500 mt-1 lg:text-sm md:text-sm text-[10px]">
                            {formik.errors[field.name]}
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>

                  <div className="flex items-center justify-center gap-3 mt-5">
                    {currentStep > 0 && (
                      <button
                        className="bg-white text-sm rounded-sm border-black border-2 py-2 px-5"
                        type="button"
                        onClick={handlePrevious}
                      >
                        {previousButtonText}
                      </button>
                    )}
                    <div className="flex items-center justify-center">
                      <button
                        className="bg-[#303033] text-sm rounded-sm text-white py-2 px-10"
                        type="submit"
                      >
                        {nextButtonText }
                      </button>
                      {/* <CustomizedDialog open={open} setOpen={setOpen} />; */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthEvaluationForm;
