import { createSlice } from "@reduxjs/toolkit";
import {
  addTeam,
  deleteTeam,
  getTeam,
  updateTeam,
  getAllTeams,
  sendRequest,
  respondToRequest,
  getRequests,
  getTeamById,
  updateTeamViewCount, // Import the new thunk
} from "./teamActions";
import { toast } from "react-toastify";

const teamSlice = createSlice({
  name: "teamSlice",
  initialState: {
    teams: [],
    team: null, // Add this for storing a single team
    pagination: {
      currentPage: 1,
      totalPages: 1,
      pageSize: 10,
      totalTeams: 0,
    },
    addingTeam: null,
    gettingTeam: null,
    deletingTeam: null,
    loading: false,
    error: null,
    requests: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Adding team
    builder.addCase(addTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addTeam.fulfilled, (state, action) => {
      state.loading = false;
      state.addingTeam = action.payload;
      toast.success("Team Created successfully!");
    });
    builder.addCase(addTeam.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(`${action.payload.message}`);
    });

    // Getting the team
    builder.addCase(getTeam.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeam.fulfilled, (state, action) => {
      state.loading = false;
      state.gettingTeam = action.payload;
    });
    builder.addCase(getTeam.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Getting a team by ID
    builder.addCase(getTeamById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamById.fulfilled, (state, action) => {
      state.loading = false;
      state.team = action.payload;
    });
    builder.addCase(getTeamById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Deleting the team
    builder.addCase(deleteTeam.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteTeam.fulfilled, (state, action) => {
      state.loading = false;
      state.deletingTeam = action.payload;
      state.teams = state.teams.filter((team) => team._id !== action.meta.arg);
    });
    builder.addCase(deleteTeam.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Updating the team
    builder.addCase(updateTeam.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateTeam.fulfilled, (state, action) => {
      state.loading = false;
      state.team = action.payload;
    });
    builder.addCase(updateTeam.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
   // Updating team view count
   builder.addCase(updateTeamViewCount.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(updateTeamViewCount.fulfilled, (state, action) => {
    state.loading = false;
    if (state.team && state.team._id === action.meta.arg) {
      state.team.teamViewCount = action.payload.teamViewCount;
    }
    const teamIndex = state.teams.findIndex(
      (team) => team._id === action.meta.arg
    );
    if (teamIndex >= 0) {
      state.teams[teamIndex].teamViewCount = action.payload.teamViewCount;
    }
  });
  builder.addCase(updateTeamViewCount.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
  });
    // Getting all teams with pagination
    builder.addCase(getAllTeams.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllTeams.fulfilled, (state, action) => {
      state.loading = false;
      state.teams = action.payload.teams;
      state.pagination = action.payload.pagination;
    });
    builder.addCase(getAllTeams.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Failed to fetch teams. Please try again.");
    });

    // Sending a request
    builder.addCase(sendRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendRequest.fulfilled, (state, action) => {
      state.loading = false;
      toast.success("Request sent successfully");
    });
    builder.addCase(sendRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(`${action.payload.message}`);
    });

    // Responding to a request
    builder.addCase(respondToRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(respondToRequest.fulfilled, (state, action) => {
      state.loading = false;
      toast.success(`Request ${action.payload.message}`);
    });
    builder.addCase(respondToRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(`${action.payload.message}`);
    });

    // Getting requests
    builder.addCase(getRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getRequests.fulfilled, (state, action) => {
      state.loading = false;
      state.requests = action.payload.invitations;
    });
    builder.addCase(getRequests.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error(`${action.payload.message}`);
    });
  },
});

export default teamSlice.reducer;
