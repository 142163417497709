import React from "react";

const ImageSkeleton = () => {
  return (
    <div className=" lg:ml-0 ml-5  rounded-xl p-2 w-72 ">
      <div className="animate-pulse">
        <div className="h-96 rounded-lg bg-slate-200 "></div>
      </div>
    </div>
  );
};

export default ImageSkeleton;
