import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  WorkoutProgramFilterQuerryState: {},
  loading: false,
  error: null,
};

export const getWorkOutProgarmFilterApiHandler = createAsyncThunk(
  "getWorkOutProgarmFilterApiHandler",
  async (querry, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/programs/list?${querry}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const workoutProgramFilterSlice = createSlice({
  name: "workoutProgramFilterSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getWorkOutProgarmFilterApiHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(
      getWorkOutProgarmFilterApiHandler.fulfilled,
      (state, action) => {
        state.WorkoutProgramFilterQuerryState = action.payload;
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      getWorkOutProgarmFilterApiHandler.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default workoutProgramFilterSlice.reducer;
