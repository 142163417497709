import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";
import { FaTiktok } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
const Footer = () => {
  return (
    <footer className="bg-[#f7f7f7] border-t border-[#303033] mt-20">
      <div className="max-w-[1240px] mx-auto py-4 px-5 flex flex-col sm:flex-row">
        <div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          {/* Replace `Logo` with your logo component or image */}
          {/* <img src={Logo} alt="Logo" className="w-10 h-10 text-white p-2 bg-blue-500 rounded-full" /> */}
          <span className="ml-3 text-[18px] text-[#303033] md:block hidden font-medium">
            © 2024 My Kilo
          </span>
        </div>
        <nav className="md:ml-auto  md:items-center text-base md:justify-center">
          <h2 className="text-[12px] text-[#868a93] sm:hidden py-3">Support</h2>
          <div className="flex">
            <a
              href="/privacy-policy"
              className="mr-5 hover:text-[#9c3022] text-[14px] text-[#303033] font-medium captalize"
            >
              Privacy Policy{" "}
            </a>
            <a
              href="/terms-and-condition"
              className="mr-5 hover:text-[#9c3022] text-[14px] text-[#303033] font-medium captalize"
            >
              Terms & Conditions{" "}
            </a>
            <a
              href="/faq"
              className="mr-5 hover:text-[#9c3022] text-[14px] text-[#303033] font-medium captalize"
            >
              FAQs{" "}
            </a>
            <a
              href="/about"
              className="mr-5 hover:text-[#9c3022] text-[14px] text-[#303033] font-medium captalize"
            >
              About{" "}
            </a>
          </div>
        </nav>
        <div className="sm:ml-auto sm:mt-0 mt-2 gap-2">
          <h2 className="text-[12px] text-[#868a93] sm:hidden py-3">Social</h2>
          <div className="flex items-center gap-2">
            <div className="custom-container cursor-pointer">
              <FaFacebookF className="custom-image" />
            </div>
            <Link to="https://www.instagram.com/mykilo_?igsh=OGpzZWtnNmt2NXh3">
              <div className="custom-container cursor-pointer">
                <FaInstagram className="custom-image" />
              </div>
            </Link>

            <div className="custom-container cursor-pointer">
              <FaXTwitter className="custom-image" />
            </div>
            <Link to="https://youtube.com/@mykilo_?si=IBPY4XHLeAyenYkv">
              <div className="custom-container cursor-pointer">
                <FaYoutube className="custom-image" />
              </div>
            </Link>
            <Link to="https://www.tiktok.com/@mykilo_?_t=8nf1FJIarIG&_r=1">
              <div className="custom-container cursor-pointer">
                <FaTiktok className="custom-image" />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
