import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api";
import { toast } from "react-toastify";

// getting recipes by category on recipes page
export const getRecipesByCategories = createAsyncThunk(
  "getRecipesByCategories",
  async (category, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/public/RecipesByParams/list/${category?.title || "latest_recipes"}`
      );

      return {
        title: response.data?.title,
        data: {
          items: response.data?.items,
          description: category?.description,
          link: category?.link,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//getting recipe on single/description page
export const getSingleRecipe = createAsyncThunk(
  "getRecipe",
  async (id, { rejectWithValue }) => {
    try {
      // Assuming the API endpoint allows fetching a specific recipe by id
      const response = await axios.get(`public/recipe/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// getting recipe category on view all category page
export const getCategoryRecipe = createAsyncThunk(
  "getCategoryRecipe",
  async (category, { rejectWithValue }) => {
    try {
      // Assuming the API endpoint allows fetching a specific recipe by id
      const response = await axios.get(
        `/public/RecipesByParams/list/${category.title}`
      );

      return {
        title: response.data?.title,
        data: {
          items: response.data?.items,
          description: category?.description,
          link: category?.link,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const getCategoryRecipe = createAsyncThunk(
//   "getCategoryRecipe",
//   async (recipeCategory, { rejectWithValue }) => {
//     try {
//       const url = `/public/RecipesByParams/list/${recipeCategory.title}`;

//       const encoded = encodeURI(url);
//       const drcodedURL = decodeURI(encoded);

//       const response = await axios.get(drcodedURL);

//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// Search Recipe
// export const serarchRecipes = createAsyncThunk(
//   "serarchRecipes",
//   async (querry, { rejectWithValue }) => {
//     try {
//       const url = `/public/searching?${querry}`;
//       const encoded = encodeURI(url);
//       const drcodedURL = decodeURI(encoded);

//       const response = await axios.get(drcodedURL);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const serarchRecipes = createAsyncThunk(
  "serarchRecipes",
  async (query, { rejectWithValue }) => {
    try {
      const url = `/public/searching?${query}`;
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      // Better to return a more specific error message or code
      return rejectWithValue(
        "Failed to fetch recipes. Please try again later."
      );
    }
  }
);

// Favourite Recipes
export const addfavRecipe = createAsyncThunk(
  "addfavRecipe",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post("/profile/favorite/recipe", {
        recipeId: id,
      });
      const data = response?.data;
      toast.success(`${data?.message}`);

      return data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);

      return rejectWithValue(error.response.data);
    }
  }
);

// Get fav recipe
export const getfavRecipe = createAsyncThunk(
  "getfavRecipe",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/profile/favorite/getRecipe");
      const data = response.data;
      const jsonString = JSON.stringify(data?.favoriteRecipes);

      localStorage.setItem("favoriteRecipes", jsonString);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecipes = createAsyncThunk(
  "getRecipes",
  async (querry, { rejectWithValue }) => {
    try {
      const url = `public/recipes?${querry}`;
      const encoded = encodeURI(url);
      const drcodedURL = decodeURI(encoded);

      const response = await axios.get(drcodedURL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get ratings
export const Rating = createAsyncThunk(
  "Rating",
  async (ratingObj, { rejectWithValue }) => {
    try {
      // const config = {
      //   headers: {
      //     authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU1YmZkM2UxNjNjYmIzZTI2YTViMWIiLCJpYXQiOjE3MTA0MDU5NTN9.nWDlyV3mez-uXw4WBmZgXIBtzipML1jnLpq9qxQOk50`,
      //     // Other headers if needed
      //   },
      // };
      const response = await axios.post("/public/rating", ratingObj);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Filters
export const getFilterdRecipeHandler = createAsyncThunk(
  "getFilterdRecipeHandler",
  async (querry, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/recipes?${querry}`);

      return response?.data?.data?.recipies;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// add recipe in folder
export const addRecipeInFolder = createAsyncThunk(
  "addRecipeInFolder",
  async ({ recipeId, folderName }) => {
    const response = await axios.post("/profile/saved/recipe", {
      recipeId,
      folderName,
    });
    return response.data;
  }
);

// get all saved folders
export const getSavedFolders = createAsyncThunk("getSavedFolders", async () => {
  const response = await axios.get("/profile/getSavedFolders");
  return response.data;
});

// Get recipe of folders with folder name
export const getFolderRecipe = createAsyncThunk(
  "getFolderRecipe",
  async (folderName) => {
    const response = await axios.get(`/profile/saved/recipes/${folderName}`);
    return response.data;
  }
);

// multi selected recipes in multiple folders
export const multiSeclectedRecipesInMultipeFolders = createAsyncThunk(
  "multiSeclectedRecipesInMultipeFolders",
  async ({ recipeId, folderName }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/profile/update/recipe", {
        recipeId,
        folderName,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// getting latest recipe on home page
export const fetchLatestRecipes = createAsyncThunk(
  "fetchLatestRecipes",
  async () => {
    const response = await axios.get(
      "/public/RecipesByParams/list/latest_recipes"
    );
    return response.data;
  }
);
