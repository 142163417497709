import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  reviews: [],
  loading: false,
  error: null,
};

export const getProductReview = createAsyncThunk(
  "reviews/getProductReview",
  async (productId, { rejectWithValue }) => {
    console.log("🚀 ~ productId:", productId)
    try {
      const response = await axios.get(`/admin/reviews/product/${productId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postProductReview = createAsyncThunk(
  "reviews/postProductReview",
  async (newReview, { rejectWithValue }) => {
    try {
      const response = await axios.post("/admin/reviews", newReview);
      toast.success("Review posted successfully")
      return response.data;
    } catch (error) {
      console.log("🚀 ~ error:", error)
      toast.error(error.response.data.error)
      return rejectWithValue(error.response.data);
    }
  }
);

const reviewsSlice = createSlice({
  name: "reviewsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProductReview.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProductReview.fulfilled, (state, action) => {
      state.reviews = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getProductReview.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(postProductReview.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(postProductReview.fulfilled, (state, action) => {
      state.loading = false;
      state.reviews = action.payload;
      state.error = null;
    });
    builder.addCase(postProductReview.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default reviewsSlice.reducer;
