import { createSlice } from "@reduxjs/toolkit";
import {
  addfavRecipe,
  getCategoryRecipe,
  getSingleRecipe,
  getRecipes,
  getRecipesByCategories,
  getfavRecipe,
  serarchRecipes,
  Rating,
  getFilterdRecipeHandler,
  addRecipeInFolder,
  getFolderRecipe,
  getSavedFolders,
  multiSeclectedRecipesInMultipeFolders,
  fetchLatestRecipes,
} from "./recipeActions";

const initialState = {
  recipes: {},
  // folderRecipe: [],
  singleRecipe: {},
  favRecipe: {},
  getSearchRecipe: {},
  starsRating: {},
  categoryRecipe: {},
  getFilterQuerryRecipeState: [],
  savedFolder: [],
  folderRecipesByName: [],
  // selectedFoldersName: [],
  fetchingLatestRecipe: null,
  recipeInMultipleFolders: null,
  loading: false,
  error: null,
};

const recipeSlice = createSlice({
  name: "recipeSlice",
  initialState,
  // getting recipes by category on recipes page
  extraReducers: (builder) => {
    builder.addCase(getRecipesByCategories.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getRecipesByCategories.fulfilled, (state, action) => {
      const { title, data } = action.payload;
      state.recipes[title] = data;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getRecipesByCategories.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //getting recipe on single page
    builder.addCase(getSingleRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSingleRecipe.fulfilled, (state, action) => {
      state.singleRecipe = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getSingleRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // getting recipe category on view all category page

    builder.addCase(getCategoryRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getCategoryRecipe.fulfilled, (state, action) => {
      const { title, data } = action.payload;
      state.categoryRecipe[title] = data;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getCategoryRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // builder.addCase(getCategoryRecipe.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(getCategoryRecipe.fulfilled, (state, action) => {
    //   state.categoryRecipe = action.payload;
    //   state.loading = false;
    //   state.error = null;
    // });

    // builder.addCase(getCategoryRecipe.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    // Search recipe
    builder.addCase(serarchRecipes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(serarchRecipes.fulfilled, (state, action) => {
      state.getSearchRecipe = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(serarchRecipes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // fav Recipe
    builder.addCase(getfavRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getfavRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getfavRecipe.fulfilled, (state, action) => {
      state.favRecipe = action.payload;

      state.loading = false;
      state.error = null;
    });

    // Saved Recipe
    builder.addCase(getRecipes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getRecipes.fulfilled, (state, action) => {
      state.recipes = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getRecipes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Stars Rating
    builder.addCase(Rating.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(Rating.fulfilled, (state, action) => {
      state.rating = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(Rating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Filter Recipe

    builder.addCase(getFilterdRecipeHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getFilterdRecipeHandler.fulfilled, (state, action) => {
      state.getFilterQuerryRecipeState = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getFilterdRecipeHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Add recipe in folder
    builder.addCase(addRecipeInFolder.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addRecipeInFolder.fulfilled, (state) => {
      state.loading = false;
      state.success = true;
    });
    builder.addCase(addRecipeInFolder.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // get all saved folders
    builder.addCase(getSavedFolders.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getSavedFolders.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.savedFolder = action.payload;
    });
    builder.addCase(getSavedFolders.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    // Get folder recipe with folder name
    builder.addCase(getFolderRecipe.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getFolderRecipe.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.folderRecipesByName = action.payload;
    });
    builder.addCase(getFolderRecipe.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });

    // multi selected recipes in multiple folders
    builder.addCase(multiSeclectedRecipesInMultipeFolders.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(
      multiSeclectedRecipesInMultipeFolders.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        state.recipeInMultipleFolders = action.payload;
      }
    );
    builder.addCase(
      multiSeclectedRecipesInMultipeFolders.rejected,
      (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      }
    );

    // getting latest recipe on home page
    builder.addCase(fetchLatestRecipes.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(fetchLatestRecipes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.fetchingLatestRecipe = action.payload;
    });
    builder.addCase(fetchLatestRecipes.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
  },
});

export default recipeSlice.reducer;
