import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { store } from "../redux/store";
import Loader from "../components/global/Loader";
import RecipeFilters from "../components/recipes-components/recipe-filters";
import FavoriteRecipes from "../pages/favorite-recipe";
import FolderSavedRecipes from "../pages/favorite-recipe/FolderSavedRecipes";
import Blogs from "../pages/blogs";
// import Subscription from "../components/subscription-plan-component";
import BlogDetail from "../pages/blog-details";
import PaymentSucess from "../pages/payment-success";
import HealthEvaluation from "../pages/health-evaluation";
import ViewBlog from "../components/blog-components/viewblog";
import LanguageForm from "../components/health-evaluation-forms/languageForm";

// Lazy load components
const Home = lazy(() => import("../pages/home/index"));
const About = lazy(() => import("../pages/about"));
const Login = lazy(() => import("../pages/login"));
const Signup = lazy(() => import("../pages/singup"));
const Recipes = lazy(() => import("../pages/recipes"));
const RecipesCategory = lazy(() => import("../pages/recipes/Recipes-category"));
// workout progrm
const WorkoutProgram = lazy(() => import("../pages/workout-program"));
const WorkoutProgramCategory = lazy(() =>
  import("../pages/workout-program/workout-program-category")
);
const WorkOutProgramChallenges = lazy(() =>
  import("../pages/workout-program/workout-program-challenges")
);
// workout video
const WorkoutVideos = lazy(() => import("../pages/workout-videos"));
const WorkoutVideosCategory = lazy(() =>
  import("../pages/workout-videos/WorkoutVideosCategory")
);

const MyFitnessJourney = lazy(() => import("../pages/my-fitness-journey"));
const MyTeam = lazy(() => import("../pages/my-team"));
const Layout = lazy(() => import("../components/layout/index"));
const MyScheduleChallengeComponent = lazy(() =>
  import("../components/my-schedule-challenge-component")
);
const MyteamComponents = lazy(() => import("../components/myteam-components"));
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"));
const TermsAndConditions = lazy(() => import("../pages/terms-and-condition"));
const FavouriteVideosComponent = lazy(() =>
  import("../components/favourite-videos")
);
const Teams = lazy(() => import("../pages/teams"));
const SavedRecipesComponent = lazy(() =>
  import("../components/saved-recipes-component")
);
const MyProfile = lazy(() => import("../pages/my-profile"));
const Tabs = lazy(() => import("../components/myteam-components/tabs"));
const FaqPageComponent = lazy(() => import("../components/faq"));
const MyAchievementComponents = lazy(() =>
  import("../components/my-achievements-components")
);
const WorkoutHistory = lazy(() => import("../pages/workout-history"));
const MySchedule = lazy(() => import("../pages/my-schedule"));
const ProgressPhotos = lazy(() => import("../pages/progress-photos"));
const MyJournal = lazy(() => import("../pages/my-journal"));
const CorporateServices = lazy(() => import("../pages/corporate-services"));
const FormComponent = lazy(() =>
  import("../components/myteam-components/create-team/team-prefrence/team-form")
);
const TeamTabs = lazy(() => import("../components/myteam-components/tabs"));
const TeamPrefrences = lazy(() =>
  import("../components/myteam-components/create-team/team-prefrence")
);
const StorePage = lazy(() => import("../pages/storepage"));
const ForgotPassword = lazy(() => import("../components/forgot-password"));
const Checkout = lazy(() => import("../pages/checkout"));
const RecipeDescription = lazy(() =>
  import("../pages/recipes/recipe-description")
);
const ProfileLayout = lazy(() => import("../components/layout/profile-layout"));
const Notifications = lazy(() => import("../components/notification"));
const Prefrences = lazy(() => import("../components/prefrences"));
const EmailPassword = lazy(() => import("../components/email&password"));
const Subscription = lazy(() =>
  import("../components/subscription-plan-component/")
);
const CloseAccount = lazy(() => import("../components/close-account"));
const SavedRecipesList = lazy(() =>
  import(
    "../components/recipe-description-component/recipe-making/SavedRecipes"
  )
);
const SubscriptionPlan = lazy(() => import("../pages/subscription-plan"));
const ViewAllWorkout = lazy(() => import("../pages/view-all-workout-program"));
const TeamCreated = lazy(() =>
  import("../components/myteam-components/team-created")
);
const UpgradeAccount = lazy(() => import("../pages/updrade-account"));
const CheckoutPage = lazy(() => import("../pages/checkoutpage"));
const ViewAllVideos = lazy(() => import("../pages/viewall-workout-videos"));
const RecipesComponents = lazy(() =>
  import("../components/recipes-components")
);
const MainLayout = lazy(() => import("../components/layout/main-layout"));
const FavoriteVideos = lazy(() => import("../pages/favorite-videos"));
const MainRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/choose-language" element={<LanguageForm />} />
        
        <Route path="/health-evaluation" element={<HealthEvaluation />} />
        <Route path="/view-blog/:id" element={<ViewBlog />} />

        <Route path="/success" element={<PaymentSucess />} />
        <Route
          path="/cancel"
          element={
            <div className="flex justify-center items-center text-3xl mt-6">
              {" "}
              Item Purchased Failed 🥺{" "}
            </div>
          }
        />
        {/*Blog route */}
        <Route path="/blog" element={<Blogs />} />
        <Route path="/pricing" element={<Subscription />} />

        <Route path="/blog/:blogId" element={<BlogDetail />} />

        <Route path="/upgrade-account" element={<UpgradeAccount />} />
        <Route path="/login" element={<Login />} />
        <Route path="/subscription-plan" element={<SubscriptionPlan />} />
        {/*<Route path="/view-allprogram" element={<ViewAllWorkout />} /> */}
        <Route path="/view-allvideos" element={<ViewAllVideos />} />
        <Route path="/signup" element={<Signup />} />

        <Route path="/recipes" element={<Recipes />} />
        <Route path="/recipe-filters" element={<RecipeFilters />} />
        <Route path="/recipes/:recipe-category" element={<RecipesCategory />} />

        <Route path="/favorite-recipes" element={<FavoriteRecipes />} />
        <Route
          path="/favorite-recipes/:folderName"
          element={<FolderSavedRecipes />}
        />
        {/* workout video routes*/}
        <Route path="/workoutvideos" element={<WorkoutVideos />} />
        <Route
          path="/workoutvideos/:workoutvideos-category"
          element={<WorkoutVideosCategory />}
        />
        {/* workout program routes*/}
        <Route path="/workout-program" element={<WorkoutProgram />} />
        <Route
          path="/workout-program/:workoutprogram-category"
          element={<WorkoutProgramCategory />}
        />
        <Route
          path="/workout-program/:name/:id"
          element={<WorkOutProgramChallenges />}
        />

        <Route path="/favorites-videos" element={<FavoriteVideos />} />
        <Route path="/store" element={<StorePage />} />
        <Route path="/about" element={<About />} />
        <Route></Route>

        <Route path="/coporate-services" element={<CorporateServices />} />
        <Route path="/save-recipe" element={<SavedRecipesList />} />
        <Route path="/my-fitness-journey" element={<Layout />}>
          <Route path="workout-history" element={<WorkoutHistory />} />
          <Route path="my-progress-photos" element={<ProgressPhotos />} />
          <Route path="my-journal" element={<MyJournal />} />
          <Route
            path="my-schedule"
            element={<MyScheduleChallengeComponent />}
          />
          <Route path="my-achievements" element={<MyAchievementComponents />} />
          <Route path="my-team" element={<MyteamComponents />}>
            <Route path="create-team" element={<TeamPrefrences />} />
            <Route path="team-created" element={<TeamCreated />} />
            <Route path="team-directory" element={<TeamTabs />} />
          </Route>
          <Route
            path="my-favorite-videos"
            element={<FavouriteVideosComponent />}
          />
          <Route path="my-saved-recipes" element={<SavedRecipesComponent />} />
        </Route>
        <Route path="/settings" element={<ProfileLayout />}>
          <Route path="prefrences" element={<Prefrences />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="change-password" element={<EmailPassword />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="close-account" element={<CloseAccount />} />
        </Route>
        <Route path="/shecdule" element={<MySchedule />} />
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/recipe/:id" element={<RecipeDescription />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-condition" element={<TermsAndConditions />} />
        <Route path="/faq" element={<FaqPageComponent />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/checkout/:id" element={<Checkout />} />
        <Route path="/main-checkout" element={<CheckoutPage />} />

        <Route path="/teams/:id"  element= {<Teams/>}/>
      </Routes>
    </Suspense>
  );
};
export default MainRoutes;
