import React, { useState, useEffect } from "react";
import vegan from "../../assets/images/vegan-chicken.webp";
import BlogSkeleton from "./BlogSkeleton";
import { Link } from "react-router-dom";
import Loader from "../global/Loader";
import axios from "../../utils/api";
const BlogSection = () => {


  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("/blog/getBlogs");
        console.log("🚀 ~ fetchBlogs ~ response:", response)
        if(response.status===200){
        setBlogData(response.data);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, []);

  // const blogData = [
  //   {
  //     image: vegan,
  //     title: "Chocolate sweet potato pancakes",
  //     description:
  //       "Who says sweet potato is only for Autumn? Boost your breakfast nutrition with these delicious sweet potato pancakes....",
  //   },
  //   {
  //     image: vegan,
  //     title: "Vegan chicken nuggets",
  //     description:
  //       "These vegan chicken nuggets are crispy, delicious, and perfect for any occasion....",
  //   },
  //   {
  //     image: vegan,
  //     title: "Healthy green smoothie",
  //     description:
  //       "Kickstart your day with this nutrient-packed green smoothie. It’s easy to make and incredibly healthy....",
  //   },
  //   {
  //     image: vegan,
  //     title: "Vegan mac and cheese",
  //     description:
  //       "Indulge in this creamy, dairy-free mac and cheese that’s perfect for vegans and non-vegans alike....",
  //   },
  // ];

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="grid lg:grid-cols-3 gap-4 my-5">
          {blogData.map((item, index) => (
            <div key={index} className="border-[1px] rounded-lg">
              <img src={`https://api.mykilo.co.uk/blogImages/${item.image}`} className="rounded-lg w-[401px] h-[534px]" alt={item.title} />
              <div className="px-3 py-4">
                <h2 className="text-2xl font-bold py-3 truncate">{item.title}</h2>
                <p className="text-base text-[#303033] line-clamp-1" dangerouslySetInnerHTML={{__html: item.description}}></p>
              </div>
              <Link to={`/view-blog/${item?._id}`}>
                <button className="mx-3 text-white bg-[#9A2925] border-[1px] border-[#9A2925] rounded-lg px-4 py-1 text-sm mb-4 hover:bg-white hover:text-[#9A2925]">
                  Learn More
                </button>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default BlogSection;
