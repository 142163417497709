// token.js

export const getAccessToken = () => {
  // Get the stored value from session storage
  // const storedValue = sessionStorage.getItem("userInfo");

  // Parse the stored JSON string
  // const storedData = storedValue ? JSON.parse(storedValue) : null;

  // Access the access token
  // return storedData?.content?.accessToken;
  return localStorage.getItem("accessToken");
};
