import React from 'react'
import BlogComponent from '../../components/blog-components'

const Blogs = () => {
  return (
    <div>
      <BlogComponent />
    </div>
  )
}

export default Blogs
