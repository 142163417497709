import React from "react";
import MainLayout from "../layout/main-layout";
import BlogSection from "./BlogSection";

const BlogComponent = () => {
  return (
    <>
      <MainLayout>
        
        <BlogSection />
      </MainLayout>
    </>
  );
};

export default BlogComponent;
