// import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";

// const initialState = {
//   carts: [],
// };

// // cart slice
// const cartSlice = createSlice({
//   name: "cartslice",
//   initialState,
//   reducers: {
//     // add to cart
//     addToCart: (state, action) => {
//       const { _id, qnty = 1 } = action.payload;
//       const itemIndex = state.carts.findIndex((item) => item._id === _id);

//       if (itemIndex >= 0) {
//         state.carts[itemIndex].qnty += 1; // Increment the quantity by qnty
//         toast.success("Item quantity updated in your cart");
//       } else {
//         state.carts.push({ ...action.payload, qnty }); // Add new item with initial quantity qnty
//         toast.success("Item added to your cart");
//       }
//     },

//     // remove particular items
//     removeToCart: (state, action) => {
//       state.carts = state.carts.filter((item) => item._id !== action.payload);
//       toast.error("Item removed from your cart");
//     },

//     // remove single items
//     removeSingleItems: (state, action) => {
//       const itemIndex = state.carts.findIndex(
//         (item) => item._id === action.payload._id
//       );
//       if (state.carts[itemIndex].qnty > 1) {
//         state.carts[itemIndex].qnty -= 1;
//         toast.success("Item quantity updated in your cart");
//       } else {
//         const updatedCarts = state.carts.filter(
//           (item) => item._id !== action.payload._id
//         );
//         state.carts = updatedCarts;
//         toast.error("Item removed from your cart");
//       }
//     },
//     // clear cart
//     emptyCartItems: (state) => {
//       state.carts = [];
//       toast.success("Your cart is emptied");
//     },
//   },
// });

// export const { addToCart, removeToCart, removeSingleItems, emptyCartItems } =
//   cartSlice.actions;

// export default cartSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("cartState");
    if (serializedState === null) {
      return { carts: [] };
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return { carts: [] };
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("cartState", serializedState);
  } catch (err) {
    // Ignore write errors
  }
};

const initialState = loadState();

const cartSlice = createSlice({
  name: "cartslice",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { _id, qnty = 1 } = action.payload;
      const itemIndex = state.carts.findIndex((item) => item._id === _id);

      if (itemIndex >= 0) {
        state.carts[itemIndex].qnty += 1;
        toast.success("Item quantity updated in your cart");
      } else {
        state.carts.push({ ...action.payload, qnty });
        toast.success("Item added to your cart");
      }

      saveState(state);
    },
    removeToCart: (state, action) => {
      state.carts = state.carts.filter((item) => item._id !== action.payload);
      toast.error("Item removed from your cart");

      saveState(state);
    },
    removeSingleItems: (state, action) => {
      const itemIndex = state.carts.findIndex(
        (item) => item._id === action.payload._id
      );
      if (state.carts[itemIndex].qnty > 1) {
        state.carts[itemIndex].qnty -= 1;
        toast.success("Item quantity updated in your cart");
      } else {
        const updatedCarts = state.carts.filter(
          (item) => item._id !== action.payload._id
        );
        state.carts = updatedCarts;
        toast.error("Item removed from your cart");
      }

      saveState(state);
    },
    emptyCartItems: (state) => {
      state.carts = [];
      toast.success("Your cart is emptied");

      saveState(state);
    },
  },
});

export const { addToCart, removeToCart, removeSingleItems, emptyCartItems } =
  cartSlice.actions;

export default cartSlice.reducer;
