export const menuItems = [
  { title: "View All Collections", link: "/recipes" },
  { title: "Latest Recipes", link: "/recipes/latest_recipes" },
  { title: "Featured Recipes", link: "/recipes/featured_recipes" },
  { title: "Budget Meals", link: "/recipes/budget_meals" },
  { title: "Easy Breakfast Ideas", link: "/recipes/easy_breakfast_ideas" },
  { title: "Healthy Desert Recipes", link: "/recipes/healthy_desert_recipes" },
  { title: "Quick And Easy Recipes", link: "/recipes/quick_and_Easy_recipes" },
  { title: "Healthy Snack Ideas", link: "/recipes/healthy_snack_ideas" },
  { title: "Healthy Drinks Recipes", link: "/recipes/healthy_drinks_recipes" },
  { title: "Easy Vegan Recipes", link: "/recipes/easy_vegan_recipes" },
  {
    title: "Healthy Pancakes Recipes",
    link: "/recipes/healthy_pancakes_recipes",
  },
  { title: "Party Food Recipes", link: "/recipes/party_food_recipes" },
];

export const COURSE = [
  "Appetizers",
  "Breakfast",
  "Desserts",
  "Drinks",
  "Mains",
  "Salads",
  "Side_Dish",
  "Snacks",
];
export const CONVENIENCE = [
  "3_Ingredients_Less",
  "5_Ingredients_Less",
  "Baking",
  "Meal_Prep",
  "No_Cook",
  "One_Pan",
];
export const PREFERENCE = ["High_Protein", "Low_Carb", "Low_Fat"];

export const DIETRY_RESTRICTION = [
  "Dairy_Free",
  "Gluten_Free",
  "Pescatarian",
  "Vegan",
  "Vegetarian",
];
export const TOTAL_TIME = ["30_Mins_Or_less", "30_Mins_+", "10_Mins_Or_Less"];
export const arrayOfObjects = [
  {
    COURSE: [
      "Appetizer",
      "Breakfast",
      "Desserts",
      "Drinks",
      "Mains",
      "Salads",
      "Side Dish",
      "Snacks",
    ],
  },
  {
    CONVENIENCE: [
      "3 ingridients Or Less",
      "5 Ingredients Or less",
      "Baking",
      "Meal Prep",
      "No-Cook",
      "One Pan",
      "",
      " ",
    ],
  }, 
  {
    PREFERENCE: ["High Protein", "Low Carb", "Low Fat", " ", " ", "", " ", " "],
  },
  {
    "DIETRY RESTRICTION": [
      "Dairy Free",
      "Gleten Free",
      "Pescatarian",
      "Vegan",
      "Vegetarian",
      "",
      "",
      " ",
    ],
  },
  {
    "TOTAL TIME": [
      "30 Mins Or Less",
      "30 Mins +",
      "10 Mins Or Less",
      " ",
      " ",
      "",
      " ",
      " ",
    ],
  },
];
export const data = [
  {
    image: require("../assets/images/protein.png"),
    heading: "high protein recipes",
  },
  {
    image: require("../assets/images/carb.png"),
    heading: "low carb recipes",
  },
  {
    image: require("../assets/images/dairy.png"),
    heading: "dairy free recipes",
  },
  {
    image: require("../assets/images/vegies.png"),
    heading: "vegetarian recipes",
  },
];

export const RecipesMenuItems = [
  { label: "Most Recent", href: "#" },
  { label: "Rating", href: "#" },
  { label: "A to Z", href: "#" },
  { label: "Z to A", href: "#" },
];

export const ContentArray = [
  {
    id: "0",
    title: "latest_recipes",
    heading: "Latest Recipes",
    description: "Try out the latest recipes and make something new!",
  },
  {
    id: "1",
    title: "featured_recipes",
    heading: "Featured Recipes",
    description:
      "Here is a list of the most popular recipes that people are loving! Try out some of these recipes to find out why everyone is raving about them.",
  },
  {
    id: "2",
    title: "trending_recipes",
    heading: "Trending Recipes",
    description:
      "From feta pasta to salmon rice bowl, these are the viral recipes that are totally worth the hype.",
  },
  {
    id: "3",
    title: "budget_meals",
    heading: "Budget Meals",
    description:
      "Discover budget-friendly recipes that use affordable pantry staple ingredients to create healthy cheap meals that won't break the bank.",
  },
  {
    id: "4",
    title: "easy_breakfast_ideas",
    heading: "Easy Breakfast Ideas",
    description:
      "Start your day right with these easy peasy ideas. Some can even be prepared ahead of time!",
  },
  {
    id: "5",
    title: "healthy_desert_recipes",
    heading: "Healthy Dessert Recipes",
    description:
      "Love dessert while on a fitness journey? These healthy yet delicious sweet treats will satisfy that sweet tooth while keeping you on track.",
  },
  {
    id: "6",
    title: "quick_and_Easy_recipes",
    heading: "Quick And Easy Recipes",
    description:
      "Recipes that require only one pan and less than 30 minutes to make. Perfect for lazy days!",
  },
  {
    id: "7",
    title: "healthy_snack_ideas",
    heading: "Healthy Snack Ideas",
    description:
      "Easy healthy snacks full of protein, fiber, and healthy fats to keep you fueled up throughout the day.",
  },
  {
    id: "8",
    title: "healthy_drinks_recipes",
    heading: "Healthy Drinks Recipes",
    description:
      "From smoothies to matcha latte or homemade boba, here are some healthy drinks to quench that thirst!",
  },
  {
    id: "9",
    title: "eeasy_vegan_recipes",
    heading: "Easy Vegan Recipes",
    description:
      "A collection of simple, delicious recipes free from dairy, meat or eggs.",
  },
  {
    id: "10",
    title: "healthy_pancakes_recipes",
    heading: "Healthy Pancakes Recipes",
    description:
      "The best healthy pancakes recipes that still taste like a treat! Pick from gluten-free, high protein, vegan, and more.",
  },
  {
    id: "11",
    title: "party_food_recipes",
    heading: "Party Food Recipes",
    description:
      "Here are some of the best finger food and bite-sized appetizers to serve at your next party or picnic.",
  },
];

export const recipeCategories = [
  {
    id: "0",
    title: "latest_recipes",
    description: "",
    link: "/recipes/latest_recipes",
  },
  {
    id: "1",
    title: "featured_recipes",
    description:
      "Here is a list of the most popular recipes that people are loving! Try out some of these recipes to find out why everyone is raving about them.",
    link: "/recipes/featured_recipes",
  },
  {
    id: "2",
    title: "trending_recipes",
    description:
      "From feta pasta to salmon rice bowl, these are the viral recipes that are totally worth the hype.",
    link: "/recipes/trending_recipes",
  },
  {
    id: "3",
    title: "budget_meals",
    description:
      "Discover budget-friendly recipes that use affordable pantry staple ingredients to create healthy cheap meals that won't break the bank.",
    link: "/recipes/budget_meals",
  },
  {
    id: "4",
    title: "easy_breakfast_ideas",
    description:
      "Start your day right with these easy peasy ideas. Some can even be prepared ahead of time!",
    link: "/recipes/easy_breakfast_ideas",
  },
  {
    id: "5",
    title: "healthy_desert_recipes",
    description:
      "Love dessert while on a fitness journey? These healthy yet delicious sweet treats will satisfy that sweet tooth while keeping you on track.",
    link: "/recipes/healthy_desert_recipes",
  },
  {
    id: "6",
    title: "quick_and_Easy_recipes",
    description:
      "Recipes that require only one pan and less than 30 minutes to make. Perfect for lazy days!",
    link: "/recipes/quick_and_Easy_recipes",
  },
  {
    id: "7",
    title: "healthy_snack_ideas",
    description:
      "Easy healthy snacks full of protein, fiber, and healthy fats to keep you fueled up throughout the day.",
    link: "/recipes/healthy_snack_ideas",
  },
  {
    id: "8",
    title: "healthy_drinks_recipes",
    description:
      "From smoothies to matcha latte or homemade boba, here are some healthy drinks to quench that thirst!",
    link: "/recipes/healthy_drinks_recipes",
  },
  {
    id: "9",
    title: "easy_vegan_recipes",
    description:
      "A collection of simple, delicious recipes free from dairy, meat or eggs.",
    link: "/recipes/easy_vegan_recipes",
  },
  {
    id: "10",
    title: "healthy_pancakes_recipes",
    description:
      "The best healthy pancakes recipes that still taste like a treat! Pick from gluten-free, high protein, vegan, and more.",
    link: "/recipes/healthy_pancakes_recipes",
  },
  {
    id: "11",
    title: "party_food_recipes",
    description:
      "Here are some of the best finger food and bite-sized appetizers to serve at your next party or picnic.",
    link: "/recipes/party_food_recipes",
  },
];

export const recipesConstant = [
  "latest_recipes",
  "featured_recipes",
  "trending_recipes",
  "budget_meals",
  "easy_breakfast_ideas",
  "healthy_desert_recipes",
  "quick_and_Easy_recipes",
  "healthy_snack_ideas+",
  "healthy_drinks_recipes",
  "easy_vegan_recipes",
  "healthy_pancakes_recipes",
  "party_food_recipes",
];