const styles = {
    customContainer: "max-w-[1240px] mx-auto",
  
    heading: "font-semibold text-[22px] text-[#000000d9] leading-[30px] capitalize",
    paragraph: "text-[#303030] text-[14px] leading-[19.6px] text-wrap",
  
    flexBetween: "flex justify-between items-center gap-4",
    flexCenter: "flex justify-center items-center",

  
    // paddingX: "sm:px-16 px-6",
    paddingY: "py-[21px]",
    // padding: "sm:px-16 px-6 sm:py-12 py-4",
  
    marginX: "sm:mx-16 mx-6",
    marginY: "my-[21px]",
  };
  
  export const layout = {
    section: `flex md:flex-row flex-col ${styles.paddingY}`,
    sectionReverse: `flex md:flex-row flex-col-reverse ${styles.paddingY}`,
  
    sectionImgReverse: `flex-1 flex ${styles.flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
    sectionImg: `flex-1 flex ${styles.flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,
  
    sectionInfo: `flex-1 ${styles.flexStart} flex-col`,
  };
  
  export default styles;