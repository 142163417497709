import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const addTeam = createAsyncThunk(
  "addTeam",
  async (teamData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/team/createTeams", teamData);
     return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);

// export const getMyTeam = createAsyncThunk("getTeam", async () => {
//   try {
//     const response = await axios.get("/team/getTeams");
//     return response.data;
//   } catch (error) {
//     throw Error("Failed to fetch team");
//   }
// });

export const getTeam = createAsyncThunk("getTeam", async () => {
  try {
    const response = await axios.get("/team/getMyTeam");
    return response.data;
  } catch (error) {
    throw Error("Failed to fetch team");
  }
});
export const getTeamById = createAsyncThunk(
  "getTeamById",
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/team/getAteam/${teamId}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);
export const deleteTeam = createAsyncThunk(
  "deleteTeam",
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/team/deleteATeam/${teamId}`);
      toast.success("Team deleted successfully");
      return response.data;
    } catch (error) {
      // Return the error message as a rejected action payload
      toast.error(error.message);
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTeam = createAsyncThunk(
  "updateTeam",
  async ({ id, formData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/team/updateATeam/${id}`, formData);
      toast.success("Team updated successfully");
      return response.data;
    } catch (error) {
      toast.error("Failed to update team. Please try again.");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const getAllTeams = createAsyncThunk(
  "getAllTeams",
  async ({ page = 1, limit = 10 } = {}, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/team/getTeams`, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      toast.error("Failed to fetch teams. Please try again.");
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateTeamViewCount = createAsyncThunk(
  "updateTeamViewCount",
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/team/updateViewCount/${teamId}`);
      //toast.success("Team view count updated successfully");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      //
      toast.error("Failed to update team view count. Please try again.");
      return rejectWithValue({ message: error.message });
    }
  }
);


// ********************************************************** Team Requests ********************************************************//

export const sendRequest = createAsyncThunk(
  "sendRequest",
  async (teamId, { rejectWithValue }) => {
    try {
      const response = await axios.post("/team/join/request", { teamId });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);

// Respond to a request to join a team
export const respondToRequest = createAsyncThunk(
  "respondToRequest",
  async ({ invitationId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.post("/team/join/respond", {
        invitationId,
        status,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);

// Get requests for the authenticated user
export const getRequests = createAsyncThunk(
  "getRequests",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/team/requests");
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);
