import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import profileavatar1 from "../../assets/images/profileavatar1.png";
import { useSelector, useDispatch } from "react-redux";
import BASE_API_URL from "../../apiConfig";

import { Link, useLocation, useNavigate } from "react-router-dom";
import emptyAvatar from "../../assets/images/emptyAvatar.png";

import { Logout } from "../../redux/slices/auth";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.auth?.profile?.content);

  const [photoURL, setPhotoURL] = useState(emptyAvatar);

  useEffect(() => {
    const storedPhotoURL = localStorage.getItem("photoURL");
    if (storedPhotoURL) {
      setPhotoURL(storedPhotoURL);
    } else if (profile?.profile_picture) {
      setPhotoURL(`${BASE_API_URL}/profile/${profile?.profile_picture}`);
    }
  }, [profile]);

  const handleLogout = () => {
    dispatch(Logout());

    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };
  const menuItems = [
    { title: "Profile", to: "/my-profile" },
    { title: "Settings", to: "/settings/change-password" },
    { title: "Upgrade Account", to: "/pricing" },
    // Add more menu items as needed
    { title: "Logout", onClick: handleLogout, to: "/" },
  ];
  return (
    <Menu
      as="div"
      className="relative lg:block inline-block text-left rounded-md"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md py-2 text-sm font-semibold text-gray-900 ">
          <img
            src={photoURL}
            alt="Avatar"
            className="h-[40px] w-[40px] rounded-full"
          />

          {/* <img
            src={
              profile?.profile_picture
                ? `${BASE_API_URL}/profile/${profile?.profile_picture}`
                : emptyAvatar
            }
            alt="Avatar"
            className="h-[40px] w-[40px] rounded-full"
          /> */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-[10px] py-[20px]">
            {menuItems.map((item, index) => (
              <Fragment key={index}>
                {item.title === "Logout" ? (
                  <hr className="my-1 border-t border-gray-200" />
                ) : null}
                <Menu.Item>
                  <Link
                    to={item.to}
                    onClick={item?.onClick}
                    className={classNames(
                      " text-gray-900 block px-4 py-2 text-sm"
                    )}
                  >
                    {item.title}
                  </Link>
                </Menu.Item>
              </Fragment>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
