import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/api";

// Async thunk action to create a product checkout session
export const createProductCheckoutSession = createAsyncThunk(
  "checkout/createProductCheckoutSession",
  async ({ cart }, { rejectWithValue }) => {
    let data = { products: cart };

    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        return rejectWithValue({ error: "You need to login first" });
      }

      const response = await axios.post(
        "/product/create-product-checkout-session",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  sessionId: null,
  loading: false,
  error: null,
};

const productCheckoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle createProductCheckoutSession
      .addCase(createProductCheckoutSession.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProductCheckoutSession.fulfilled, (state, action) => {
        state.sessionId = action.payload.id;
        state.loading = false;
        state.error = null;
      })
      .addCase(createProductCheckoutSession.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload
          ? action.payload.error || "Something went wrong"
          : "Network error";
      });
  },
});

export default productCheckoutSlice.reducer;
