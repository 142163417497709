import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api";

// Post request of profile progress photo
export const addProgressPhoto = createAsyncThunk(
  "addProgressPhoto",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "/profile/ProgressImages/AddImages",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Get request of profile progress photo
export const getProgressPhoto = createAsyncThunk(
  "getProgressPhoto",
  async () => {
    try {
      const response = await axios.get("/profile/getImages");
      return response.data;
    } catch (error) {
      // You can handle errors here
      throw Error("Failed to fetch images");
    }
  }
);

// Update profile progress photo
export const updateProgressPhoto = createAsyncThunk(
  "updateProgressPhoto",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/profile/updateProgressImages/${formData.get("id")}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

// Delete profile progress photo
export const deleteProgressPhoto = createAsyncThunk(
  "deleteProgressPhoto",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/profile/delete/bodyImages/${id}`);
      return response.data;
    } catch (error) {
      // Return a rejected action with the error message
      return rejectWithValue(error.response.data);
    }
  }
);
