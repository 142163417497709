import React from 'react';
import { Link } from 'react-router-dom';

const ButtonComp = ({ label, placeholder, onClick, link }) => {
  return (
    <div>
      <button
        className="rounded-full text-[14px] font-medium  border border-[rgb(48,48,51)] hover:border-[#9c3022] hover:bg-[#9c3022] lg:mt-0 mt-3 hover:text-[#ffff] px-4 py-2"
        onClick={onClick}
        placeholder={placeholder}
      >
         <Link to={link}>{label}</Link>
      </button>
    </div>
  );
};

export default ButtonComp;
