import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getSearchWorkoutProgram: {},
  loading: false,
  error: null,
};

export const getSearchWorkoutProgramHandler = createAsyncThunk(
  "getSearchWorkoutProgramHandler",
  async (querry, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/searchingPrograms/?${querry}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getSearchWorkoutProgram = createSlice({
  name: "getSearchWorkoutProgram",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSearchWorkoutProgramHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(
      getSearchWorkoutProgramHandler.fulfilled,
      (state, action) => {
        state.getSearchWorkoutProgram = action.payload;
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      getSearchWorkoutProgramHandler.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default getSearchWorkoutProgram.reducer;
