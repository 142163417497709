import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { RxCross2 } from "react-icons/rx";
import { IoCartOutline } from "react-icons/io5";
import {
  removeToCart,
  removeSingleItems,
  emptyCartItems,
  addToCart,
} from "../../redux/slices/cart/cartSlice";
import { toast } from "react-toastify";
// Import your Stripe API function here
import { loadStripe } from "@stripe/stripe-js";
import { createProductCheckoutSession } from "../../redux/slices/products/productCheckoutSlice";
import { RiDeleteBin6Line } from "react-icons/ri";
//
const stripePromise = loadStripe(
  "pk_test_51PNsJHAwcH3sjAbIM1cXiCWcUABMe8MU3D76UwBAKvQiTRm0NtaySi20WEcrQfHtQu0wEDStwTj9jqqPtbB1g93J00Uz0x823E"
);
function CartDetail() {
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { carts } = useSelector((state) => state.allCart);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const cancelButtonRef = useRef(null);

  const handleRemoveFromCart = (item) => {
    dispatch(removeToCart(item));
  };

  const handleIncrement = (item) => {
    dispatch(addToCart(item));
  };

  const handleSingleDecrement = (item) => {
    dispatch(removeSingleItems(item));
  };
  const emptyCart = () => {
    dispatch(emptyCartItems());
  };
  const handleCheckout = async () => {
    setIsLoading(true);
    try {
      const action = await dispatch(
        createProductCheckoutSession({ cart: carts })
      );
      if (createProductCheckoutSession.fulfilled.match(action)) {
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId: action.payload.id });
        emptyCart()
      } else if (createProductCheckoutSession.rejected.match(action)) {
        toast.error(action.payload.error || "Something went wrong");
      }
    } catch (error) {
      toast.error(error.message || "Something went wrong with checkout");
    } finally {
      setIsLoading(false);
    }
  };

 

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const calculateTotal = () => {
      let total = 0;
      carts.forEach((item) => {
        total += item.price * item.qnty;
      });
      setTotalPrice(total);
    };

    const calculateQuantity = () => {
      let total = 0;
      carts.forEach((item) => {
        total += item.qnty;
      });
      setTotalQuantity(total);
    };

    calculateTotal();
    calculateQuantity();
  }, [carts]);

  return (
    <div>
      <div className="relative">
        <span className="absolute top-0 right-0 bg-[#9c3022]  text-white text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
          {carts.length}
        </span>
        <IoCartOutline
          className="cursor-pointer mx-3"
          size={25}
          onClick={() => setIsCartOpen(true)}
        />
      </div>
      <Transition.Root show={isCartOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setIsCartOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                  <div className="text-2xl mb-2">Cart Items</div>
                  <div className="absolute top-3 right-2">
                    <span
                      className="p1 fa-stack fa-2x has-badge"
                      data-count={carts?.length}
                    >
                      <RxCross2
                        className="cursor-pointer"
                        size={23}
                        onClick={() => setIsCartOpen(false)}
                      />
                    </span>
                  </div>

                  <div className="flex flex-col gap-4">
                    {carts.length > 0 ? (
                      carts.map((item) => (
                        <div
                          key={item.id}
                          className="flex items-center border-b py-2"
                        >
                          <img
                            src={`${process.env.REACT_APP_API_BASE_URL}/product/${item?.images[0]}`}
                            alt={item.productName}
                            className="w-12 h-12 object-cover rounded"
                          />
                          <div className="ml-2">
                            <p className="text-lg font-semibold">
                              {item.productName}
                            </p>
                            <p className="text-gray-500">
                              Price: £{item.price}
                            </p>
                            <p className="text-gray-500">
                              Quantity: {item.qnty}
                            </p>
                          </div>
                          <div className="ml-auto flex gap-2">
                            <button
                              className="text-red-600 hover:text-red-700"
                              onClick={() => handleSingleDecrement(item)}
                            >
                              -
                            </button>
                            <button
                              className="text-green-600 hover:text-green-700"
                              onClick={() => handleIncrement(item)}
                            >
                              +
                            </button>
                            <button
                              onClick={() => handleRemoveFromCart(item._id)}
                              className="text-[#9c3022] hover:text-red-700"
                            >
                              <RiDeleteBin6Line />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="text-lg text-center text-gray-500">
                        Your cart is empty
                      </p>
                    )}
                  </div>
                  {carts.length > 0 && (
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <span>Total Quantity: {totalQuantity}</span>
                        <span>Total Price: £{totalPrice.toFixed(2)}</span>
                      </div>
                      <div className="flex flex-wrap justify-between">
                        <button
                          className="mt-4 w-fit border border-[#9c3022] rounded-full text-gray-800 px-3 py-1 "
                          onClick={emptyCart}
                        >
                          Empty Cart
                        </button>
                        <button
                          disabled={isLoading}
                          className={`${
                            isLoading ? "bg-opacity-50 bg-black" : ""
                          } mt-4 w-fit bg-[#9c3022] rounded-full text-white px-3 py-1 `}
                          onClick={handleCheckout}
                        >
                          Checkout
                        </button>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default CartDetail;
