import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getUpgradePlan: [],
  loading: false,
  error: null,
};

export const getUpgradePlanHandler = createAsyncThunk(
  "getUpgradePlanHandler",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/fitness/fetch/fitness-plans`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUpgradePlanSlice = createSlice({
  name: "getUpgradePlanSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUpgradePlanHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getUpgradePlanHandler.fulfilled, (state, action) => {
      state.getUpgradePlan = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getUpgradePlanHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getUpgradePlanSlice.reducer;
