import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  deleteChallenge: {},
  loading: false,
  error: null,
};

export const deleteChallengeApiHandler = createAsyncThunk(
  "deleteChallengeApiHandler",
  async (id, { rejectWithValue }) => {
    const deleteProgram = {
      programId: id,
    };

    try {
      const response = await axios.delete(`/admin/stopProgram`, {
        data: deleteProgram,
      });

      toast.success(`${response?.data?.message}`);
      return response?.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);
      return rejectWithValue(error.response.data);
    }
  }
);

const deleteChallengeSlice = createSlice({
  name: "deleteChallengeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(deleteChallengeApiHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deleteChallengeApiHandler.fulfilled, (state, action) => {
      state.deleteChallenge = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(deleteChallengeApiHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default deleteChallengeSlice.reducer;
