import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/api";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipes: [],
  searchRecipe: [],
  favRecipe: [],
  rating: {},
  categoryRecipe: {},
  recipeDetails: {},
  popularRecipes: {},
  recipeCategoryList: {},
  error: null,
  loading: false,
};

export const getRecipeCategoryList = createAsyncThunk(
  "getRecipeCategoryList",
  async (category, { rejectWithValue }) => {
    try {
      // Assuming the API endpoint allows fetching a specific recipe by id
      const response = await axios.get(
        `public/RecipesByParams/list/${category}`
        // `public/RecipesByParams/list/${category?.recipe_Categorie || "latest_recipes"}`
      );

      return {
        title: response.data?.title,
        data: {
          items: response.data?.items,
          description: response.data?.items?.recipe_description,
          // link: category?.link,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createRecipe = createAsyncThunk(
  "createRecipe",
  async (recipeData, { rejectWithValue }) => {
    try {
      const response = await axios.post("recipes", recipeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRecipes = createAsyncThunk(
  "getRecipes",
  async (querry, { rejectWithValue }) => {
    try {
      const url = `public/recipes?${querry}`;
      const encoded = encodeURI(url);
      const drcodedURL = decodeURI(encoded);

      const response = await axios.get(drcodedURL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCategoryRecipe = createAsyncThunk(
  "getCategoryRecipe",
  async (recipeCategory, { rejectWithValue }) => {
    try {
      const url = `/public/categoryRecipe/${recipeCategory}`;

      const encoded = encodeURI(url);
      const drcodedURL = decodeURI(encoded);

      const response = await axios.get(drcodedURL);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
); 

export const getPopularRecipes = createAsyncThunk(
  "getPopularRecipes",
  async (_, { rejectWithValue }) => {
    try {
      const url = `/public/popularRecipe`;

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const serarchRecipes = createAsyncThunk(
  "serarchRecipes",
  async (querry, { rejectWithValue }) => {
    try {
      const url = `/public/searching?${querry}`;
      const encoded = encodeURI(url);
      const drcodedURL = decodeURI(encoded);

      const response = await axios.get(drcodedURL);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getRecipe = createAsyncThunk(
  "getRecipe",
  async (id, { rejectWithValue }) => {
    try {
      // Assuming the API endpoint allows fetching a specific recipe by id
      const response = await axios.get(`public/recipe/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const favRecipe = createAsyncThunk(
  "favRecipe",
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU1YmZkM2UxNjNjYmIzZTI2YTViMWIiLCJpYXQiOjE3MTA0MDU5NTN9.nWDlyV3mez-uXw4WBmZgXIBtzipML1jnLpq9qxQOk50`,
          // Other headers if needed
        },
      };
      const response = await axios.post(
        "/profile/favorite/recipe",
        { recipeId: id },
        config
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getfavRecipe = createAsyncThunk(
  "getfavRecipe",
  async (id, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU1YmZkM2UxNjNjYmIzZTI2YTViMWIiLCJpYXQiOjE3MTA0MDU5NTN9.nWDlyV3mez-uXw4WBmZgXIBtzipML1jnLpq9qxQOk50`,
          // Other headers if needed
        },
      };
      const response = await axios.get("profile/favorite/getRecipe", config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateRecipe = createAsyncThunk(
  "updateRecipe",
  async ({ recipeId, recipeData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`recipes/${recipeId}`, recipeData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteRecipe = createAsyncThunk(
  "deleteRecipe",
  async (recipeId, { rejectWithValue }) => {
    try {
      await axios.delete(`recipes/${recipeId}`);
      return recipeId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const Rating = createAsyncThunk(
  "Rating",
  async (ratingObj, { rejectWithValue }) => {
    try {
      // const config = {
      //   headers: {
      //     authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWU1YmZkM2UxNjNjYmIzZTI2YTViMWIiLCJpYXQiOjE3MTA0MDU5NTN9.nWDlyV3mez-uXw4WBmZgXIBtzipML1jnLpq9qxQOk50`,
      //     // Other headers if needed
      //   },
      // };
      const response = await axios.post("/public/rating", ratingObj);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const recipeSlice = createSlice({
  name: "recipe",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(createRecipe.fulfilled, (state, action) => {
      state.recipes.push(action.payload);
      state.loading = false;
      state.error = null;
    });

    builder.addCase(createRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // builder.addCase(getRecipes.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(getRecipes.fulfilled, (state, action) => {
    //   state.recipes = action.payload;
    //   state.loading = false;
    //   state.error = null;
    // });

    // builder.addCase(getRecipes.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(getCategoryRecipe.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(getCategoryRecipe.fulfilled, (state, action) => {
    //   state.categoryRecipe = action.payload;
    //   state.loading = false;
    //   state.error = null;
    // });

    // builder.addCase(getCategoryRecipe.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    builder.addCase(serarchRecipes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(serarchRecipes.fulfilled, (state, action) => {
      state.searchRecipe = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(serarchRecipes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // builder.addCase(favRecipe.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(favRecipe.fulfilled, (state, action) => {
    //   state.favRecipe = action.payload;
    //   state.loading = false;
    //   state.error = null;
    // });

    // builder.addCase(favRecipe.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });
    // builder.addCase(getRecipe.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(getRecipe.fulfilled, (state, action) => {
    //   state.recipes = action.payload;
    //   state.loading = false;
    //   state.error = null;
    // });

    // builder.addCase(getRecipe.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(getfavRecipe.rejected, (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // });

    // builder.addCase(getfavRecipe.pending, (state) => {
    //   state.loading = true;
    //   state.error = null;
    // });

    // builder.addCase(getfavRecipe.fulfilled, (state, action) => {
    //   state.favRecipe = action.payload;

    //   state.loading = false;
    //   state.error = null;
    // });
    builder.addCase(getPopularRecipes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getPopularRecipes.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getPopularRecipes.fulfilled, (state, action) => {
      state.popularRecipes = action.payload;

      state.loading = false;
      state.error = null;
    });

    builder.addCase(updateRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(updateRecipe.fulfilled, (state, action) => {
      // Update the specific recipe in the state based on your application's structure
      state.recipeDetails = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(updateRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(deleteRecipe.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(deleteRecipe.fulfilled, (state, action) => {
      // Remove the deleted recipe from the state based on your application's structure
      state.recipes = state.recipes.filter(
        (recipe) => recipe.id !== action.payload
      );
      state.loading = false;
      state.error = null;
    });

    builder.addCase(deleteRecipe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(Rating.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(Rating.fulfilled, (state, action) => {
      state.rating = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(Rating.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getRecipeCategoryList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getRecipeCategoryList.fulfilled, (state, action) => {
      state.recipeCategoryList = action.payload; // directly store the fetched data
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getRecipeCategoryList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default recipeSlice.reducer;
