import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/api"; // Make sure to use the correct path for your axios instance

const initialState = {
  achievements: {
    dearDiary: {},
    stranger: {},
    year: {},
    Journey: {},
    trackingMood: {},
    MasterPiece: {},
    foodResearcher: {},
    beforeAfter: {},
    progressinStrong: {},
    organizedChef: {},
    saveForLater: {},
  },
  success: false,
  error: null,
  loading: false,
};

export const getAchievements = createAsyncThunk(
  "getAchievements",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/achievements/general");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const achievementsSlice = createSlice({
  name: "achievements",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAchievements.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getAchievements.fulfilled, (state, action) => {
      state.achievements = action.payload;
      state.success = action.payload.success;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getAchievements.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default achievementsSlice.reducer;
