import { useState, useEffect } from "react";
import { HiFilter, HiCheck } from "react-icons/hi";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import axiosInstance from "../../utils/api";
import { GET_RECIPES } from "../../apiConfig";
//workout program filter
import { getWorkOutProgarmFilterApiHandler as workoutProgramHandler } from "../../redux/slices/workout-program-slice/get-workout-program-filterQuerry";
// workout video filter
import { getSearchQuerryWorkoutVideoHandler as workOutVideoHandler } from "../../redux/slices/workout-videos-slices/get-workoutvideo-urlQuerry";
// recipes filter
import { getFilterdRecipeHandler } from "../../redux/recipes/recipeActions";

const SubMenuFilterBtn = ({ mapData = [] }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [ispop, setIsPop] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [totalFilter, setTolalFilter] = useState(0);

  const toggleFilter = (category, value, allowMultiple = false) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [category]: allowMultiple
        ? Array.isArray(prevFilters[category])
          ? prevFilters[category].includes(value)
            ? prevFilters[category].filter((v) => v !== value)
            : [...prevFilters[category], value]
          : [value]
        : prevFilters[category] === value
        ? null
        : value,
    }));
  };

  const applyFilters = async () => {
    const createQueryString = (filters, lowercase = false) => {
      return Object.entries(filters)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            value = value.join(",");
          }
          if (lowercase) {
            value = value?.toLowerCase();
          }
          return `${key}=${value}`;
        })
        .join("&");
    };

    const queryString = createQueryString(selectedFilters);
    const lowerCaseQueryString = createQueryString(selectedFilters, true);

    setIsPop(false);
    if (location?.pathname.startsWith("/workoutvideos")) {
      navigate(`/workoutvideos?${queryString}`);
      dispatch(workOutVideoHandler(queryString));
    } else if (location?.pathname.startsWith("/recipes")) {
      // var queryParams = queryString.split("=");
      // var key = queryParams[0].toLowerCase();
      // var value = queryParams[1]
      //   .toLowerCase()
      //   .replace(/\b\w/g, function (char) {
      //     return char.toUpperCase();
      //   });

      // // Construct the modified query string
      // var modifiedQueryString = key + "=" + value;
      // navigate(`/recipes/?${modifiedQueryString}`);

      // dispatch(getFilterdRecipeHandler(modifiedQueryString));

      navigate(`/recipes?${queryString}`);
      dispatch(getFilterdRecipeHandler(queryString));
      setIsPop(false);
    } else if (location?.pathname.startsWith("/workout-program")) {
      navigate(`/workout-program?${lowerCaseQueryString}`);
      dispatch(workoutProgramHandler(queryString));
    }
  };

  useEffect(() => {
    const value = assignNumericValues(selectedFilters);
    setTolalFilter(value);
  }, [selectedFilters]);
  useEffect(() => {
    if (!location.search) {
      setTolalFilter(0);
      setSelectedFilters({});
    }
  }, [location.search]);

  const popShow = () => {
    setIsPop(!ispop);
  };

  // Check if any filter is selected
  const isFilterSelected = Object.values(selectedFilters).some(
    (value) => value !== null
  );

  return (
    <>
      <div
        className={`relative flex flex-row rounded-full text-[14px] font-[500] text-[#868A93] py-[10px] px-[14px] items-center focus:bg-[#9C3022] cursor-pointer ${
          ispop || totalFilter > 0
            ? "bg-[#9C3022] text-[white]"
            : "bg-transparent"
        } gap-[2px] focus:text-white`}
        onClick={popShow}
      >
        <div className="text-center">
          <HiFilter
            className={`text-[20px] ${
              ispop || totalFilter > 0 ? "text-[white]" : "text-[#868A93]"
            }  font-500 text-[#868A93] `}
          />
        </div>{" "}
        <p className="cursor-pointer flex items-center ">
          Filters{" "}
          {totalFilter > 0 && (
            <p className="ml-2 rounded-full border flex items-center justify-center border-white text-sm h-6 w-6 text-center">
              {totalFilter}
            </p>
          )}
        </p>
      </div>

      <div>
        {ispop && (
          <div
            className="filter-container "
            style={{
              transition: ispop ? "500px" : "0",
              overflow: "hidden",
              transition: "transform 10s ease-out",
            }}
          >
            <div className="absolute left-0 top-48 z-10 bg-white border border-t border-b flex-[1.5px] w-full py-[20px]">
              <div className="max-w-[1240px] mx-auto flex  lg:flex-row flex-col justify-between flex-wrap md:mt-0 mt-10">
                {mapData?.map((data, index) => (
                  <div
                    key={index}
                    className={`flex flex-col ss:my-0 my-4 ${
                      index !== 0 ? "border-l-2 pl-6" : ""
                    }`}
                  >
                    <h4 className="text-[#303033] text-[14px]">
                      {data?.heading?.replace("_", " ")?.toUpperCase()}
                    </h4>
                    <ul className="list-none mt-2">
                      {data?.items?.map((text, index) => (
                        <li
                          key={index}
                          className={`text-[#303033] text-[16px] leading-[24px] cursor-pointer py-[4px] flex justify-between items-center gap-4`}
                          onClick={() =>
                            toggleFilter(
                              data?.heading,
                              text?.key,
                              data?.allowMultiple
                            )
                          }
                          style={{
                            backgroundColor:
                              selectedFilters[data?.heading] &&
                              (data.allowMultiple
                                ? selectedFilters[data?.heading].includes(
                                    text.key
                                  )
                                : selectedFilters[data?.heading] === text.key)
                                ? "#F9F9FA"
                                : "",
                          }}
                        >
                          {text?.text}
                          {selectedFilters[data?.heading] &&
                            (data?.allowMultiple
                              ? selectedFilters[data?.heading].includes(
                                  text?.key
                                )
                              : selectedFilters[data?.heading] ===
                                text?.key) && (
                              <HiCheck className="ml-1 text-[#9C3022]" />
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>

              <div className="max-w-[1240px] mx-auto flex justify-between">
                <button
                  className={`${
                    isFilterSelected ? "text-[#9C3022]" : "text-gray-400 "
                  } `}
                  onClick={() => {
                    if (isFilterSelected) {
                      setSelectedFilters({});
                      setTolalFilter(0);
                    }
                  }}
                >
                  Clear Filters
                </button>
                <div className="flex">
                  <button
                    className="mr-[16px] text-[#606062] font-500"
                    onClick={popShow}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={applyFilters}
                    className={`font-500 text-white rounded-full px-4 py-2 ${
                      isFilterSelected
                        ? "bg-[#9C3022]"
                        : "bg-gray-400 cursor-not-allowed"
                    }`}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubMenuFilterBtn;

const assignNumericValues = (data) => {
  let sum = 0;

  for (const value of Object.values(data)) {
    if (typeof value === "string") {
      sum += 1; // Add 1 for string values
    } else if (Array.isArray(value)) {
      sum += value.length; // Add array length for array values
    }
  }

  return sum;
};

// import { Fragment, useEffect, useState } from "react";
// import { HiFilter } from "react-icons/hi";
// import { useLocation, useNavigate } from "react-router-dom";
// import { FaCheck } from "react-icons/fa6";
// import { getRecipes, serarchRecipes } from "../../redux/slices/recipe";
// import { getAccessToken } from "../../token";
// import {
//   menuItems,
//   COURSE,
//   CONVENIENCE,
//   PREFERENCE,
//   DIETRY_RESTRICTION,
//   TOTAL_TIME,
// } from "../../utils/recipesConstant";
// import { useDispatch } from "react-redux";

// export default function SubMenuFilterBtn() {
//   const dispatch = useDispatch();
//   const [isSearchOpen, setIsSearchOpen] = useState(false);
//   const [ispop, setIsPop] = useState(false);
//   //navigation
//   //hook
//   //token
//   const token = getAccessToken();
//   const isTokenPresent = !!token; // Assuming token is provided as a prop
//   const location = useLocation();
//   const isSaveRecipePath = location.pathname === "/save-recipe";
//   const navigation = useNavigate();
//   const toggleSearch = () => {
//     setIsSearchOpen(!isSearchOpen);
//   };

//   const [isLgScreen, setIsLgScreen] = useState(false);
//   const [clicked, setClicked] = useState(false);
//   const [search, setSearch] = useState("");
//   const [course, setCourse] = useState({ title: "", value: "" });
//   const [convenience, setconvenience] = useState({ title: "", value: "" });
//   const [preference, setpreference] = useState({ title: "", value: "" });
//   const [dietary, setDietary] = useState({ title: "", value: [] });
//   const [totalTime, setTotalTime] = useState({ title: "", value: "" });
//   const handleItemClick = (categoryKey, value) => {
//     if (categoryKey === "COURSE") {
//       setCourse({
//         ...course,
//         title: categoryKey,
//         value: course.value === value ? "" : value,
//       });
//     }
//     if (categoryKey === "CONVENIENCE") {
//       setconvenience({
//         ...convenience,
//         title: categoryKey,
//         value: convenience.value === value ? "" : value,
//       });
//     }
//     if (categoryKey === "PREFERENCE") {
//       setpreference({
//         ...preference,
//         title: categoryKey,
//         value: preference.value === value ? "" : value,
//       });
//     }
//     if (categoryKey === "TOTAL_TIME") {
//       setTotalTime({
//         ...totalTime,
//         title: categoryKey,
//         value: totalTime.value === value ? "" : value,
//       });
//     }
//     if (categoryKey === "DIETRY_RESTRICTION") {
//       const newValue = dietary.value.includes(value)
//         ? dietary.value.filter((val) => val !== value)
//         : [...dietary.value, value];
//       setDietary({
//         ...dietary,
//         title: categoryKey,
//         value: newValue,
//       });
//     }
//   };
//   const clearFilterHandler = () => {
//     setCourse({
//       ...course,
//       title: "",
//       value: "",
//     });

//     setconvenience({
//       ...convenience,
//       title: "",
//       value: "",
//     });

//     setpreference({
//       ...preference,
//       title: "",
//       value: "",
//     });

//     setTotalTime({
//       ...totalTime,
//       title: "",
//       value: "",
//     });

//     setDietary({
//       ...dietary,
//       title: "",
//       value: [],
//     });
//   };
//   const applyFilterHandler = () => {
//     const dietaryString = dietary?.value?.map((item) => item).join(",");

//     const query = [
//       course.value !== "" && `course=${course.value}`,
//       convenience.value !== "" && `convenience=${convenience.value}`,
//       preference.value !== "" && `preference=${preference.value}`,
//       dietary.value.length !== 0 && `dietary_restriction=${dietaryString}`,
//       totalTime.value !== "" && `duration=${totalTime.value}`,
//     ]
//       .filter(Boolean)
//       .join("&");
//     dispatch(getRecipes(query));
//   };

//   const handleNavigate = () => {
//     setClicked(!clicked);

//     navigation("/save-recipe");
//   };
//   useEffect(() => {
//     // Function to update isLgScreen state based on window width
//     const handleResize = () => {
//       setIsLgScreen(window.innerWidth <= 1024); // Adjust breakpoint as needed
//     };

//     // Initial check on component mount
//     handleResize();

//     // Add event listener for window resize
//     window.addEventListener("resize", handleResize);

//     // Cleanup: remove event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []); // Empty dependency array to run only once on mount

//   const popShow = () => {
//     setIsPop(!ispop);
//   };

//   return (
//     <>
//       <div
//         className={`flex flex-row rounded-full text-[14px] font-[500] text-[#868a93] py-[5px] px-[14px] items-center focus:bg-[#9C3022] ${
//           ispop ? "bg-[#9C3022] text-[white]" : "bg-transparent"
//         } gap-[2px] focus:text-white`}
//       >
//         <div className="text-center">
//           <HiFilter
//             className={`text-[20px] ${
//               ispop ? "text-[white]" : "text-[#868a93]"
//             }  font-500 text-[#868a93] `}
//           />
//         </div>{" "}
//         <span
//           className="cursor-pointer sm:hidden md:block lg:block hidden"
//           onClick={popShow}
//         >
//           Filters
//         </span>
//       </div>

//       <div className="border-b border-solid shadow-md border-rgb-227-228-235">
//         {/* Your content goes here */}
//       </div>
//       <div className="w-full 2xl:w-8/12 mx-auto transition-transform duration-300 ease-out transform-origin-center p-[24px] transform scaleY-100">
//         {ispop && (
//           <>
//             {" "}
//             <div className="flex h-1/4">
//               <div className=" w-3/12 text-[#868A93] md:hidden  lg:grid xl:grid max-w-[1240px]  pr-5 border-r border-gray-300 mx-auto   sm:hidden hidden ">
//                 <div className="pl-[25px] bg-white">
//                   <span
//                     className={`flex gap-[10px] text-center py-3 px-1 text-[12px] font-700 justify-left items-center font-[700] `}
//                   >
//                     COURSES
//                   </span>
//                   {COURSE?.map((item, index) => (
//                     <div
//                       key={index}
//                       className={` cursor-pointer  gap-[10px] rounded-sm p-1.5 text-[16px] font-700 font-normal flex justify-between  items-center ${
//                         course.value === item
//                           ? "bg-slate-100"
//                           : "bg-transparent"
//                       }`}
//                       onClick={() => handleItemClick("COURSE", item)}
//                     >
//                       {item.replace(/_/g, " ")}
//                       {course.value === item && <FaCheck />}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               <div
//                 className="w-3/12 text-[#868A93] md:hidden  lg:grid xl:grid max-w-[1240px] mx-auto
//                pr-5 border-r border-gray-300
//               sm:hidden hidden "
//               >
//                 <div className="pl-[25px] bg-white">
//                   <span
//                     className={`flex gap-[10px] text-center  py-[8px] px-[5px] text-[12px] font-700 justify-left items-center font-[700] `}
//                   >
//                     CONVENIENCE
//                   </span>
//                   {CONVENIENCE?.map((item, index) => (
//                     <div
//                       key={index}
//                       className={`  cursor-pointer   rounded-sm p-1.5 text-[16px] font-700 font-normal flex justify-between  items-center ${
//                         convenience.value === item
//                           ? "bg-slate-100"
//                           : "bg-transparent"
//                       }`}
//                       onClick={() => handleItemClick("CONVENIENCE", item)}
//                     >
//                       {item.replace(/_/g, " ")}
//                       {convenience.value === item && <FaCheck />}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               <div
//                 className="w-3/12 text-[#868A93] md:hidden  lg:grid xl:grid max-w-[1240px] mx-auto  sm:hidden hidden
//               pr-5 border-r border-gray-300"
//               >
//                 <div className="pl-[25px] bg-white">
//                   <span
//                     className={`flex gap-[10px] text-center
//                     py-[8px] px-[5px] text-[12px] font-700 justify-left items-center font-[700] `}
//                   >
//                     PREFERENCE
//                   </span>
//                   {PREFERENCE?.map((item, index) => (
//                     <div
//                       key={index}
//                       className={`  cursor-pointer   rounded-sm p-1.5 text-[16px] font-700 font-normal flex justify-between  items-center ${
//                         preference.value === item
//                           ? "bg-slate-100"
//                           : "bg-transparent"
//                       }`}
//                       onClick={() => handleItemClick("PREFERENCE", item)}
//                     >
//                       {item.replace(/_/g, " ")}
//                       {preference.value === item && <FaCheck />}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//               <div className="w-3/12 text-[#868A93] md:hidden  lg:grid xl:grid max-w-[1240px] mx-auto  sm:hidden hidden    pr-5 border-r border-gray-300">
//                 <div className="pl-[25px] bg-white">
//                   <span
//                     className={`flex gap-[10px] text-center py-[8px] px-[5px] text-[12px] font-700 justify-left items-center font-[700] `}
//                   >
//                     DIETARY RESTRICTION
//                   </span>
//                   {DIETRY_RESTRICTION?.map((item, index) => (
//                     <div
//                       key={index}
//                       className={`  cursor-pointer px-1  rounded-sm my-1 py-2 text-[16px] font-700 font-normal flex justify-between  items-center ${
//                         dietary?.value?.includes(item)
//                           ? "bg-slate-100"
//                           : "bg-transparent"
//                       }`}
//                       onClick={() =>
//                         handleItemClick("DIETRY_RESTRICTION", item)
//                       }
//                     >
//                       {item.replace(/_/g, " ")}
//                       {dietary?.value?.includes(item) && <FaCheck />}
//                     </div>
//                   ))}
//                 </div>
//               </div>{" "}
//               <div className="w-3/12 text-[#868A93] md:hidden  lg:grid xl:grid max-w-[1240px] mx-auto  sm:hidden hidden   pr-5">
//                 <div className="pl-[25px] bg-white">
//                   <span
//                     className={`flex gap-[10px] text-center  py-[8px] px-[5px] text-[12px] font-700 justify-left items-center font-[700] `}
//                   >
//                     TOTAL TIME
//                   </span>
//                   {TOTAL_TIME?.map((item, index) => (
//                     <div
//                       key={index}
//                       className={`  cursor-pointer   rounded-sm py-1 text-[16px] font-700 font-normal flex justify-between  items-center ${
//                         totalTime.value === item
//                           ? "bg-slate-100"
//                           : "bg-transparent"
//                       }`}
//                       onClick={() => handleItemClick("TOTAL_TIME", item)}
//                     >
//                       {item.replace(/_/g, " ")}
//                       {totalTime.value === item && <FaCheck />}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//             <div className="lg:hidden w-[100vw] h-[100vh] ">
//               {/* Show this content when screen size is less than md */}
//               {ispop && (
//                 <div className="absolute top-0 inset-0 overflow-y-auto z-50 bg-black bg-opacity-50 ">
//                   <div className=" bg-white rounded-lg overflow-hidden">
//                     <div className="p-4 w-[100vw]">
//                       {/*
//                       <Accordion data={arrayOfObjects} />
//                     */}
//                       <div className="border-b border-solid shadow-md border-rgb-227-228-235">
//                         {/* Your content goes here */}
//                       </div>
//                       <div className="lg:hidden flex mt-3 sm:flex justify-between font-500  items-center ">
//                         <div className="flex justify-between items-center">
//                           <button className="mt-3 text-[#E75E6E]">
//                             Clear Filters
//                           </button>
//                         </div>
//                         <div className="flex justify-between gap-6 items-center">
//                           <p className="mt-3 text-[#606062] font-500">Cancel</p>

//                           <button className="mt-3 font-500 bg-[#9C3022] rounded-[35px] px-4 py-2 text-[white]">
//                             Apply
//                           </button>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </div>
//             <div className="md:hidden hidden lg:flex justify-between font-500  items-center ">
//               <div className="flex lg:justify-start justify-center  items-center lg:w-2/12 2xl:w-5/12  ">
//                 <button
//                   className={`mt-3 lg:ml-5   ${
//                     course.value === "" &&
//                     convenience.value === "" &&
//                     preference.value === "" &&
//                     dietary.value.length === 0 &&
//                     totalTime.value === ""
//                       ? "text-gray-300 cursor-not-allowed	"
//                       : "text-red-600 cursor-pointer"
//                   }`}
//                   onClick={clearFilterHandler}
//                 >
//                   Clear Filters
//                 </button>
//               </div>
//               <div className="flex justify-between gap-6 items-center ">
//                 <p
//                   className="mt-3 text-[#606062] font-500 cursor-pointer"
//                   onClick={popShow}
//                 >
//                   Cancel
//                 </p>
//                 <button
//                   className={`mt-3 font-500 text-white rounded-full px-4 py-2 ${
//                     course.value === "" &&
//                     convenience.value === "" &&
//                     preference.value === "" &&
//                     dietary.value.length === 0 && // Check if the length is 0
//                     totalTime.value === ""
//                       ? "bg-gray-300 cursor-not-allowed"
//                       : "bg-primary cursor-pointer"
//                   }`}
//                   onClick={applyFilterHandler}
//                 >
//                   Apply
//                 </button>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//       {ispop && (
//         <div className="border-b border-solid shadow-md border-rgb-227-228-235">
//           {/* Your content goes here */}
//         </div>
//       )}
//     </>
//   );
// }
