import React from "react";

const FolderSkeleton = () => {
  return (
    <div class=" rounded-md flex items-center flex-wrap gap-4 p-4 max-w-[1240px] mx-auto">
      {[1, 2, 3, 4, 5, 6,]?.map((item, index) => (
        <div key={index} class="animate-pulse flex border-[1px] ">
          <div class="flex-1 space-y-6 ">
            <div class="h-24 w-56 bg-slate-200 rounded"></div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FolderSkeleton;
