import { Tooltip } from "@mui/material";
import { getAccessToken } from "../../token";
import { useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { CiHeart, CiSearch } from "react-icons/ci";
import { FaHeart } from "react-icons/fa";

const SubMenuSavedRecipe = () => {
  const token = getAccessToken();
  const isTokenPresent = !!token; // Assuming token is provided as a prop
  const location = useLocation();
  const isSaveRecipePath =
    location.pathname === "/save-recipe" ||
    location.pathname === "/favorites-videos" ||
    location.pathname === "/favorite-recipes";
  const [clicked, setClicked] = useState(false);
  const navigation = useNavigate();
  const [isLgScreen, setIsLgScreen] = useState(false);

  const handleNavigate = () => {
    setClicked(!clicked);

    navigation(
      location.pathname.startsWith("/workoutvideos") ||
        location.pathname.startsWith("/favorites-videos")
        ? "/favorites-videos"
        : location.pathname.startsWith("/recipes") ||
          location.pathname.startsWith("/save-recipe")
        ? "/favorite-recipes"
        : "#"
    );
  };
  return (
    <>
      <Tooltip
        className=""
        title={
          !isTokenPresent && (
            <div
              style={{
                background: "#ebf4ff",
                margin: "auto",
                padding: "11px 16px",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <div className=" gap-2">
                <p className="text-[#303033] rounded-full leading-5 text-[14px]">
                  {"Login or Register for an account to save recipes"}
                </p>
              </div>
            </div>
          )
        }
      >
        <div
          className={`mx-3 flex flex-row rounded-full text-[14px] py-[5px] text-[#868a93] px-[14px] items-center gap-[2px] ${
            isSaveRecipePath ? "bg-[#9C3022] text-white" : ""
          }`}
          onClick={isTokenPresent ? handleNavigate : undefined}
          style={{ cursor: isTokenPresent ? "pointer" : "not-allowed" }}
        >
          {!location.pathname.startsWith("/workout-program") &&
            <div className="text-center">
              {!isSaveRecipePath ? (
                <CiHeart className="text-[20px] font-500" />
              ) : (
                <FaHeart color="red" className="text-[16px] font-500" />
              )}
            </div>
          }
         
          {!isLgScreen && (
            <span className="hidden md:block lg:block whitespace-nowrap">
              {location.pathname.startsWith("/workoutvideos") ||
              location.pathname.startsWith("/favorites-videos")
                ? "Favorites"
                : location.pathname.startsWith("/recipes") ||
                  location.pathname.startsWith("/save-recipe") ||
                  location.pathname.startsWith("/favorite-recipe")
                ? " Saved Recipe"
                : ""}{" "}
            </span>
          )}
        </div>
      </Tooltip>
    </>
  );
};

export default SubMenuSavedRecipe;
