import React from "react";
import { Skeleton } from "@mui/material";
const CardSkeleton = ({ variant, width, height, animation }) => {
  return (
    <>
      <div className="grid lg:grid-cols-5 items-center gap-3 flex-wrap md:grid-cols-2 max-w-[1240px] mx-auto ">
        {[1, 2, 3, 4, 5,]?.map((item, index) => (
          <div key={index} class=" shadow rounded-md  max-w-sm w-full mx-auto">
            <div class="animate-pulse flex space-x-4">
              <div class="flex-1 space-y-6 py-1">
                <div class="h-60 bg-slate-200 rounded"></div>
                <div class=""></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default CardSkeleton;
