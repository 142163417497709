
import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { IoMdCloseCircle } from "react-icons/io";
import { FaCcVisa } from 'react-icons/fa6';

// Load your publishable key from Stripe
const stripePromise = loadStripe('pk_test_51PNsJHAwcH3sjAbIM1cXiCWcUABMe8MU3D76UwBAKvQiTRm0NtaySi20WEcrQfHtQu0wEDStwTj9jqqPtbB1g93J00Uz0x823E');

const StripeForm = ({ handlePayment, setOpenPay }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false); // Track payment processing state

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    const cardNumberElement = elements.getElement(CardNumberElement);
    const postalCode = document.getElementById('postal-code').value;

    // Start processing
    setIsProcessing(true);

    try {
      const { token, error } = await stripe.createToken(cardNumberElement, { address_zip: postalCode });
  
      if (error) {
        setError(error.message);
        setIsProcessing(false); // Reset processing on error
      } else if (token) {
        setError('');
        await handlePayment(token, email); // Wait for the payment process to complete
        
        // Clear the form fields
        setEmail(''); // Clear email
        setName(" ");
        elements.getElement(CardNumberElement).clear(); // Clear card number
        elements.getElement(CardExpiryElement).clear(); // Clear expiry date
        elements.getElement(CardCvcElement).clear(); // Clear CVC
        document.getElementById('postal-code').value = ''; // Clear postal code
      }
    } catch (error) {
      setError('Something went wrong. Please try again.');
    } finally {
      // Reset processing state after payment completion or error
      setIsProcessing(false);
    }
  };
  const OPTIONS = {
    showIcon: true,
  };
  
  return (
    <div className="bg-white rounded-lg shadow-lg max-w-md w-full p-8 relative h-[80%] overflow-y-auto">
      <div className='absolute top-[2px] right-[2px]'>
        <button onClick={() => setOpenPay(false)}>
          <IoMdCloseCircle fill='#9c3022' size={30} />
        </button>
      </div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Payment Details</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
        <div>
            <label className="block text-gray-600 mb-2" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              type="text"
              placeholder="Enter your name"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2" htmlFor="email">
              Email
            </label>
            <input
              id="email"
              type="email"
              placeholder="you@example.com"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2" htmlFor="card-number">
              Card number
            </label>
            {/* <div className='w-full p-3 border border-gray-300 rounded-md grid grid-cols-[1fr_8fr] items-center'> */}
            
            <CardNumberElement options={OPTIONS}  className=" focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full p-3 border border-gray-300 rounded-md" />
            {/* </div> */}
          </div>
          <div>
            <label className="block text-gray-600 mb-2" htmlFor="expiry-date">
              Expiry date
            </label>
            <CardExpiryElement className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </div>
          <div>
            <label className="block text-gray-600 mb-2" htmlFor="cvc">
              CVC
            </label>
            <CardCvcElement className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500" />
          </div>
          <div>
            <label className="block text-gray-600 mb-2" htmlFor="postal-code">
              Postal code
            </label>
            <input
              id="postal-code"
              name="postal_code"
              placeholder="90210"
              className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        </div>
        {error && <div className="text-red-500 text-sm">{error}</div>}
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 disabled:bg-gray-400 transition duration-150"
          disabled={!stripe || isProcessing} // Disable button if stripe is not ready or if processing
        >
          {isProcessing ? 'Processing...' : 'Pay now £14.99'}
        </button>
      </form>
    </div>
  );
};

// Wrap the form with Stripe Elements
const StripeCheckoutForm = ({ handlePayment, setOpenPay }) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm handlePayment={handlePayment} setOpenPay={setOpenPay} />
    </Elements>
  );
};

export default StripeCheckoutForm;
