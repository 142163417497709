import React from "react";
import Cards from "../cards";
import NotRecipeFound from "../no-found/index";
import { useDispatch } from "react-redux";
import { getRecipes } from "../../../redux/slices/recipe";
import Card from "../../global/Card";
const CardsContent = ({
  heading,
  description,
  button,
  fav,
  cardArray,
  displayButton,
  check = true,
}) => { 
  const dispatch = useDispatch();
  return (
    <div className="max-w-[1240px] mx-auto px-4 pt-12 ">
      <h1 className="text-[22px] font-semibold text-[#303033]">{heading}</h1>

      <div className="flex justify-between items-center">
        <p className="hidden lg:block md:block sm:hidden text-[10px] lg:text-[14px] md:text-[10px] sm:text-[10px] pr-8 font-normal text-[#303033]">
          {description}
        </p>
        {displayButton && (
          <button
            className="rounded-full text-[10px] lg:text-[14px] md:text-[14px] sm:text-[10px] font-medium border border-1 border-[#303033] hover:bg-[#9c3022] hover:border-transparent hover:text-[#fff] px-4 lg:px-4 md:px-4 sm:px py-2"
            onClick={() => dispatch(getRecipes())}
          >
            {button}
          </button>
        )}
      </div>
      <div className="grid grid-cols-[1fr] lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-[1fr_1fr] sm:grid-cols-[1fr] gap-6  pt-12">
        {/* check  */}
        {check ? (
          cardArray?.map((item, index) => (
            <Card
             key={index}
              favVar={fav?.some((favRecipe) => favRecipe.recipeId === item._id)}
              title={item?.recipe_title}
              dietary_restriction={item?.dietary_restriction}
              image={item?.recipe_Cover_picture}
              id={item?._id} 
              item={item}
             />,
            {/* <Cards
              key={index}
              favVar={fav?.some((favRecipe) => favRecipe.recipeId === item._id)}
              title={item?.recipe_title}
              dietary_restriction={item?.dietary_restriction}
              image={item?.recipe_Cover_picture}
              id={item?._id}
              item={item}
            /> */}
          ))
        ) : cardArray && cardArray.length > 0 ? (
          cardArray.map((item, index) => (
            <Cards 
              key={index}
              favVar={fav?.some((favRecipe) => favRecipe.recipeId === item?._id)}
              title={item?.recipe_title}
              dietary_restriction={item?.dietary_restriction}
              image={item?.recipe_Cover_picture}
              id={item?._id}
              item={item}
            />
          ))
        ) : (
          <div className="w-screen">
            <NotRecipeFound
              title={"No Recipes Found"}
              description={"Sorry, there are currently no Recipes available."}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardsContent;
