import React from "react";
import evaluationbg from "../../assets/images/evaluationbg2.png";
import logo from "../../assets/images/logoblack.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../redux/slices/translationSlice/translationSlice";


const validationSchema = Yup.object({
  language: Yup.string().required("Please select a language."), // Language is required
});
const LanguageForm = () => {
    const dispatch=useDispatch();
    const navigate=useNavigate()
  const initialValues = {
    language: "", // This should match the "name" in your select element
  };
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("🚀 ~ LanguageForm ~ values:", values);
      dispatch(setLanguage(values.language));
      navigate("/health-evaluation")
    },
  });
  return (
    <div className="">
      <div
        className="bg-center flex justify-center items-center bg-no-repeat h-screen bg-cover"
        style={{
          backgroundImage: `url(${evaluationbg})`,
        }}
      >
        <div>
          <div className="bg-[#72727224]  md:py-10 2xl:py-16 md:w-[700px] w-[310px] py-10 lg:py-12 lg:w-[980px] 2xl:max-w-[1060px] rounded-[30px] overflow-visible relative">
            <div className="shadow-2xl shadow-black mx-16 rounded-[30px] py-12">
              <div className="flex items-center justify-center">
                <img src={logo} alt="logo" />
              </div>

              <div className="mt-5 lg:px-12 md:px-14 px-2">
                <form onSubmit={formik.handleSubmit} className="z-50">
                  <h2 className="lg:text-[20px] md:text-2xl text-xl font-semibold mb-4">
                   Language
                  </h2>
                  <div className="bg-white rounded-lg px-3 py-5 md:px-10 md:py-8 lg:px-14 lg:py-10">
                    <div>
                      <h2 className="font-medium lg:text-base 2xl:text-base md:text-base text-[10px] lg:mt-4 md:mt-3 mt-2">
                        Please Select Language
                      </h2>
                      <select
                        
                        name="language"
                        value={formik.values.language}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={`border w-full outline-none h-10 mt-2 ${
                          formik.touched.language && formik.errors.language
                            ? "border-red-500"
                            : ""
                        }`}
                      >
                        <option value="" disabled>
                          Select an option
                        </option>
                        <option value="english">English</option>
                        <option value="french">French</option>
                        <option value="italian">Italian</option>
                      </select>

                      {/* Display validation error message */}
                      {formik.touched.language && formik.errors.language ? (
                        <div className="text-red-500 text-sm mt-1">
                          {formik.errors.language}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex items-center justify-center gap-3 mt-5">
                    <div className="flex items-center justify-center">
                      <button
                        className="bg-[#303033] text-sm rounded-sm text-white py-2 px-10"
                        type="submit"
                      >
                        {" "}
                        Next
                      </button>
                     
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageForm;
