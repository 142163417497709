import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL; // .env file

const axiosInstance = axios.create({ baseURL: API_URL }); // create axios Instance  that will call before and after the api call

axiosInstance.interceptors.request.use(
  //with token instance
  async (config) => {
    let token;
    token = localStorage.getItem("accessToken");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const axiosWithoutToken = axios.create({
  // without token
  baseURL: API_URL,
});
