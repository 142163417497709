// apiConfig.js

const BASE_API_URL = "https://api.mykilo.co.uk"; // Replace with your API base URL

export default BASE_API_URL;

export const GET_RECIPES = (
  course,
  convenience,
  preference,
  dietary_restriction,
  duration
) => {
  return `public/recipes`;
};
