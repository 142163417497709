import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getSearchQuerryWorkoutVideoState: {},
  loading: false,
  error: null,
};

export const getSearchQuerryWorkoutVideoHandler = createAsyncThunk(
  "getSearchQuerryWorkoutVideoHandler",
  async (querry, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/videos/list?${querry}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  } 
);

const getSearcQuerryhWorkoutVides = createSlice({
  name: "getSearcQuerryhWorkoutVides",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSearchQuerryWorkoutVideoHandler.pending, (state) => {
      state.loading = true;
      state.error = null; 
    });

    builder.addCase(
      getSearchQuerryWorkoutVideoHandler.fulfilled,
      (state, action) => {
        state.getSearchQuerryWorkoutVideoState = action.payload;
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      getSearchQuerryWorkoutVideoHandler.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default getSearcQuerryhWorkoutVides.reducer;
