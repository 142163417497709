import React, { useState, useEffect, useRef } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { TbChefHat, TbPhoto } from "react-icons/tb";
import { FaHeart, FaRegCalendarAlt, FaStar } from "react-icons/fa";
import { BsJournalAlbum } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";
import { RiTeamFill } from "react-icons/ri";
import { BsFillPersonFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { accessToken, getAccessToken } from "../../token";
import { IoCartOutline } from "react-icons/io5";
import { CiBellOn } from "react-icons/ci";

import {
  FreeUserRoutes,
  PremiumUserRoutes,
  EliteUserRoutes,
} from "../../Routes/routes-config";
import Profile from "../common/profileDropdown";
import { useDispatch, useSelector } from "react-redux";
import { getprofile } from "../../redux/slices/auth";
import CartDetail from "./CartDetail";
import promotional from "../../assets/images/promotional.jpg";
import PopUpModel from "../common/popUpModel";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = ({ setSidebarOpen }) => {
  const [activeItem, setActiveItem] = useState(null);

  const listItems = [
    {
      icon: <BsFillPersonFill size={20} />,
      text: "My Profile",
      route: "/my-profile",
    },
    {
      icon: <FaRegCalendarAlt size={20} />,
      text: "My Schedule",
      route: "/my-fitness-journey/my-schedule",
    },
    {
      icon: <RiTeamFill size={20} />,
      text: "My Team",
      route: "/my-fitness-journey/my-team",
    },
    {
      icon: <FaStar size={20} />,
      text: "My Achievements",
      route: "/my-fitness-journey/my-achievements",
    },

    {
      icon: <TbPhoto size={20} />,
      text: "My Progress Photos",
      route: "/my-fitness-journey/my-progress-photos",
    },
    {
      icon: <FaHeart size={20} />,
      text: "My Favorite Videos",
      route: "/my-fitness-journey/my-favorite-videos",
    },
    {
      icon: <BsJournalAlbum size={20} />,
      text: "My Journal",
      route: "/my-fitness-journey/my-journal",
    },
    {
      icon: <IoTimeOutline size={20} />,
      text: "My Workout History",
      route: "/my-fitness-journey/workout-history",
    },
    {
      icon: <TbChefHat size={20} />,
      text: "My Saved Recipes",
      route: "/my-fitness-journey/my-saved-recipes",
    },
  ];

  const [activePrimaryItem, setActivePrimaryItem] = useState(null);
  const [activeSecondaryItem, setActiveSecondaryItem] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isJourneyOpen, setIsJourneyOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = location?.pathname;
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    // Define an async function to handle the async operations
    const fetchData = async () => {
      // Call the function to get the access token and await its result
      const token = await getAccessToken();
      // Update the state with the access token
      setAccessToken(token);
      // Dispatch the getprofile action
      dispatch(getprofile());
    };

    fetchData();
  }, [dispatch]); // The empty dependency array ensures that this effect runs only once on component mount
  //
  useEffect(() => {
    const userRole = localStorage.getItem("userRole") || "freemium";
    const currentPath = location.pathname;
    let allowedRoutes;
    switch (userRole) {
      case "eliteium":
        allowedRoutes = EliteUserRoutes;
        break;
      case "premium":
        allowedRoutes = PremiumUserRoutes;
        break;
      default:
        allowedRoutes = FreeUserRoutes;
        break;
    }
    const isPathAllowed = allowedRoutes.some((route) => {
      if (route === currentPath) {
        return true;
      }

      const routePattern = new RegExp(
        `^${route.replace(/:[^\s/]+/g, "([\\w-]+)")}$`
      );
      return routePattern.test(currentPath);
    });
    if (!isPathAllowed) {
      navigate("/pricing");
    }
  }, [pathname]);

  const shouldShowButton = () => {
    return location.pathname.startsWith("/my-fitness-journey");
  };

  const isActivePrimary = (path) => {
    return activePrimaryItem === path || location.pathname.startsWith(path)
      ? "text-[#9c3022] border-b border-b-[#9c3022]"
      : "";
  };

  const isActiveSecondary = (path) => {
    if (location.pathname === path) {
      return "bg-[#9c3022] text-black"; // Apply the background color if the current path matches the given path
    } else {
      return ""; // No background color applied if not on the given path
    }
  };

  const handlePrimaryItemClick = (path) => {
    setActivePrimaryItem(path);
  };

  const handleSecondaryItemClick = (path) => {
    setActiveSecondaryItem(path);
  };
  const handleItemClick = (index) => {
    setActiveItem(index);
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(true);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const closeModal = () => {
    setIsPopupVisible(false);
  };
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setIsPopupVisible(true);
      localStorage.setItem("hasVisited", "true");
    }

    const handleBeforeUnload = () => {
      localStorage.removeItem("hasVisited");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // isPopupVisible
  return (
    <>
      <div className="realtive z-50">
        <PopUpModel isOpen={isPopupVisible} onRequestClose={closeModal}>
          <div className="bg-gray-900 min-h-full w-full relative overflow-hidden p-8">
            {/* Background decorative elements */}
            <div className="absolute inset-0">
              <div className="absolute top-1/4 right-1/4 w-2 h-2 bg-yellow-200 rounded-full animate-ping"></div>
              <div className="absolute top-1/3 left-1/3 w-2 h-2 bg-yellow-200 rounded-full animate-ping delay-300"></div>
              <div className="absolute bottom-1/4 right-1/3 w-2 h-2 bg-yellow-200 rounded-full animate-ping delay-700"></div>
            </div>

            {/* Content container */}
            <div className="max-w-6xl mx-auto flex items-center justify-between">
              {/* Left side content */}
              <div className="w-1/2 space-y-6">
                <div className="space-y-2">
                  <h2 className="text-gray-300 text-lg font-semibold tracking-wider">
                    EXCLUSIVE OFFERS
                  </h2>
                  <div className="space-y-1">
                    <h1 className="text-6xl font-bold">
                      <span className="text-white">BLACK</span>
                      <br />
                      <span className="text-red-600">FRIDAY</span>
                    </h1>
                    <div className="text-5xl font-serif text-white italic">
                      Sale
                    </div>
                  </div>
                </div>

                <p className="text-gray-400 max-w-md">
                  Get up to 15% off this Black Friday with the code FRIDAY15!
                </p>

                {/* Discount tag */}
                <div className="relative">
                  <div className="absolute -top-6 right-12 bg-red-600 rounded-full w-24 h-24 flex items-center justify-center transform rotate-12 animate-pulse">
                    <div className="text-white text-center">
                      <div className="text-sm">UP TO</div>
                      <div className="text-2xl font-bold">15%</div>
                      <div className="text-sm">OFF</div>
                    </div>
                  </div>
                </div>

                <button
                  className="bg-red-600 text-white px-8 py-3 rounded-full hover:bg-red-700 transition-colors cursor-pointer"
                  onClick={() => navigate("/store")}
                >
                  Shop Now
                </button>
              </div>

              {/* Right side gift box animation */}
              <div className="w-1/2 relative">
                <img src={promotional} alt="gift" className="my-20" />
                {/* <div className="relative w-64 h-64 mx-auto animate-bounce-slow">
                  <div className="absolute inset-0 bg-gray-800 rounded-lg shadow-xl">
                    <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-4 h-full bg-red-600"></div>
                    <div className="absolute top-1/2 left-0 transform -translate-y-1/2 w-full h-4 bg-red-600"></div>
                  </div>

                  <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-24 h-24">
                    <div className="w-full h-16 border-8 border-red-600 rounded-full"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </PopUpModel>
      </div>
      <Disclosure as="nav" className="bg-[#f7f7f7] shadow">
        {({ open }) => (
          <>
            <div className="max-w-[1240px] mx-auto ">
              <div className="flex justify-between ">
                {/* {shouldShowButton() && (
                <button
                  type="button"
                  className="px-12 text-gray-700 lg:hidden bg-white"
                  onClick={handleSidebarToggle}
                >
                  <RxHamburgerMenu className="text-[24px] text-[#9c3022]" />
                  <span className="sr-only text-red-600">Open sidebar</span>
                </button>
              )} */}
                <div className="flex space-x-4">
                  <div>
                    <a
                      href="/"
                      className="py-5 lg:px-2  md:px-1 text-[12px] lg:text-[14px] md:text-[14px] sm:text-[12px] text-[#303033] hover:text-[#9c3022]"
                    >
                      <img
                        src={require("../../assets/images/Mykillo-New-Logo.png")}
                        alt="Logo"
                        className="w-[80px] h-[40px] mx-2 my-2"
                      />
                    </a>
                  </div>
                </div>

                <div className="hidden lg:flex pt-6 space-x-1">
                  <Link
                    to={accessToken ? "/my-fitness-journey" : "/login"}
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/my-fitness-journey"
                    )}`}
                    onClick={
                      accessToken
                        ? () => handlePrimaryItemClick("/my-fitness-journey")
                        : () => handlePrimaryItemClick("/login")
                    }
                  >
                    My Fitness Journey
                  </Link>

                  <Link
                    to="/workout-program"
                    className={`py-5 lg:px-2 md:px-1 text-[12px]  hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/workout-program"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/workout-program")}
                  >
                    Workout Program
                  </Link>
                  <Link
                    to="/workoutvideos"
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/workoutvideos"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/workoutvideos")}
                  >
                    Workout Videos
                  </Link>
                  <Link
                    to="/recipes"
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/recipes"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/recipes")}
                  >
                    Recipes
                  </Link>
                  <Link
                    to="/coporate-services"
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/coporate-services"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/coporate-services")}
                  >
                    Corporate Service
                  </Link>

                  <Link
                    to="/about"
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/about"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/about")}
                  >
                    About
                  </Link>
                  <Link
                    to="/pricing"
                    className={`py-5 lg:px-2 md:px-1 text-[12px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                      "/pricing"
                    )}`}
                    onClick={() => handlePrimaryItemClick("/pricing")}
                  >
                    Pricing
                  </Link>
                </div>

                <div className="hidden lg:ml-6 -mr-5 sm:flex sm:items-center">
                  {/* <button
                  type="button"
                  className="relative rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="absolute -inset-1.5" />
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button> */}

                  {!accessToken ? (
                    <div className="hidden lg:flex items-center space-x-3">
                      <NavLink
                        to="/signup"
                        className={`py-2 px-8 font-medium rounded-[35px] bg-transparent text-[#303033] text-[14px] hover:bg-[#9c3022] hover:text-white transition duration-300 ${isActiveSecondary(
                          "/signup"
                        )}`}
                        onClick={() => handleSecondaryItemClick("/signup")}
                      >
                        Sign Up
                      </NavLink>
                      <NavLink
                        to="/login"
                        className={`py-2 px-8 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300 ${isActiveSecondary(
                          "/login"
                        )}`}
                        onClick={() => handleSecondaryItemClick("/login")}
                      >
                        Log In
                      </NavLink>
                      <Link to="/store">
                        <button className="py-2 px-5 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300">
                          Shop Now
                        </button>
                      </Link>
                      <CartDetail />
                    </div>
                  ) : (
                    <div className=" lg:flex md:hidden hidden sm:hidden  gap-4 items-center justify-end">
                      {/* <span className="box-border m-0 p-0 text-[27px] leading-6">
                      <TiMessages />
                    </span> */}

                      <Profile />
                      <Link to="/store">
                        <button className="py-2 px-5 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300">
                          Shop Now
                        </button>
                      </Link>
                      <CartDetail />
                    </div>
                  )}

                  {/* Profile dropdown */}
                </div>
                <div className="mr-2 flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              {/* <div className="border-t border-gray-200 pb-3 pt-4">
              <div className="mt-3 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Your Profiles
                </Disclosure.Button>
                <Link to="/settings/change-password">
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Settings
                  </Disclosure.Button>
                </Link>

                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                >
                  Sign out
                </Disclosure.Button>
              </div>
            </div> */}
              <CartDetail />
              {!accessToken ? (
                <div className=" lg:flex items-center space-x-3">
                  <NavLink
                    to="/signup"
                    className={`py-2 px-8 font-medium rounded-[35px] bg-transparent text-[#303033] text-[14px] hover:bg-[#9c3022] hover:text-white transition duration-300 ${isActiveSecondary(
                      "/signup"
                    )}`}
                    onClick={() => handleSecondaryItemClick("/signup")}
                  >
                    Sign Up
                  </NavLink>
                  <NavLink
                    to="/login"
                    className={`py-2 px-8 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300 ${isActiveSecondary(
                      "/login"
                    )}`}
                    onClick={() => handleSecondaryItemClick("/login")}
                  >
                    Log In
                  </NavLink>
                  <Link to="/store">
                    <button className="py-2 px-5 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300">
                      Shop Now
                    </button>
                  </Link>
                </div>
              ) : (
                <div className=" flex  gap-4 items-center justify-end">
                  {/* <span className="box-border m-0 p-0 text-[27px] leading-6">
                      <TiMessages />
                    </span> */}
                  {/* <span className="box-border m-0 p-0  text-[27px] leading-6">
                      <CiBellOn />
                    </span> */}

                  <Profile />
                  <Link to="/store">
                    <button className="py-2 px-5 font-medium text-white text-[14px] border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033] bg-[#9c3022] rounded-[35px]  transition duration-300">
                      Shop Now
                    </button>
                  </Link>
                </div>
              )}

              <div className="space-y-1 pb-3 pt-2">
                <div>
                  <div className="flex border-t-[1px] pt-5 pl-5  items-center justify-between">
                    <h2 className="text-[14px] text-[#303033]">Menu</h2>
                    <IoMdArrowDropdown onClick={() => setIsOpen(!isOpen)} />
                  </div>

                  <div
                    className={`openclosesection pl-10 mt-7 transition flex items-stretch flex-col  delay-500 duration-800 ${
                      isOpen ? "block" : "hidden"
                    }`}
                  >
                    <a
                      href="/workout-program"
                      className={`py-2 lg:px-2 md:px-1 text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/workout-program"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/workout-program")}
                    >
                      Workout Program
                    </a>
                    <a
                      href="/workoutvideos"
                      className={`py-2 lg:px-2 md:px-1  text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/workoutvideos"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/workoutvideos")}
                    >
                      Workout Videos
                    </a>
                    <a
                      href="/recipes"
                      className={`py-2 lg:px-2 md:px-1  text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/recipes"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/recipes")}
                    >
                      Recipes
                    </a>
                    <a
                      href="/coporate-services"
                      className={`py-2 lg:px-2 md:px-1  text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/coporate-services"
                      )}`}
                      onClick={() =>
                        handlePrimaryItemClick("/coporate-services")
                      }
                    >
                      Corporate Service
                    </a>
                    <a
                      href="/store"
                      className={`py-2 lg:px-2 md:px-1  text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/store"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/store")}
                    >
                      Store
                    </a>
                    <a
                      href="/about"
                      className={`py-2 lg:px-2 md:px-1  text-[14px] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/about"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/about")}
                    >
                      About
                    </a>
                    <Link
                      to="/pricing"
                      className={`py-2 lg:px-2 md:px-1 text-[14px] hover:text-[#9c3022] lg:text-[14px] md:text-[14px] sm:text-[12px] ${isActivePrimary(
                        "/pricing"
                      )}`}
                      onClick={() => handlePrimaryItemClick("/pricing")}
                    >
                      Pricing
                    </Link>
                  </div>
                </div>
                <div className="flex border-t-[1px] pt-5 pl-5  items-center justify-between">
                  <h2 className="text-[14px] text-[#303033] ">
                    My Fitness Journey
                  </h2>
                  <IoMdArrowDropdown
                    onClick={() => setIsJourneyOpen(!isJourneyOpen)}
                  />
                </div>

                <div
                  className={`openclosesection pl-5 mt-7 transition flex items-stretch flex-col  delay-500 duration-800 ${
                    isJourneyOpen ? "block" : "hidden"
                  }`}
                >
                  <div className="">
                    <ul className="listitems mt-4">
                      {listItems.map((item, index) => (
                        <li
                          key={index}
                          className={`flex items-center gap-3 py-2 my-2 mx-3 px-3 ${
                            activeItem === index
                              ? "bg-[#f7f7f7]  "
                              : "hover:bg-[#f7f7f7]"
                          } ${
                            index === 0 || index === 2 ? "border-b-[1px]" : ""
                          }`}
                          onClick={() => handleItemClick(index)}
                        >
                          <Link to={item?.route} className="flex">
                            <span className="mr-2">{item.icon}</span>
                            <span className="text-[14px] text-[#303033] font-medium">
                              {item.text}
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Navbar;
