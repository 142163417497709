import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getChallenge: {},
  loading: false,
  error: null,
};

export const getChallengeApiHandler = createAsyncThunk(
  "getChallengeApiHandler",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/getUserSpecificPrograms`);
      return response?.data?.programs;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);

      return rejectWithValue(error.response.data);
    }
  }
);

const getChallengeSlice = createSlice({
  name: "getChallengeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getChallengeApiHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getChallengeApiHandler.fulfilled, (state, action) => {
      state.getChallenge = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getChallengeApiHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getChallengeSlice.reducer;
