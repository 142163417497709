import { createSlice } from "@reduxjs/toolkit";
import {
  addProgressImage,
  addProgressPhoto,
  deleteProgressPhoto,
  getProgressPhoto,
  updateProgressPhoto,
} from "./profileProgressPhotoActions";

const initialState = {
  addProgessPhoto: null,
  getProgressPhoto: null,
  updateProgressPhoto: null,
  deleteProgressPhoto: null,
  images: null,
  loading: false,
  error: null,
};

const progressPhotoSlice = createSlice({
  name: "progressPhotoSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // adding progress photo
    builder.addCase(addProgressPhoto.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addProgressPhoto.fulfilled, (state, action) => {
      state.loading = false;
      state.addProgessPhoto = action.payload;
    });
    builder.addCase(addProgressPhoto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Getting progress photo
    builder.addCase(getProgressPhoto.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProgressPhoto.fulfilled, (state, action) => {
      state.loading = false;

      state.images = action.payload;
    });
    builder.addCase(getProgressPhoto.rejected, (state, action) => {
      state.loading = false;

      state.error = action.error.message;
    });

    // update progress photo
    builder.addCase(updateProgressPhoto.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProgressPhoto.fulfilled, (state, action) => {
      state.loading = false;

      state.updateProgressPhoto = action.payload;
    });
    builder.addCase(updateProgressPhoto.rejected, (state, action) => {
      state.loading = false;

      state.error = action.payload;
    });

    // delete progress photo
    builder.addCase(deleteProgressPhoto.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteProgressPhoto.fulfilled, (state, action) => {
      state.loading = false;
      state.deleteProgressPhoto = action.payload;

      // Assuming action.payload contains the deleted image's id
      // state.deleteProgressPhoto = state.bodyImages.filter((image) => image.id !== action.meta.arg);
    });
    builder.addCase(deleteProgressPhoto.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default progressPhotoSlice.reducer;
