import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import WorkoutContent from "../../components/workoutvideos-components/workout-content";
import Navbar from "../../components/navbar";
import SubMenu from "../../components/SubMenu";
import NotFound from "../../components/recipes-components/no-found";
import Footer from "../../components/footer";
import {
  getSavedFolders,
  getfavRecipe,
} from "../../redux/recipes/recipeActions";
import CardSkeleton from "../../components/global/CardSkeleton";
import CardsContent from "../../components/recipes-components/cards-content";
import Card from "../../components/global/Card";
import RecipesSekelton from "../../components/recipes-components/recipes-sekelton";
import { recipeFilterData } from "../../utils/filtersData";
import FolderSkeleton from "./FolderSkeleton";

const FavoriteRecipes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isTrue, setIsTrue] = useState(false);
  const { loading, favRecipe } = useSelector((state) => state?.recipe);

  const gettingSavedFolders = useSelector(
    (state) => state?.recipe?.savedFolder?.folders
  );

  const handleCardClick = (recipeId) => {
    // Navigate to the recipe description page
    navigate(`/recipe/${recipeId}`);
  };

  useEffect(() => {
    dispatch(getfavRecipe());
  }, [isTrue]);

  useEffect(() => {
    dispatch(getSavedFolders());
  }, [dispatch]);

  return (
    <>
      <Navbar />
      <SubMenu mapData={recipeFilterData} />
      <div className="min-h-[100vh] max-w-[1240px] mx-auto">
        {loading ? (
          <FolderSkeleton />
        ) : (
          <div className="grid gap-3 lg:grid-cols-5 grid-cols-2 items-center justify-center my-3">
            {gettingSavedFolders?.map((folder, index) => (
              <div className=" " key={index}>
                <Link to={`/favorite-recipes/${folder?.folderName[0]}`}>
                  <div className="flex items-center justify-start relative border p-4 rounded-lg bg-white">
                    <div className="">
                      <img
                        src={require("../../assets/images/avacado.jpg")}
                        alt={folder?.folderName[0]}
                        className="w-16 h-16 object-cover rounded"
                      />
                    </div>
                    <div>
                      <h2 className="text-xl font-semibold ml-4">
                        {folder?.folderName[0]}
                      </h2>
                    </div>
                    <div className="absolute top-0 right-0 m-1 bg-gray-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
                      <span className="text-sm">{folder?.recipeCount}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}

        {loading ? (
          <RecipesSekelton />
        ) : (
          <>
            <div className="grid lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-1fr_1fr grid-cols-1 items-center justify-center gap-6 pt-12">
              {favRecipe?.favoriteRecipes &&
              favRecipe?.favoriteRecipes?.length > 0
                ? favRecipe?.favoriteRecipes?.map((item, index) => (
                    <Card
                      key={index}
                      item={item}
                      recipeTitle={item?.recipe_title}
                      coverPicture={item?.recipe_Cover_picture}
                      loading={loading}
                      id={item?._id}
                      isTrue={isTrue}
                      setIsTrue={setIsTrue}
                      isfav={true}
                      onClick={() => handleCardClick(item._id)}
                    />
                  ))
                : null}
            </div>
            {favRecipe?.favoriteRecipes &&
              favRecipe?.favoriteRecipes?.length === 0 && (
                <div className="my-52">
                  <NotFound
                    title="No Saved Recipe"
                    label="No Saved Recipe Found"
                  />
                </div>
              )}
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default FavoriteRecipes;

// import React, { useEffect, useState } from "react";

// import { useDispatch, useSelector } from "react-redux";
// import WorkoutContent from "../../components/workoutvideos-components/workout-content";
// import Navbar from "../../components/navbar";
// import SubMenu from "../../components/SubMenu";
// import NotFound from "../../components/recipes-components/no-found";
// import Footer from "../../components/footer";
// import {
//   getSavedFolders,
//   getfavRecipe,
// } from "../../redux/recipes/recipeActions";
// import CardSkeleton from "../../components/global/CardSkeleton";
// import CardsContent from "../../components/recipes-components/cards-content";
// import Card from "../../components/global/Card";
// import RecipesSekelton from "../../components/recipes-components/recipes-sekelton";
// import { recipeFilterData } from "../../utils/filtersData";
// import { Link, useNavigate } from "react-router-dom";
// import FolderSkeleton from "./FolderSkeleton";

// const FavoriteRecipes = () => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const [isTrue, setIsTrue] = useState(false);
//   const { loading, favRecipe } = useSelector((state) => {
//     return state?.recipe;
//   });

//   const gettingSavedFolders = useSelector((state) => {
//     return state?.recipe?.savedFolder?.folders;
//   });

//   const handleCardClick = (recipeId) => {
//     // Navigate to the recipe description page
//     navigate(`/recipe/${recipeId}`);
//   };

//   useEffect(() => {
//     dispatch(getfavRecipe());
//   }, [isTrue]);

//   useEffect(() => {
//     dispatch(getSavedFolders());
//   }, [dispatch]);

//   return (
//     <>
//       <Navbar />
//       <SubMenu mapData={recipeFilterData} />
//       <div className="min-h-[100vh] max-w-[1240px] mx-auto">
//         {/* {loading && <FolderSkeleton />} */}
//         {loading ? (
//           <FolderSkeleton />
//         ) : (
//           <div className="grid gap-3 grid-cols-5 my-3">
//             {gettingSavedFolders?.map((folder) => (
//               <div className=" " key={folder?.id}>
//                 <Link to={`/favorite-recipes/${folder}`}>
//                   <div className="flex items-center justify-start relative border p-4 rounded-lg bg-white">
//                     <div className="">
//                       <img
//                         src={require("../../assets/images/avacado.jpg")}
//                         alt={folder?.folderName}
//                         className="w-16 h-16 object-cover rounded"
//                       />
//                     </div>
//                     <div>
//                       <h2 className="text-xl font-semibold ml-4">{folder}</h2>
//                     </div>
//                     <div className="absolute top-0 right-0 m-1 bg-gray-500 text-white rounded-full w-6 h-6 flex items-center justify-center">
//                       <span className="text-sm">
//                         {gettingSavedFolders?.length}
//                       </span>
//                     </div>
//                   </div>
//                 </Link>
//               </div>
//             ))}
//           </div>
//         )}

//         {loading ? (
//           <RecipesSekelton />
//         ):(
//           <>
//             <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-1fr_1fr sm:grid-cols-1fr gap-6 pt-12">
//               {favRecipe?.favoriteRecipes &&
//               favRecipe?.favoriteRecipes?.length > 0
//                 ? favRecipe?.favoriteRecipes?.map((item, index) => (
//                     <Card
//                       key={index}
//                       item={item}
//                       recipeTitle={item?.recipe_title}
//                       coverPicture={item?.recipe_Cover_picture}
//                       loading={loading}
//                       id={item?._id}
//                       isTrue={isTrue}
//                       setIsTrue={setIsTrue}
//                       isfav={true}
//                       onClick={() => handleCardClick(item._id)}
//                     />
//                   ))
//                 : null}
//             </div>
//             {favRecipe?.favoriteRecipes &&
//               favRecipe?.favoriteRecipes?.length === 0 && (
//                 <div className="my-52">
//                   <NotFound
//                     title="No Saved Recipe"
//                     label="No Saved Recipe Found"
//                   />
//                 </div>
//               )}
//           </>
//         )}
//       </div>
//       <div className="">
//         <Footer />
//       </div>
//     </>
//   );
// };

// export default FavoriteRecipes;
