import React from "react";

const RecipeFolderSkeleton = () => {
  return (
    <div class=" rounded-md my-4 w-full mx-auto">
      <div class="animate-pulse flex space-x-4">
        <div class="flex-1 space-y-6 py-1">
          <div class=" bg-slate-200 h-12 rounded"></div>
          <div class=" bg-slate-200 h-12 rounded"></div>

          <div class=" bg-slate-200 h-12 rounded"></div>

          <div class="space-y-3"></div>
        </div>
      </div>
    </div>
  );
};

export default RecipeFolderSkeleton;
