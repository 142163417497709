import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Heart from "../../../assets/images/heart.svg";
import moment from "moment";
import { TooltipComponent } from "../upper-section/index";
import { PiWarningCircleThin } from "react-icons/pi";
import { addFavoriteWorkOutVideo } from "../../../redux/slices/workout-videos-slices/add-workout-videos-favorite";
import { getFavWorkOutVideo } from "../../../redux/slices/workout-videos-slices/get-workout-videos-favorite";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../global/Loader";
import { Fa0 } from "react-icons/fa6";
const WorkoutContent = ({ item, index, isTrue, setIsTrue }) => {
  const dispatch = useDispatch();
  const [checkFav, setCheckFav] = useState(false);
  let location = useLocation();
  const [isFavorited, setIsFavorited] = useState(null);
  const [setFav, SetIsFav] = useState(false);
  const { loading } = useSelector((state) => state?.addFavoriteWorkOutVideo);
  useEffect(() => {
    const storedJsonString = localStorage.getItem("favWorkoutVideo");
    const storedArray = JSON.parse(storedJsonString);
    let Favorited = storedArray?.some(
      (video) => video?.videoId?._id === item?._id
    );
    SetIsFav(false);
    setIsFavorited(Favorited);
  }, [checkFav]);
  // loading

  return (
    <>
      <div className="bg-white shadow-md rounded-xl  flex gap-3 " key={index}>
        {" "}
        <div className=" w-full">
          <div className="flex justify-between mt-2">
            <p className="text-[14px] text-[#868a93]">{item?.text}</p>
            <div className=" flex flex-col h-64 w-full">
              <a href={item?.video_path} target="_blank" className="w-full">
                <img
                  className=" h-40 w-full rounded-xl -mt-1.5 object-contain"
                  src={`https://api.mykilo.co.uk/thumbnail/${item?.thumbnail}`}
                  alt="backimage"
                />
              </a>
              <div className="flex flex-col justify-between px-4 pt-2 pb-3 h-full">
                <a href={item?.video_path} target="_blank" className="w-full">
                  <h1
                    className={`text-base over text-ellipsis break-words font-medium text-[#303033] py-2`}
                  >
                    {item?.video_title}
                  </h1>
                </a>
                <div className=" flex items-center gap-2 text-gray-400 ">
                  <span className="text-sm text-[#868a93]">
                    {item?.video_views} views &#x2022;
                  </span>
                  <span
                    className={`${
                      item?.video_views?.length >= 10 ? "truncate" : ""
                    } text-sm`}
                  >
                    {moment(item?.createdAt).format("MMMM D")}
                  </span>

                  <TooltipComponent firstIndexData={item} />

                  <svg
                    onClick={async () => {
                      SetIsFav(true);
                      const response = await handleFavorite(
                        item?._id,
                        dispatch,
                        isTrue,
                        setIsTrue,
                        setCheckFav,
                        checkFav,
                        SetIsFav
                      );
                    }}
                    className={
                      "h-5 w-5 cursor-pointer" +
                      (isFavorited || setFav ? " fill-red-600" : "")
                    }
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    {svg}
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkoutContent;

const handleFavorite = async (
  id,
  dispatch,
  isTrue,
  setIsTrue,
  setCheckFav,
  checkFav,
  SetIsFav
) => {
  const data = await dispatch(addFavoriteWorkOutVideo(id));
  if (data.payload.success) {
    const response = await dispatch(getFavWorkOutVideo());
    if (response?.payload?.success) {
      setCheckFav(!checkFav);
    }
  } else {
    SetIsFav(false);
  }
  if (data.payload.success && setIsTrue) {
    setIsTrue(!isTrue);
  }
  return data;
};

const svg = (
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
    stroke="#868a93"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);

const CustomTooltip = ({ title, description }) => (
  <div
    style={{ padding: 10, background: "white", borderRadius: "20px" }}
    className=" border-2 "
  >
    <h3 className="text-[#303033] text-[16px]">
      {"You have not yet attemted this workout"}
    </h3>
    <p className="text-[#303033] bg-[#e4e1f4] rounded-full text-[14px] py-2 px-4 mt-4 ">
      {"Body weight workouts"}
    </p>
  </div>
);
