import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getavoriteWorkOutVideo: {},
  loading: false,
  error: null,
};

export const getFavWorkOutVideo = createAsyncThunk(
  "getFavWorkOutVideo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("profile/favorite/getVideos");
      const data = response?.data;
      const jsonString = JSON.stringify(data?.favoriteVideos);

      localStorage.setItem("favWorkoutVideo", jsonString);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getFavoriteWorkOutVideo = createSlice({
  name: "getFavoriteWorkOutVideo",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFavWorkOutVideo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getFavWorkOutVideo.fulfilled, (state, action) => {
      state.getavoriteWorkOutVideo = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getFavWorkOutVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getFavoriteWorkOutVideo.reducer;
