// import ReactStars from "react-rating-stars-component";
import React, { useEffect, useState } from "react";
import StarRating from "../star-rating";
import BASE_API_URL from "../../../apiConfig";
import { UseDispatch, useDispatch } from "react-redux";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import handleResponse from "../../common/toaster";
import { useNavigate } from "react-router-dom";
import { favRecipe, getRecipes } from "../../../redux/slices/recipe";
import { getprofile } from "../../../redux/slices/auth";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import empty from "../../../assets/images/empty-folder.e57de881.svg";
import { CheckIcon } from "@heroicons/react/24/outline";
const Cards = ({
  title,
  image,
  dietary_restriction,
  id,
  favVar,
  average_rating,
  item,
}) => { 
  //func
  //hook use nav and dispatch
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleImageClick = (id, event) => {
    // Check if the click originated from the image, not from other elements inside the container
    if (event.target.tagName.toLowerCase() === "div") {
      // Handle the image click logic here
      navigation(`/recipe-description/${id}`);
    }
  };
  const [open, setOpen] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  useEffect(() => {
    setIsFilled(favVar);
  }, [favVar]);

  const handleHeartClick = async (id, event) => {
    event.preventDefault();

    setIsFilled(!isFilled);
    const response = await dispatch(favRecipe(id));
    handleResponse(response);
    dispatch(getRecipes());
  };
  const [showInput, setShowInput] = useState(false);
  const [folderName, setFolderName] = useState(""); // State variable to store input field value

  const handleInputChange = (event) => {
    setFolderName(event.target.value); // Update the folderName state with the input field value
  };

  const handleSave = () => {
    // Handle the save action here, you can use the folderName state variable
  };
  const [showInitialMessage, setShowInitialMessage] = useState(true);
  const dietaryDisplay = dietary_restriction.map((tag, index) => {
    const cleanedTag = tag.replace(/_/g, " ");
    return (
      <div
        key={index}
        className="group relative cursor-pointer bg-[#d5e6ff] backdrop-blur-sm bg-opacity-50 rounded-full w-6 h-6 hover:w-20 flex items-center justify-center overflow-hidden"
      >
        <span className="text-[12px] font-semibold  transition-all duration-100 ease-in-out group-hover:opacity-0">
          {cleanedTag.charAt(0).toUpperCase()}
        </span>
        <span className="absolute text-[12px] font-semibold opacity-0 transition-all duration-100 ease-in-out group-hover:opacity-100">
          {cleanedTag}
        </span>
      </div>
    );
  });
  return (
    // modal for add recipe to folder
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden  rounded-lg bg-white px-4 pb-4 pt-5 w-[50%] text-left shadow-xl transition-all sm:my-8 sm:p-6">
                  <div>
                    <h2 className="text-[22px] text-[#NaN0000]">
                      Add to Folder
                    </h2>
                    <div className="flex items-center mt-5 gap-4 pb-3 border-b-[1px]">
                      <img
                        src={`https://api.mykilo.co.uk/cover/${item?.recipe_Cover_picture}`}
                        className="w-24 h-24 rounded-full"
                        alt="cover pic"
                      />
                      <h2>{item?.recipe_title}</h2>
                    </div>
                    {showInitialMessage && (
                      <div className="flex flex-col gap-5 items-center justify-center py-6">
                        <img src={empty} alt="" />
                        <p className="text-[16px] text-center text-[#868a93]">
                          Looks like you don't have any folders yet!<br></br>{" "}
                          Create a new folder to get started
                        </p>
                      </div>
                    )}
                    {showInput && (
                      <div className="h-64">
                        <input
                          type="text"
                          placeholder="Folder Name"
                          className="w-full border-[1px] py-2 px-1"
                          value={folderName} // Set the value of the input field to the folderName state variable
                          onChange={handleInputChange} // Handle input field changes
                        />
                      </div>
                    )}

                    <div className="flex justify-between items-center">
                      <button
                        className="border-[1px] text-[14px] text-[#303033] border-[#363639] rounded-full px-4 py-1"
                        onClick={() => {
                          setShowInput(true);
                          setShowInitialMessage(false);
                        }}
                      >
                        + New Folder
                      </button>
                      <div className="flex items-center gap-5">
                        <h4
                          className="text-[14px] text-[#303033] cursor-pointer"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </h4>
                        <button className=" bg-[#C4C4C4] text-[14px] text-white  rounded-full px-4 py-1">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="bg-white  shadow-md rounded-xl pb-10 flex justify-between flex-col  ">
        <div
          style={{
            backgroundImage: `url(https://api.mykilo.co.uk/cover/${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "390px",
            maxWidth: "18.124rem",
            borderRadius: "20px",
            marginBottom: "10px",
          }}
          onClick={(e) => handleImageClick(id, e)}
        >
          <div className="flex justify-between ">
            <div className="star-rating space-x-1 mx-2 my-2 bg-opacity-50 cursor-pointer  bg-white  backdrop-blur-sm rounded-full overflow-hidden w-14 h-8  hover:w-36  transition-all duration-300 ease-linear flex justify-between items-center ">
              <StarRating item={item} />
              {/*
               */} 
            </div>
            <div className="flex">
              <div className=" bg-white mx-2 my-2 backdrop-blur-sm bg-opacity-50 flex justify-center items-center rounded-full w-8 h-8">
                <button type="button" onClick={(e) => handleHeartClick(id, e)}>
                  {isFilled ? <FaHeart color="red" /> : <FaRegHeart />}
                </button>
              </div>
              <div className=" bg-white mx-2 my-2 backdrop-blur-sm bg-opacity-50 flex justify-center items-center rounded-full w-8 h-8">
                <img
                  src={require("../../../assets/images/plus.png")}
                  alt="pancake"
                  className="cursor-pointer"
                  onClick={() => setOpen(true)}
                />
              </div> 
            </div>
          </div>

          <div className="flex flex-col justify-center b h-[85%] gap-3 px-2 py-3">
            <div className="flex flex-col justify-center h-[85%] gap-3 px-2 py-3">
              {/* Display the mapped dietaryDisplay array */}
              {dietaryDisplay}
            </div> 
          </div>
        </div>
        <p className="text-[18px] text-[#303033] text-center font-medium">
          {title.replace(/_/g, " ")}
        </p>
      </div>
    </>
  );
};

export default Cards;
