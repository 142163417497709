const RecipesSekelton = () => {
  return (
    <div className="lg:flex items-center gap-3 flex-wrap md:grid-cols-2 max-w-[1240px] mx-auto ">
      {[1, 2, 3, 4, 5, 6, 7, 8.9,10,11,12]?.map((item, index) => (
        <div
          className="border border-slate-300 lg:ml-0 ml-5 shadow rounded-xl p-2 w-72 my-3"
          key={index}
        >
          <div className="animate-pulse">
            <div className="h-72 rounded-lg bg-slate-200 "></div>
            <div className=" p-2 rounded-md my-2 bg-slate-200 "></div>
            <div className="flex gap-2 items-center">
              <div className=" bg-slate-200 h-5 w-20 rounded-md"></div>{" "}
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>{" "}
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>{" "}
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default RecipesSekelton;
