import React from "react";
import Navbar from "../../navbar";
import { Outlet } from "react-router-dom";
import Footer from "../../footer";
import styles from "../../../styles";

const MainLayout = ({ children }) => {
  return (
    <>
      <div className="min-h-screen bg-[#f7f7f7] flex flex-col"> 
        <Navbar />
        <div className={`${styles.customContainer} flex-1`}>{children}</div>
        <Footer />
      </div>
    </>
  );
};

export default MainLayout;
