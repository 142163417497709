import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import SubMenu from "../../components/SubMenu";
import MainLayout from "../../components/layout/main-layout";
import { recipeFilterData } from "../../utils/filtersData";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  getFolderRecipe,
  getfavRecipe,
} from "../../redux/recipes/recipeActions";
import Card from "../../components/global/Card";
import NotFound from "../../components/recipes-components/no-found";
import RecipesSekelton from "../../components/recipes-components/recipes-sekelton";
import { Link, useNavigate, useParams } from "react-router-dom";

const fetchInnermostRecipe = (data) => {
  return data?.flatMap((folder) =>
    folder?.recipe?.flatMap((recipe) =>
      recipe?.recipes?.map((innerRecipe) => ({
        folderId: folder?._id,
        folderName: folder?.folderName,
        recipeId: recipe?._id,
        recipeTitle: recipe?.recipe_title,
        recipeHeading: innerRecipe?.recipeHeading,
        ingredients: innerRecipe?.ingredients,
        coverPicture: recipe?.recipe_Cover_picture,
      }))
    )
  );
};

const FolderSavedRecipes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { folderName } = useParams();
  const [isTrue, setIsTrue] = useState(false);
  const { loading, favRecipe } = useSelector((state) => state?.recipe);

  const getRecipesByFolderName = useSelector((state) => {
    return state?.recipe?.folderRecipesByName?.savedRecipe;
  });

  const innermostRecipes = getRecipesByFolderName
    ? fetchInnermostRecipe(getRecipesByFolderName)
    : [];

  const handleCardClick = (recipeId) => {
    // Navigate to the recipe description page
    navigate(`/recipe/${recipeId}`);
  };

  useEffect(() => {
    dispatch(getfavRecipe());
  }, [isTrue]);

  useEffect(() => {
    dispatch(getFolderRecipe(folderName));
  }, [dispatch, folderName]);

  return (
    <div>
      <MainLayout>
        {/* <SubMenu mapData={recipeFilterData} /> */}
        <Link to="/favorite-recipes">
          <div className="flex mt-8 items-center gap-4">
            <FaArrowLeftLong />

            <h2 className="text-[15px] font-semibold text-[#303033]">
              Back To Recipe Home
            </h2>
          </div>
        </Link>
        <h2 className="text-[22px] mt-4 font-semibold text-[#303033]">
          {folderName}
        </h2>
        <div className="min-h-[100vh] max-w-[1240px] mx-auto">
          {loading && <RecipesSekelton />}
          {!loading && (
            <>
              <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-1fr_1fr sm:grid-cols-1fr gap-6 ">
                {innermostRecipes && innermostRecipes.length > 0
                  ? innermostRecipes.map((item, index) => (
                      <Card
                        key={index}
                        item={item}
                        recipeTitle={item?.recipeTitle}
                        coverPicture={item?.coverPicture}
                        loading={loading}
                        id={item?.recipeId}
                        isTrue={isTrue}
                        setIsTrue={setIsTrue}
                        isfav={true}
                        onClick={() => handleCardClick(item?.recipeId)}
                      />
                    ))
                  : null}
              </div>
              {innermostRecipes && innermostRecipes?.length === 0 && (
                <div className="my-52">
                  <NotFound
                    title="No Saved Recipe"
                    label="No Saved Recipe Found"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </MainLayout>
    </div>
  );
};

export default FolderSavedRecipes;

// import React, { useEffect, useState } from "react";
// import Navbar from "../../components/navbar";
// import SubMenu from "../../components/SubMenu";
// import MainLayout from "../../components/layout/main-layout";
// import { recipeFilterData } from "../../utils/filtersData";
// import { FaArrowLeftLong } from "react-icons/fa6";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   getFolderRecipe,
//   getfavRecipe,
// } from "../../redux/recipes/recipeActions";
// import Card from "../../components/global/Card";
// import NotFound from "../../components/recipes-components/no-found";
// import RecipesSekelton from "../../components/recipes-components/recipes-sekelton";
// import { Link, useParams } from "react-router-dom";

// const FolderSavedRecipes = () => {
//   const dispatch = useDispatch();
//   const { folderName } = useParams();
//   const [isTrue, setIsTrue] = useState(false);
//   const { loading, favRecipe } = useSelector((state) => {
//     return state?.recipe;
//   });

//   const getRecipesByFolderName = useSelector((state) => {
//     return state?.recipe?.folderRecipesByName?.savedRecipe;
//   });
// // const myRecipe=getRecipesByFolderName.map((items)=> items.recipe)

//   useEffect(() => {
//     dispatch(getfavRecipe());
//   }, [isTrue]);

//   useEffect(() => {
//     dispatch(getFolderRecipe(folderName));
//   }, [dispatch]);
//   return (
//     <div>
//       <MainLayout>
//         <SubMenu mapData={recipeFilterData} />
//         <div className="flex items-center gap-4">
//           <FaArrowLeftLong />
//           <Link to="/favorite-recipes">
//             <h2 className="text-[15px] font-semibold text-[#303033]">
//               Back To Recipe Home
//             </h2>
//           </Link>
//         </div>
//         <h2 className="text-[22px] mt-4 font-semibold text-[#303033]">
//           Favourite
//         </h2>
//         <div className="min-h-[100vh] max-w-[1240px] mx-auto">
//           {loading && <RecipesSekelton />}

//           {!loading && (
//             <>
//               <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr] md:grid-cols-1fr_1fr sm:grid-cols-1fr gap-6 pt-12 px-4">
//                 {getRecipesByFolderName && getRecipesByFolderName.length > 0
//                   ? getRecipesByFolderName?.map((item, index) => (
//                       <Card
//                         key={index}
//                         item={item}
//                         recipeTitle={item?.recipe_title}
//                         coverPicture={item?.recipe_Cover_picture}
//                         loading={loading}
//                         id={item?._id}
//                         isTrue={isTrue}
//                         setIsTrue={setIsTrue}
//                         isfav={true}
//                       />
//                     ))
//                   : null}
//               </div>
//               {getRecipesByFolderName &&
//                 getRecipesByFolderName?.length === 0 && (
//                   <div className="my-52">
//                     <NotFound
//                       title="No Saved Recipe"
//                       label="No Saved Recipe Found"
//                     />
//                   </div>
//                 )}
//             </>
//           )}
//         </div>
//       </MainLayout>
//     </div>
//   );
// };

// export default FolderSavedRecipes;
