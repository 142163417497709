import React, { useState } from 'react';
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import check from "../../assets/images/chocolate-sweet-potato-pancakes-1684296231009-2.png";
import StripeCheckout from "react-stripe-checkout";
import axios from "../../utils/api";
import { FaCheck } from "react-icons/fa6";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import GenerateEmailTemplate from "./emailTemplate";
import CheckoutForm from "./customCheckout";
import StripeCheckoutForm from "./customCheckout";

const stripePromise = loadStripe("pk_test_51PNsJHAwcH3sjAbIM1cXiCWcUABMe8MU3D76UwBAKvQiTRm0NtaySi20WEcrQfHtQu0wEDStwTj9jqqPtbB1g93J00Uz0x823E");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5),
  },
}));
const items = [
  {
    id: 1,
    title: "Eating:",
    description: "Discover healthier eating habits tailored to your needs.",
  },
  {
    id: 2,
    title: "Drinking:",
    description: "Learn tips for better hydration and alcohol consumption.",
  },
  {
    id: 3,
    title: "Sleeping:",
    description: "Find ways to improve your sleep quality.",
  },
  {
    id: 4,
    title: "Exercising:",
    description: "Get personalised exercise recommendations.",
  },
];
export default function PaymentDialog({ open, setOpen, plan, formdata,selectedLanguage }) {
console.log("🚀 ~ PaymentDialog ~ selectedLanguage:", selectedLanguage)

  const navigate = useNavigate();

  

  // function generateEmailTemplate(formdata, logo, graphUrl) {
  //   let htmlContent = `
  //     <div style="font-family: Arial, sans-serif; line-height: 1.6; color: #333;">
  //       <div style="text-align: center; padding: 20px; background-color: #f4f4f4;">
  //         <img src="https://dev.mykilo.co.uk/static/media/Mykillo-New-Logo.20c123373bc6b3b11ef0.png" alt="Company Logo" style="width: 150px; height: auto;"/>
  //       </div>
  //       <div style="padding: 20px;">
  //         <h2 style="color: #9c3022; text-align: center;">Health Evaluation Submission</h2>
  //         <p style="text-align: center; color: #777;">Thank you for completing your health evaluation. Below are your responses:</p>
  //         <table border="1" cellpadding="10" cellspacing="0" style="border-collapse: collapse; width: 100%; margin-top: 20px;">
  //           <thead style="background-color: #9c3022; color: white;">
  //             <tr>
  //               <th style="padding: 10px; text-align: left;">Question</th>
  //               <th style="padding: 10px; text-align: left;">Answer</th>
  //             </tr>
  //           </thead>
  //           <tbody>
  //   `;

  //   formdata.forEach((item) => {
  //     htmlContent += `
  //       <tr style="border-bottom: 1px solid #ddd;">
  //         <td style="padding: 10px;">${item.question}</td>
  //         <td style="padding: 10px;">${item.answer}</td>
  //       </tr>
  //     `;
  //   });

  //   htmlContent += `
  //           </tbody>
  //         </table>
  //         <div style="margin-top: 40px; text-align: center;">
  //           <h3 style="color: #9c3022 ;">Your Health Analytics</h3>
  //           <p style="color: #777;">Here’s a quick look at your health evaluation results:</p>
  //           <img src="${graphUrl}" alt="Health Analytics Graph" style="width: 100%; height: auto; margin-top: 20px;"/>
  //         </div>
  //         <p style="text-align: center; color: #777; margin-top: 30px;">
  //           If you have any questions, feel free to <a href="https://mykilo.co.uk/coporate-services" style="color: #4CAF50;">contact us</a>.
  //         </p>
  //       </div>
  //     </div>
  //   `;

  //   return htmlContent;
  // }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log("Price", plan);
 const [openPay,setOpenPay] = useState(false);
  const makePayment = async (token,email) => {
  
    try {

      const paymentResponse = await axios.post("/public/payment", {
        plan: { price: 14.99, name: "Health Evaluation" }, // price in pence
        token,
        email,
      });
   
      if (paymentResponse.status === 200) {
        console.log("Payment successful, submitting form data...");
        const graphUrl = "https://example.com/graph.png";
         // Replace with the URL of the analytics graph
        // let email=paymentResponse?.data?.charge?.receipt_email;
        const emailHtml = GenerateEmailTemplate(formdata, logo, graphUrl,email,selectedLanguage);

  
        const formSubmitResponse = await axios.post("/public/submit", {
          email: email,
          subject: "Health Evaluation Submission",
          html: emailHtml,
        });
  
        if (formSubmitResponse.status === 200) {
          console.log("Form data submitted successfully.");
          toast.success("Payment successful! Check your email for details.");
          setTimeout(() => {
            navigate("/");
          }, 3000);
        } else {
          console.error("Failed to submit form data:", formSubmitResponse.data);
          toast.error("Failed to submit form data. Please try again.");
        }
      } else {
        toast.error("Payment failed. Please try again.");
      }
    } catch (error) {
    console.log("🚀 ~ makePayment ~ error:", error)
 
       toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div className="flex flex-col rounded-xl">
       
          <div className="bg-primary text-center text-white font-sans lg:text-[20px] text-xl lg:px-14 md:px-14  md:text-3xl font-bold lg:py-5 md:py-3 py-2">
          Our health evaluation will help you
          </div>
          <div className="flex flex-col items-center py-2 md:py-5 lg:py-6">
            <div className="flex gap-x-2">
              <span className="text-primary text-4xl">&#163;</span>
              <b className="font-sans font-bold text-2xl md:text-4xl lg:text-5xl">
                14.99
              </b>
            </div>
            <div className="pt-2 md:pt-4 lg:pt-5">
            <button 
                 onClick={()=>setOpenPay(!openPay)}
                className="bg-primary text-center  md:text-sm text-xs py-1 px-7 md:py-1.5 md:px-14 lg:py-1.5 lg:px-14  text-white rounded-full">
                  Pay Now
                </button>
            {
              openPay &&( <div  className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                <StripeCheckoutForm handlePayment={makePayment} setOpenPay ={setOpenPay}  />  </div>
              )
            }
{/*   
              <StripeCheckout
                
                stripeKey="pk_test_51PNsJHAwcH3sjAbIM1cXiCWcUABMe8MU3D76UwBAKvQiTRm0NtaySi20WEcrQfHtQu0wEDStwTj9jqqPtbB1g93J00Uz0x823E"
                token={makePayment}
                name="Health Evaluation"
                description="Get personalized health tips based on your responses."
                amount={plan * 100}
                currency="GBP"
                label="Get Started"
                panelLabel="Pay now"
                image="https://dev.mykilo.co.uk/static/media/Mykillo-New-Logo.20c123373bc6b3b11ef0.png"
                
                locale="en"
                allowRememberMe
                
              >
                <button 
                 onClick={makePayment}
                className="bg-primary text-center  md:text-sm text-xs py-1 px-7 md:py-1.5 md:px-14 lg:py-1.5 lg:px-14  text-white rounded-full">
                  Get Started
                </button>
              </StripeCheckout> */}
            </div>
          </div>
          <hr className="h-[0.5px] bg-primary border-0" />
          <div className="lg:pt-6 md:pt-4 lg:pl-5 md:pl-5 pl-3 pt-3">
            <p className="text-primary text-base md:text-xl lg:text-xl font-medium">
              Our health evaluation will help you
            </p>
          </div>
          <div className="py-3 md:py-1 lg:py-5">
            {items.map((item) => (
              <>
                <div className="lg:pl-5 md:pl-5 flex items-center gap-x-3 pl-3">
                <FaCheck color="#9c3022"/>
                  <h2 className="text-[12px] lg:text-sm md:text-sm text-primary font-normal">
                    {item.title}
                  </h2>
                </div>
                <div className="lg:pb-2.5 md:pb-1.5 md:pl-5 lg:pl-10 pt-1 pl-3">
                  <p className="text-[10px] lg:text-[12px] md:text-[12px]">
                    {item.description}
                  </p>
                </div>
              </>
            ))}
          </div>
        </div>
      </BootstrapDialog>
    </React.Fragment>
  );
}
