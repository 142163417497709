import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  favoriteWorkOutVideo: {},
  loading: false,
  error: null,
};

export const addFavoriteWorkOutVideo = createAsyncThunk(
  "addFavoriteWorkOutVideo",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post("/admin/favorite/video/", {
        videoId: id,
      });
      const data = response?.data;
      toast.success(`${data?.message}`);

      return data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);

      return rejectWithValue(error.response.data);
    }
  }
);

export const getFavWorkOutVideo = createAsyncThunk(
  "getFavWorkOutVideo",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("profile/favorite/getVideos");

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const favoriteWorkOutVideo = createSlice({
  name: "favoriteWorkOutVideo",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addFavoriteWorkOutVideo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(addFavoriteWorkOutVideo.fulfilled, (state, action) => {
      state.favoriteWorkOutVideo = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(addFavoriteWorkOutVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getFavWorkOutVideo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getFavWorkOutVideo.fulfilled, (state, action) => {
      state.favoriteWorkOutVideo = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getFavWorkOutVideo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default favoriteWorkOutVideo.reducer;
