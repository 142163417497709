// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios from "../../../utils/api";
// import { toast } from "react-toastify";

// // Initial state
// const initialState = {
//   journals: [],
//   loading: false,
//   error: null,
// };

// // Thunk for fetching journal entries
// export const fetchJournals = createAsyncThunk(
//   "journals/fetchJournals",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axios.get("/profile/get/myJournals");

//       return response.data.journals;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// // Thunk for adding a new journal entry
// export const addJournal = createAsyncThunk(
//   "journals/addJournal",
//   async (journalData, { rejectWithValue }) => {
//     try {
//       const response = await axios.post("/profile/add/myJournal", journalData, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`, // assuming token is stored in local storage
//         },
//       });
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
// const journalsSlice = createSlice({
//   name: "journals",
//   initialState,
//   extraReducers: (builder) => {
//     // Fetch journals
//     builder.addCase(fetchJournals.pending, (state) => {
//       state.loading = true;
//       state.error = null;
//     });
//     builder.addCase(fetchJournals.fulfilled, (state, action) => {
//       state.journals = action.payload;
//       state.loading = false;
//       state.error = null;
//     });
//     builder.addCase(fetchJournals.rejected, (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//       toast.error("Failed to fetch journals");
//     });

//     // Add journal
//     builder.addCase(addJournal.pending, (state) => {
//       state.loading = true;
//       state.error = null;
//     });
//     builder.addCase(addJournal.fulfilled, (state, action) => {
//       state.journals.push(action.payload);
//       state.loading = false;
//       state.error = null;
//       toast.success("Journal entry added successfully");
//     });
//     builder.addCase(addJournal.rejected, (state, action) => {
//       state.loading = false;
//       state.error = action.payload;
//       toast.error("Failed to add journal entry");
//     });
//   },
// });

// export default journalsSlice.reducer;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  journals: [],
  loading: false,
  error: null,
};

// Thunk for fetching journal entries
export const fetchJournals = createAsyncThunk(
  "journals/fetchJournals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/profile/get/myJournals");
      return response.data.journals;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for adding a new journal entry
export const addJournal = createAsyncThunk(
  "journals/addJournal",
  async (journalData, { rejectWithValue }) => {
    try {
      const response = await axios.post("/profile/add/myJournal", journalData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // assuming token is stored in local storage
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for updating an existing journal entry
export const updateJournal = createAsyncThunk(
  "journals/updateJournal",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/profile/update/myJournal/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // assuming token is stored in local storage
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const journalsSlice = createSlice({
  name: "journals",
  initialState,
  extraReducers: (builder) => {
    // Fetch journals
    builder.addCase(fetchJournals.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchJournals.fulfilled, (state, action) => {
      state.journals = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(fetchJournals.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Failed to fetch journals");
    });

    // Add journal
    builder.addCase(addJournal.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addJournal.fulfilled, (state, action) => {
      state.journals.push(action.payload);
      state.loading = false;
      state.error = null;
      toast.success("Journal entry added successfully");
    });
    builder.addCase(addJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Failed to add journal entry");
    });

    // Update journal
    builder.addCase(updateJournal.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateJournal.fulfilled, (state, action) => {
      const index = state.journals.findIndex(
        (journal) => journal.id === action.payload.id
      );
      if (index !== -1) {
        state.journals[index] = action.payload;
      }
      state.loading = false;
      state.error = null;
      toast.success("Journal entry updated successfully");
    });
    builder.addCase(updateJournal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      toast.error("Failed to update journal entry");
    });
  },
});

export default journalsSlice.reducer;
