import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import handleResponse from "../../common/toaster";
import { toast } from "react-toastify";
import { Rating } from "../../../redux/recipes/recipeActions";

const StarRating = ({ id, item }) => {
  const [rating, setRating] = useState(() => {
    // Initialize rating from local storage or default to 0
    return parseInt(localStorage.getItem(`rating_${id}`)) || 0;
  });
  const [hoverRating, setHoverRating] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Store rating in local storage whenever it changes
    localStorage.setItem(`rating_${id}`, rating);
  }, [rating, id]);

  const handleClick = async (value) => {
    setRating(value);
    const ratingObj = {
      recipeId: id,
      rating: value,
    };
    try {
      const response = await dispatch(Rating(ratingObj));
      if (response?.payload?.success) {
        handleResponse(response);
      } else {
        toast.error(response?.payload);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleMouseOver = (value) => {
    setHoverRating(value);
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setHoverRating(0);
    setIsHovering(false);
  };

  return (
    <div
      className="flex "
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center gap-3 justify-center cursor-pointer pl-[7px]">
        {[1, 2, 3, 4, 5].map((index) => (
          <Star
            key={index}
            starId={index}
            filled={index <= rating || index <= hoverRating}
            onMouseOver={handleMouseOver}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>

      <p className="pl-2">
        {item?.average_rating && item?.average_rating.toFixed(1)}
      </p>
    </div>
  );
};

const Star = ({ starId, filled, onMouseOver, onClick }) => {
  return (
    <span
      className={`star text-xl ${filled ? "text-yellow-500" : "text-gray-200"}`}
      onMouseOver={() => onMouseOver(starId)}
      onClick={() => onClick(starId)}
    >
      ★
    </span>
  );
};

export default StarRating;

// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import handleResponse from "../../common/toaster";
// import { toast } from "react-toastify";
// import { Rating } from "../../../redux/recipes/recipeActions";

// const StarRating = (item) => {
//   const [rating, setRating] = useState(() => {
//     // Initialize rating from local storage or default to 0
//     return parseInt(localStorage.getItem(`rating_${item.item._id}`)) || 0;
//   });
//   const [hoverRating, setHoverRating] = useState(0);
//   const [isHovering, setIsHovering] = useState(false);
//   const dispatch = useDispatch();

//   const id =  item?.item?._id;

//   useEffect(() => {
//     // Store rating in local storage whenever it changes
//     localStorage.setItem(`rating_${id}`, rating);
//   }, [rating, id]);

//   const handleClick = async (value) => {
//     setRating(value);
//     const ratingObj = {
//       recipeId: id,
//       rating: value,
//     };
//     try {
//       const response = await dispatch(Rating(ratingObj));
//       if (response?.payload?.success) {
//         handleResponse(response);
//       } else {
//         toast.error(response?.payload);
//       }
//     } catch (error) {
//       toast.error(error);
//     }
//   };

//   const handleMouseOver = (value) => {
//     setHoverRating(value);
//     setIsHovering(true);
//   };

//   const handleMouseLeave = () => {
//     setHoverRating(0);
//     setIsHovering(false);
//   };

//   return (
//     <div
//       className="flex items-center justify-center"
//       onMouseOver={() => setIsHovering(true)}
//       onMouseLeave={handleMouseLeave}
//     >
//       <div className="flex space-x-1 cursor-pointer pl-2">
//         {[1, 2, 3, 4, 5].map((index) => (
//           <Star
//             key={index}
//             starId={index}
//             filled={index <= rating || index <= hoverRating}
//             onMouseOver={handleMouseOver}
//             onClick={handleClick}
//           />
//         ))}
//       </div>

//       <p className="pl-2">
//         {item?.item?.average_rating && item?.item?.average_rating.toFixed(1)}
//       </p>
//     </div>
//   );
// };

// const Star = ({ starId, filled, onMouseOver, onClick }) => {
//   return (
//     <span
//       className={`star text-xl ${
//         filled ? "text-yellow-500" : "text-gray-200"
//       }`}
//       onMouseOver={() => onMouseOver(starId)}
//       onClick={() => onClick(starId)}
//     >
//       ★
//     </span>
//   );
// };

// export default StarRating;
