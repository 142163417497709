import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  addChallenge: {},
  loading: false,
  error: null,
};

export const addChallengeApiHandler = createAsyncThunk(
  "addChallengeApiHandler",
  async (data, { rejectWithValue }) => {
    const { id, formData } = data;

    try {
      const response = await axios.post(
        `/admin/UserSpecificPrograms/${id}`,
        formData
      );

      toast.success(`${response?.data?.message}`);
      return response?.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);

      return rejectWithValue(error.response.data);
    }
  }
);

const addChallengeSlice = createSlice({
  name: "addChallengeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(addChallengeApiHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(addChallengeApiHandler.fulfilled, (state, action) => {
      state.addChallenge = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(addChallengeApiHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default addChallengeSlice.reducer;
