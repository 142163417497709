import { Typography, Box } from "@mui/material";
import ButtonComp from "../../global/ButtonComp";
import { Link } from "react-router-dom";
import { getprofile } from "../../../redux/slices/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
const NotFound = ({ title, description, label, link, show }) => {
  const dispatch=useDispatch();
  const profile = useSelector((state) => state?.auth?.profile?.content?.avatar);

// const dispatch = useDispatch();
useEffect(() => {
  dispatch(getprofile());
}, [dispatch]);
  return (
    <div
      className="max-w-[1240px] mx-auto flex flex-col items-center justify-center w-full 
    "
    >
      <Box>
        <img src={`https://api.mykilo.co.uk/avatar/${profile}`} alt="" className="w-56" />
      </Box>

      <Box textAlign="center">
        {title && (
          <Typography variant="h5" mt={2}>
            {title}
          </Typography>
        )}
        <Typography variant="body1" mt={1}>
          {description}
        </Typography>
        {label && (
          <div className="mt-3">
            <Link to={link}>
              <ButtonComp label={"Back to Recipes"} />
            </Link>
          </div>
        )}
      </Box>
    </div>
  );
};

export default NotFound;
