import { Fragment, useEffect, useRef, useState } from "react";
import _ from "lodash";

import { CiHeart, CiSearch } from "react-icons/ci";
import { useDispatch } from "react-redux";
// import { getRecipes, serarchRecipes } from "../../redux/slices/recipe";
import { useLocation, useNavigate } from "react-router-dom";
import { getSearchWorkoutVideoHandler } from "../../redux/slices/workout-videos-slices/search-workout-video";
import { getRecipes, serarchRecipes } from "../../redux/recipes/recipeActions";
//workout program
import { getSearchWorkoutProgramHandler } from "../../redux/slices/workout-program-slice/search-workout-program";

const SubMenuSearchBar = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  let location = useLocation();
  const navigation = useNavigate();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const node = useRef(null);
  const handleClickOutside = (event) => {
    if (
      node.current &&
      !node.current.contains(event.target) &&
      search.trim()?.length == 0
    ) {
      setIsSearchOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [node, search]);

  const emptySearchHandler = () => {
    if (
      location.pathname.startsWith("/workoutvideos") ||
      location.pathname.startsWith("/favorites-videos")
    ) {
      dispatch(getSearchWorkoutVideoHandler.fulfilled({}));
      navigation(`/workoutvideos`);
    } else if (location.pathname.startsWith("/workout-program")) {
      dispatch(getSearchWorkoutProgramHandler.fulfilled({}));
      navigation(`/workout-program`);
    }
  }; 

  const searchHandler = async () => {
    const querrySearch = search.trim().replace(/\s+/g, "_");
    const querry = `name=${querrySearch}`;

    if (location.pathname === "/recipes" || location.pathname === "/favorite-recipes") {
      const response = await dispatch(serarchRecipes(querry));
      if (response.payload.success) {
        navigation(`/recipes?${querry}`);
      }
    } else if (
      location.pathname.startsWith("/workoutvideos") ||
      location.pathname.startsWith("/favorites-videos")
    ) {
      const response = await dispatch(getSearchWorkoutVideoHandler(querry));
      if (response.payload.success) {
        navigation(`/workoutvideos?${querry}`);
      }
    } else if (location.pathname.startsWith("/workout-program")) {
      const response = await dispatch(getSearchWorkoutProgramHandler(querry));
      if (response.payload.success) {
        navigation(`/workout-program?${querry}`);
      }
    }
  };

  const debouncedSearch = _.debounce((term) => {
    term?.length > 2 && searchHandler(term);
    (term?.length == 0 || null) && emptySearchHandler();
  }, 500);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearch(value);
    debouncedSearch(value);
  };

  return (
    <div
      ref={node}
      style={{
        transition: "width 0.3s ease-in-out",
        // width: isSearchOpen ? "18.7rem" : "75px",
      }}
      className={`flex cursor-pointer flex-row rounded-full items-center gap-[2px] ${isSearchOpen ? "lg:w-[18rem] w-[12rem]" : "w-[75px]"}  ${
        isSearchOpen
          ? "border-2 bg-white sm:max- mx- md:max-w-[100%] max-w-[80%] py-[5px] px-[5px]"
          : "px-[5px] py-2"
      }`}
      onMouseEnter={() => setIsSearchOpen(true)}
    >
      <div className="flex items-center gap-2">
        <div onClick={toggleSearch} className="text-center cursor-pointer">
          <CiSearch className="text-[20px] font-500 text-[#868a93]" />
        </div>
        {!isSearchOpen && (
          <span className="sm:hidden cursor-pointer text-[#868a93] text-[14px] md:block lg:block hidden">
            Search
          </span>
        )}
        {isSearchOpen && (
          <input
            type="text"
            placeholder="Search.."
            className="focus:outline-none outline-none bg-transparent border-none transition-all duration-300 ease-in-out"
            value={search}
            onChange={handleInputChange}
          />
        )}
      </div>
    </div>
  );
};

export default SubMenuSearchBar;

/*
 */
