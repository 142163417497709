import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../../utils/api";

// Async thunk action to fetch products with filters
export const getProducts = createAsyncThunk(
  "products/getProducts",
  async ({ filters, page, limit, sort }, { rejectWithValue }) => {
    try {
      // Construct query parameters based on filters, page, limit, and sort
      let queryParams = `?page=${page}&limit=${limit}`;

      // Handle filters
      if (filters) {
        Object.keys(filters).forEach((filterKey) => {
          if (filters[filterKey]) {
            queryParams += `&${filterKey}=${filters[filterKey]}`;
          }
        });
      }

      // Handle sort
      if (sort) {
        queryParams += `&sort=${sort}`;
      }

      const response = await axios.get(`/admin/get/products${queryParams}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk action to fetch a product by ID
export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/admin/get/product/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Define initial state for products slice
const initialState = {
  products: [],
  totalProducts: 0,
  product: null,
  loading: false,
  error: null,
  status: 'idle',
};

// Create products slice with reducers and extraReducers
const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    // Add synchronous reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      // Handle getProducts
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading'; 
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.products = action.payload.data;
        state.totalProducts = action.payload.totalProducts;
        state.loading = false;
        state.error = null;
        state.status = 'succeeded'; 
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'failed';
      })
      // Handle getProductById
      .addCase(getProductById.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.status = 'loading';
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.product = action.payload.data;
        state.loading = false;
        state.error = null;
        state.status = 'succeeded'; 
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.status = 'failed';
      });

   
  },
});

export default productsSlice.reducer;
