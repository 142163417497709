import React, { useState, useEffect } from "react";
import Card from "../../global/Card";

const RecipeFilters = () => {
  const [recipes, setRecipes] = useState([]);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch("/public/recipes?course=Appetizers");
        if (!response.ok) {
          throw new Error("Failed to fetch recipes");
        }
        const data = await response.json();
        setRecipes(data.data.recipies);
      } catch (error) {}
    };

    fetchRecipes();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="bg-[#f7f7f7] w-[80%] rounded-lg p-[8px] flex justify-between items-center">
          <div>Showing {recipes.length} Recipes matching</div>
          <div className="text-red-500">Remove All Filters</div>
        </div>
        <div>Sort By</div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        {recipes.map((recipe) => (
          <Card
            key={recipe?._id}
            image={recipe?.recipe_Cover_picture}
            title={recipe?.recipe_title}
          />
        ))}
      </div>
    </>
  );
};

export default RecipeFilters;
