import { useLocation } from "react-router-dom";
import styles from "../../styles";
import { recipeFilterData } from "../../utils/filtersData";
import BrowseByCollectionBtn from "./BrowseByCollectionBtn";
import SubMenuFilterBtn from "./SubMenuFilterBtn";
import SubMenuSavedRecipe from "./SubMenuSavedRecipe";
import SubMenuSearchBar from "./SubMenuSearchBar";

export default function SubMenu({ mapData }) {
  const location = useLocation();
  return (
    <>
      {/* <div className="border-b-[1px]"> */}

      <div
        className={`${styles.customContainer} lg:${styles.flexBetween} pt-[20px] xl:min-w-[1240px] md:min-w-[767px]`}
      >
        <div>
          <BrowseByCollectionBtn />
        </div>
        <div className={`${styles.flexCenter}`}>
          <SubMenuSearchBar />
          <div className="mx-2">
            {!location?.pathname.startsWith("/workout-program") && (
              <SubMenuSavedRecipe />
            )}
          </div>

          <SubMenuFilterBtn mapData={mapData} />
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
