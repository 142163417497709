import { createAsyncThunk } from "@reduxjs/toolkit";
import axios, { axiosWithoutToken } from "../../utils/api";
import { toast } from "react-toastify";
import { createSlice } from "@reduxjs/toolkit";
import { getAccessToken } from "../../token";

const initialState = {
  users: [],
  loading: false,
  error: null,
  profile: {},
  userAuth: {
    userInfo: sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null,
    loading: false,
    error: null,
  },
};
let token = getAccessToken();

// To Register New User
export const Register = createAsyncThunk(
  "Register",
  async (Values, { rejectWithValue }) => {
    try {
      const response = await axios.post(`auth/register`, Values);
      localStorage.setItem("accessToken", response?.data?.content?.accessToken);
      toast.success(`${response?.data?.message}`);
      return response.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);
      return rejectWithValue(error);
    }
  }
);

// To Login
export const Login = createAsyncThunk(
  "Login",
  async (Values, { rejectWithValue }) => {
    try {
      const response = await axiosWithoutToken.post(`/auth/login`, Values);
      localStorage.setItem("accessToken", response?.data?.content?.accessToken);
      toast.success(`${response?.data?.message}`);

      return response.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);
      return rejectWithValue(error);
    }
  }
);

export const getprofile = createAsyncThunk(
  "getprofile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/profile/`);

      localStorage.setItem(
        "userRole",
        response?.data?.content?.class_type || "freemium"
      );
      localStorage.setItem("gender", response?.data?.content?.gender || "Male");

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// LogOut
export const Logout = createAsyncThunk("Logout", async () => {
  localStorage.clear();
  return null;
});
// To Update
export const update = createAsyncThunk(
  "update",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/profile/update`, values);
      // const response = await axios.put(`/profile/update`, values, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });

      const data = await response?.data;
      toast.success(`${data?.message}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.resoi);
    }
  }
);
// To Upload Profile photo
export const updatePhoto = createAsyncThunk(
  "updatePhoto",
  async (values, { rejectWithValue }) => {
    try {
      // const response = await axios.put(`/profile/update`, values);
      const response = await axios.put(`/profile/profile_picture`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const data = await response?.data;
      toast.success(`${data?.message}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.resoi);
    }
  }
);
// To Delete Profile photo
export const deletePhoto = createAsyncThunk(
  "deletePhoto",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/profile/delete_profile_pic`);

      const data = await response?.data;
      toast.success(`${data?.message}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.resoi);
    }
  }
);

// To Delete
export const deleteAccount = createAsyncThunk(
  "deleteAccount",
  async (values, { rejectWithValue }) => {
    try {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      const token = userInfo.content.accessToken;

      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      //   data: values,
      // };
      const response = await axios.delete(`/profile/delete`, values);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.error);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/profile/update/updatePassword`,
        values
      );
      const data = await response?.data;
      toast.success(`${data?.message}`);
      return data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);

      return rejectWithValue(error.response.data);
    }
  }
);

export const selectProfile = (state) =>
  state.auth.profile.content?.favoriteRecipe;

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(Register.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Register.fulfilled, (state, action) => {
      state.userAuth.userInfo = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Register.rejected, (state, action) => {
      state.loading = false;
      state.userAuth.error = action.payload;
    });
    builder.addCase(Login.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Login.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.userAuth.userInfo = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(Login.rejected, (state, action) => {
      state.loading = false;
      state.userAuth.error = action.payload;
    });
    builder.addCase(Logout.fulfilled, (state, action) => {
      state.profile = null;
      state.loading = false;
    });
    builder.addCase(getprofile.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getprofile.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getprofile.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
    builder.addCase(update.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(update.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
    builder.addCase(updatePhoto.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePhoto.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updatePhoto.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
    builder.addCase(deletePhoto.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deletePhoto.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deletePhoto.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
    builder.addCase(deleteAccount.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.profile = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.profile.error = action.payload;
      state.loading = false;
    });
  },
});
const authReducer = authSlice.reducer;
export default authReducer;
