import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getFitnessSubscriptionStatus: {},
  loading: false,
  error: null,
};

export const getFitnessSubscriptionStatusHandler = createAsyncThunk(
  "getFitnessSubscriptionStatusHandler",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/fitness/subscriptionStatus`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getFitnessSubscriptionSlice = createSlice({
  name: "getFitnessSubscriptionSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFitnessSubscriptionStatusHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(
      getFitnessSubscriptionStatusHandler.fulfilled,
      (state, action) => {
        state.getUpgradePlan = action.payload;
        state.loading = false;
        state.error = null;
      }
    );

    builder.addCase(
      getFitnessSubscriptionStatusHandler.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      }
    );
  },
});

export default getFitnessSubscriptionSlice.reducer;
