import { combineReducers } from "redux";
//slices
import authReducer from "./slices/auth";
// import workOutVideo from "./slices/wortout-video";
import recipeSlices from "./recipes/recipeSlices";
import progressPhotoSlice from "./profile-progress-photo/profileProgressPhotoSlice";
// upgrade plan
import getUpgradePlanSlice from "./slices/upgrade-plan/get-upgrade-plan";
import payUpgradePlanSlice from "./slices/upgrade-plan/pay-upgrade-plan";
import languageSlice from "./slices/translationSlice/translationSlice";
import recipeReducer from "./slices/recipe";
// workout video
import workOutVideo from "./slices/workout-videos-slices/wortout-video";
import addFavoriteWorkOutVideo from "./slices/workout-videos-slices/add-workout-videos-favorite";
import getFavoriteWorkOutVideo from "./slices/workout-videos-slices/get-workout-videos-favorite";
import getSearchWorkoutVides from "./slices/workout-videos-slices/search-workout-video";
import getSearcQuerryhWorkoutVides from "./slices/workout-videos-slices/get-workoutvideo-urlQuerry";
// ----------------------------------------------------------------------
//workout program slice
import getWorkOutProgram from "./slices/workout-program-slice/workout-program";
import getSearchWorkoutProgram from "./slices/workout-program-slice/search-workout-program";
import workoutProgramFilterSlice from "./slices/workout-program-slice/get-workout-program-filterQuerry";
import workoutProgramChallengeSlice from "./slices/workout-program-slice/get-workout-program-challenge";
//
import teamSlice from "./team/teamSlice";
import journalsReducer from "./slices/my-journals/my-journal-slice";
//
import reviewsSlice from "../redux/slices/product-review/productReviewSlice";
import productsSlice from "../redux/slices/products/product";
import productCheckoutSlice from "./slices/products/productCheckoutSlice";
import getFitnessSubscriptionSlice from "./slices/upgrade-plan/get-fitness-subscription-status";

// challenges
import addChallengeSlice from "./slices/workout-challenge/add-challenge";
import deleteChallengeSlice from "./slices/workout-challenge/delete-challenge";
import getChallengeSlice from "./slices/workout-challenge/get-challenge";

//achievements


import achievementsReducer from './slices/my-achievements/my-achievements'
import cartSlice from "./slices/cart/cartSlice";
const rootReducer = combineReducers({
  auth: authReducer,
  recipe: recipeSlices,
  // Progress Photo
  progressPhoto: progressPhotoSlice,
  // Team
  team: teamSlice,
  //products:
  products: productsSlice,
  productCheckout: productCheckoutSlice,
  //cart
  allCart: cartSlice,
  // workout videos slices
  workOutVideo: workOutVideo,
  addFavoriteWorkOutVideo: addFavoriteWorkOutVideo,
  getFavoriteWorkOutVideo: getFavoriteWorkOutVideo,
  getSearchWorkoutVides: getSearchWorkoutVides,
  getSearcQuerryhWorkoutVides: getSearcQuerryhWorkoutVides,
  // workout program slices
  getWorkOutProgram: getWorkOutProgram,
  getSearchWorkoutProgram: getSearchWorkoutProgram,
  workoutProgramFilterSlice: workoutProgramFilterSlice,
  workoutProgramChallengeSlice: workoutProgramChallengeSlice,

  // upgrade plan
  getUpgradePlanSlice: getUpgradePlanSlice,
  payUpgradePlanSlice: payUpgradePlanSlice,
  getFitnessSubscriptionSlice: getFitnessSubscriptionSlice,

  ///////////////////////////////// review slice////////////////////////////////////

  reviewsSlice: reviewsSlice,
  ///////////////////////////////// journal slice////////////////////////////////////

  journals: journalsReducer,
  // challenge
  addChallengeSlice: addChallengeSlice,
  deleteChallengeSlice: deleteChallengeSlice,
  getChallengeSlice: getChallengeSlice,

  //achivements
  achievements: achievementsReducer,
  languageSlice:languageSlice,

});

export default rootReducer;
