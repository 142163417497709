import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  payUpgradePlan: {},
  loading: false,
  error: null,
};

export const payUpgradePlanHandler = createAsyncThunk(
  "payUpgradePlanHandler",
  async (argurments, { rejectWithValue }) => {
    const { data, setPaidLoading } = argurments;
    try {
      const response = await axios.post(`/fitness/initiate`, data);
      const url = response?.data?.url;
      window.location.href = url;
      setPaidLoading(null);
      return response?.data;
    } catch (error) {
      toast.error(`${error?.response?.data?.error}`);
      setPaidLoading(null);
      return rejectWithValue(error.response.data);
    }
  }
);

const payUpgradePlanSlice = createSlice({
  name: "payUpgradePlanSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(payUpgradePlanHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(payUpgradePlanHandler.fulfilled, (state, action) => {
      state.payUpgradePlan = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(payUpgradePlanHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default payUpgradePlanSlice.reducer;
