import React, { useEffect, useState } from "react";
import WorkoutContent from "../workout-content";
import { Link } from "react-router-dom";
import { CiHeart } from "react-icons/ci";
import { PiWarningCircleThin } from "react-icons/pi";
import { Tooltip } from "@mui/material";
import LatestWorkOutSekelton from "../../../components/workoutvideos-components/workout-Sekelton/lastestWorkoutSekelton";
import moment from "moment";
import { useDispatch } from "react-redux";
import { addFavoriteWorkOutVideo } from "../../../redux/slices/workout-videos-slices/add-workout-videos-favorite";
import { getFavWorkOutVideo } from "../../../redux/slices/workout-videos-slices/get-workout-videos-favorite";

const UpperSection = ({ heroArray, heading, button, link }) => {
  const [loading, setIsLoading] = useState(true);
  const [isShowMore, setIsShowMore] = useState("Load More latest workouts");
  let firstIndexData = null;
  firstIndexData = heroArray?.items[0];
  const viewSting = "561 K views ";
  const dispatch = useDispatch();
  const [checkFav, setCheckFav] = useState(false);
  const [isFavorited, setIsFavorited] = useState(null);
  const [setFav, SetIsFav] = useState(false);
  useEffect(() => {
    const storedJsonString = localStorage.getItem("favWorkoutVideo");
    const storedArray = JSON.parse(storedJsonString);
    let Favorited = storedArray?.some(
      (video) => video?.videoId?._id === firstIndexData?._id
    );
    SetIsFav(false);
    setIsFavorited(Favorited);
  }, [checkFav]);

  return (
    <>
      <div className="max-w-[1240px] mx-auto px-2 pt-12">
        <div className="flex justify-between items-center">
          <h1 className="text-[22px] font-semibold text-[#303033]">
            {heading}
          </h1>
          <Link to={link}>
            <button className="rounded-full text-[14px] font-medium  border border-[rgb(48,48,51)] hover:border-[#9c3022] hover:bg-[#9c3022] hover:text-[#ffff] px-4 py-2">
              {button}
            </button>
          </Link>
        </div>
        <div>
          <div className="">
            <div className="grid grid-cols-[1fr]  h-auto lg:grid-cols-[1.40fr_1fr] gap-10 mt-4 ">
              {firstIndexData ? (
                <div className="bg-white shadow-md rounded-xl py-9 px-5 flex gap-4 ">
                  <div className="flex lg:flex-row flex-col gap-6">
                    <div className="w-1/2">
                      <a target="_blank" href={firstIndexData?.video_path}>
                        <img
                          className=" h-40 w-full rounded-xl -mt-1.5 object-contain "
                          src={`https://api.mykilo.co.uk/thumbnail/${firstIndexData?.thumbnail}`}
                          alt="backimage"
                        />
                      </a>
                    </div>
                    <div>
                      <h1 className="text-[18x] pb-3 font-normal text-[#303033]">
                        {firstIndexData?.video_title}
                      </h1>
                      <div className="flex items-center gap-4 flex-wrap">
                        {firstIndexData?.focus_type?.map((focusType, index) => (
                          <span
                            className="text-xs font-thin rounded-2xl bg-gray-100 px-2 py-1"
                            key={index}
                          >
                            {focusType}
                          </span>
                        ))}
                      </div>
                      <div className="flex items-center justify-between md:h-full">
                        <p className="text-[14px] text-[#868a93]">
                          <span className="text-sm">
                            {firstIndexData?.video_views} Views .{" "}
                          </span>
                          <span
                            className={`${
                              firstIndexData?.video_views?.length >= 10
                                ? "truncate"
                                : ""
                            } text-sm`}
                          >
                            {moment(firstIndexData?.createdAt).format("MMMM D")}
                          </span>
                        </p>
                        <div className="flex">
                          <TooltipComponent firstIndexData={firstIndexData} />

                          <svg
                            onClick={() => {
                              SetIsFav(true);
                              handleFavorite(
                                firstIndexData?._id,
                                dispatch,
                                setCheckFav,
                                checkFav
                              );
                            }}
                            className={
                              "h-5 w-5 cursor-pointer" +
                              (isFavorited || setFav ? " fill-red-600" : "")
                            }
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            {svg}
                          </svg>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              ) : (
                <LatestWorkOutSekelton />
              )}
              <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr] lg:gap-4 md:grid-cols-1fr md:grid-cols-1fr md:gap-10 sm:grid-cols-1fr gap-3">
                
                {heroArray?.items?.slice(1, 3)?.map((item, index) => (
                  <WorkoutContent key={index} item={item} index={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        {isShowMore === "View More" && (
          <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr_1fr] lg:gap-4 md:grid-cols-1fr md:grid-cols-1fr md:gap-10 sm:grid-cols-1fr sm:gap-10 mt-10">
            {heroArray?.items?.slice(3, 8)?.map((item, index) => (
              <WorkoutContent key={index} item={item} index={index} />
            ))}
          </div>
        )}
      </div>

      <div
        className="max-w-[1240px] mx-auto px-2"
        onClick={() => setIsShowMore("View More")}
      >
        <div className="bg-white shadow-md rounded-xl py-5 px-5 flex justify-center items-center gap-4 mt-6 cursor-pointer">
          <h1 className="text-[16px] font-normal text-[#868a93]">
            {isShowMore === "View More" ? (
              <Link to={link}>{isShowMore}</Link>
            ) : (
              <>{isShowMore}</>
            )}
          </h1>
        </div>
      </div>
    </>
  );
};

export default UpperSection;

const handleFavorite = async (
  id,
  dispatch,

  setCheckFav,
  checkFav,
  SetIsFav
) => {
  const data = await dispatch(addFavoriteWorkOutVideo(id));
  if (data.payload.success) {
    const response = await dispatch(getFavWorkOutVideo());
    if (response?.payload?.success) {
      setCheckFav(!checkFav);
    }
  } else {
    SetIsFav(false);
  }

  return data;
};

const TooltipComponent = ({ firstIndexData }) => {
  return (
    <Tooltip
      className=""
      title={
        <>
          <div
            style={{
              background: "transparent",
              borderRadius: "10px",
              padding: "10px ",
              width: "300px",
            }}
          >
            <h2 className="text-black text-sm pb-3 ">
              You have not yet attempted this workout
            </h2>
            <div className="flex items-center gap-1 flex-wrap">
              {firstIndexData?.focus_type?.map((focusType, index) => (
                <span
                  className="text-[10px] font-thin rounded-2xl bg-[#b9b4f0] px-2 py-1"
                  key={index}
                >
                  {focusType}
                </span>
              ))}
            </div>
          </div>
        </>
      }
    >
      <div>
        <PiWarningCircleThin
          color="#868a93"
          className="transform rotate-180 text-xl cursor-pointer "
        />
      </div>
    </Tooltip>
  );
};
export { TooltipComponent };
const svg = (
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
    stroke="#9CA38F"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
);
