const LatestWorkOutSekelton = () => {
  return (
    <div className=" lg:max-w-[1240px] md:max-w-[100vw]  sm:max-w-[100vw] max-w-[100vw] py-[15 px] mx-auto px-[15px] over flex gap-5">
      <div className="w-[630px]">
        {[1].map((item, index) => (
          <div
            className="border border-slate-300 shadow rounded-xl p-2  my-3 "
            key={index}
          >
            <div className="animate-pulse">
              <div className=" flex gap-5">
                <div className="h-40 rounded bg-slate-200  w-6/12"></div>
                <div className="h-40 rounded w-6/12 ">
                  <h1 className="w-32 h-8 bg-slate-200 rounded-md"></h1>
                  <div className="mt-3 flex gap-3 ">
                    <p className="rounded-3xl w-16 h-6 bg-slate-200"></p>
                    <p className="rounded-3xl w-16 h-6 bg-slate-200"></p>
                    <p className="rounded-3xl w-16 h-6 bg-slate-200"></p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="flex gap-2 items-center justify-between mt-10 w-6/12">
                  <div className=" bg-slate-200 h-5 w-28 rounded"></div>
                  <div className="flex items-center gap-2">
                    <div className="rounded bg-slate-200 h-5 w-5"></div>
                    <div className="rounded bg-slate-200 h-5 w-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default LatestWorkOutSekelton;

{
  /*  
  grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr_1fr_1fr] md:grid-cols-1fr_1fr sm:grid-cols-1fr gap-5  
  <div className="flex gap-2 items-center">
              <div className=" bg-slate-200 h-5 w-20 rounded-md"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
              <div className="rounded-full bg-slate-200 h-5 w-5"></div>
            </div> */
}
