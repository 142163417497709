import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  WorkoutProgramChallengeState: {},
  loading: false,
  error: null,
};

export const getProgramChallengeHandler = createAsyncThunk(
  "getProgramChallengeHandler",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/getUniqueProgram/${id}`);

      return response?.data?.program;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const workoutProgramChallengeSlice = createSlice({
  name: "workoutProgramChallengeSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProgramChallengeHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getProgramChallengeHandler.fulfilled, (state, action) => {
      state.WorkoutProgramChallengeState = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getProgramChallengeHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default workoutProgramChallengeSlice.reducer;
