import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getFitnessSubscriptionStatusHandler } from "../../redux/slices/upgrade-plan/get-fitness-subscription-status";
import Navbar from "../../components/navbar";
import Loader from "../../components/global/Loader";
import Footer from "../../components/footer";
import HappyBoyAvatar from "../../assets/images/HappyBoyAvatar.png";
const PaymentSucess = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state?.auth?.profile?.content?.avatar);
  const { loading } = useSelector(
    (state) => state?.getFitnessSubscriptionSlice
  );
  useEffect(() => {
    dispatch(getFitnessSubscriptionStatusHandler());
  }, []);
  return (
    <>
      <Navbar />
      <div className="flex h-screen items-center justify-center w-full">
        {loading ? (
          <Loader />
        ) : (
          <div className="flex flex-col items-center jsutify-center gap-5">
            <img
               src={`https://api.mykilo.co.uk/avatar/${profile}`}
              alt="alternative text"
              className="h-[20rem]"
            />
            <p className="text-xl font-black"> Successfully Purchased</p>
            <Link to="/" className="bg-primary rounded-md p-2 text-white">
              Back to Home
            </Link>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default PaymentSucess;
