import React from "react";
import ReactModal from "react-modal";
import { ImCross } from "react-icons/im";

ReactModal.setAppElement("#root"); // Bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

const PopUpModel = ({ isOpen, onRequestClose, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          top: "50%",
          position: "relative",
          left: "50%",
          right: "auto",
          zIndex: "100",

          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          height: "80%",
          padding: "30px 40px 0px 40px",
        },
      }}
    >
      <ImCross
        size={20}
        className="absolute z-70 top-3 right-1 cursor-pointer"
        onClick={onRequestClose}
      />
      <div className="" style={{ height: "94%" }}>
        {children}
      </div>
    </ReactModal>
  );
};

export default PopUpModel;
