import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  workOutProgram: {},
  loading: false,
  error: null,
};

export const getWorkProgramApi = createAsyncThunk(
  "getWorkProgramApi",
  async (category, { rejectWithValue }) => {
    try {
      // Assuming the API endpoint allows fetching a specific recipe by id
      const response = await axios.get(
        `public/ProgramsByParams/list/${category?.title}`
      );

      return {
        title: response.data?.title,
        data: {
          items: response.data?.items,
          description: category?.description,
          link: category?.link,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getWorkOutProgram = createSlice({
  name: "getWorkOutProgram",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getWorkProgramApi.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getWorkProgramApi.fulfilled, (state, action) => {
      const { title, data } = action.payload;
      state.workOutProgram[title] = data;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getWorkProgramApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getWorkOutProgram.reducer;
