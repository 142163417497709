import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../utils/api";
import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  getSearchWorkoutVideo: {},
  loading: false,
  error: null,
};

export const getSearchWorkoutVideoHandler = createAsyncThunk(
  "getSearchWorkoutVideoHandler",
  async (querry, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/public/searchingVideos?${querry}`);

      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    } 
  }
);
 
const getSearchWorkoutVides = createSlice({
  name: "getSearchWorkoutVides",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSearchWorkoutVideoHandler.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(getSearchWorkoutVideoHandler.fulfilled, (state, action) => {
      state.getSearchWorkoutVideo = action.payload;
      state.loading = false;
      state.error = null;
    });

    builder.addCase(getSearchWorkoutVideoHandler.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getSearchWorkoutVides.reducer;
