export const workoutVideoConstant = [
  "Latest_Workouts",
  "Most_Popular",
  "HIIT",
  "Abs",
  "Booty",
  "Dumbbell",
  "10_Mins",
  "20_Mins+",
  "Standing_Workouts",
  "No_Jumping",
  "No_Planks",
  "Burpee_Free",
  "Wrist_Friendly",
  "Yoga",
  "Pilates",
];
export const workOutCategories = [
  {
    title: "Latest_Workouts",
    description: "",
    link: "/workoutvideos/Latest_Workouts",
  },
  {
    title: "Most_Popular",
    description:
      "There are some of the most popular workout videos. Give them a try and see why people love these routines.",
    link: "/workoutvideos/Most_Popular",
  },
  {
    title: "HIIT",
    description:
      "Ready to get your heart pumping? These HIIT & cardio workouts will help you burn those calories!",
    link: "/workoutvideos/HIIT",
  },
  {
    title: "Abs",
    description:
      "If you're looking to work on that 6 pack, check out these ab and core workout routines!",
    link: "/workoutvideos/Abs",
  },
  {
    title: "Booty",
    description:
      "Want to grow a booty? There are equipment and non-equipment workouts to help you grow your glutes.",
    link: "/workoutvideos/Booty",
  },
  {
    title: "Dumbbell",
    description:
      "Check out these dumbbell workouts that you can do at home to build strength and get toned.",
    link: "/workoutvideos/Dumbbell",
  },
  {
    title: "10_Mins",
    description:
      "Want a quick workout? These videos are only 10 minutes long to help you get a quick and effective workout in.",
    link: "/workoutvideos/10_Mins",
  },
  {
    title: "20_Mins+",
    description:
      "If you prefer longer workouts, check out these 20+ minutes workouts.",
    link: "/workoutvideos/20_Mins+",
  },
  {
    title: "Standing_Workouts",
    description:
      "No mat? No problem. All of these workouts can be done while standing up.",
    link: "/workoutvideos/Standing_Workouts",
  },
  {
    title: "No_Jumping",
    description:
      "These videos have no jumping variations so it's apartment friendly with no jumping and no noise.",
    link: "/workoutvideos/No_Jumping",
  },
  {
    title: "No_Planks",
    description:
      "Not a fan of planks? These workouts are free of plank exercises.",
    link: "/workoutvideos/No_Planks",
  },
  {
    title: "Burpee_Free",
    description:
      "Hate burpees? These workouts do not have any burpees in them.",
    link: "/workoutvideos/Burpee_Free",
  },
  {
    title: "Wrist_Friendly",
    description:
      "Need to take it easy on the wrists? These workouts avoid planks or pressure on your wrists.",
    link: "/workoutvideos/Wrist_Friendly",
  },
  {
    title: "Yoga",
    description:
      "Find your inner peace and flexibility with these calming yoga routines, perfect for all levels.",
    link: "/workoutvideos/Yoga",
  },
  {
    title: "Pilates",
    description:
      "Strengthen your core and improve your posture with these gentle Pilates workouts, designed to be easy on your wrists.",
    link: "/workoutvideos/Pilates",
  },
];
