export const workOutParogramCategories = [
  {
    title: "Latest_Challenges",
    description: "",
    link: "/workout-program/latest-challenges",
  },
  {
    title: "Most_Popular",
    description: "",
    link: "/workout-program/most-popular",
  },
  {
    title: "Beginner_Friendly",
    description:
      "Looking to get started on your fitness journey? Try one of these beginner-friendly programs! These have shorter time commitments or have low-impact alternatives.      ",
    link: "/workout-program/beginner-friendly",
  },
  {
    title: "Moderate_To_Advanced",
    description:
      "If you're looking for something that pushes you a little harder, try any of these moderate to advanced challenges to help you progress further..      ",
    link: "/workout-program/moderate-to-advanced",
  },
  {
    title: "Weight_Loss",
    description:
      "Get started on your weight loss journey with one of these challenges that are high intensity and will get you sweating!      ",
    link: "/workout-program/weight-loss",
  },
  {
    title: "Abs",
    description:
      "Your abs will love you and hate you at the same time! Try out any one of these core focused workout programs.",
    link: "/workout-program/abs",
  },
  {
    title: "Booty_and_Legs",
    description:
      "If you're looking to work your lower body or grow your glutes, try out these leg and booty programs without equipment or with dumbbells and resistance bands.      ",
    link: "/workout-program/booty-and-legs",
  },
  {
    title: "Strength_Training",
    description:
      "If you're looking to work on your strength, check out these resistance based programs. We'd recommend having resistance bands and a variety of light.      ",
    link: "/workout-program/strength-training",
  },
  {
    title: "No_Equipment",
    description:
      "These programs can be done at home without the need for equipment like dumbbells or resistance bands!,      ",
    link: "/workout-program/no-equipment",
  },
];

export const cardArray = [
  {
    image: require("../assets/images/erik.jpg"),
    plan: "20 days",
    duration: "30 min/day",
    plantitle: "2022 Get Abs Challenge",
    programPoints: [
      {
        type: "Weight Loss, Full Body, Abs & Core",
        equipment: "Fitness Mat",
        releasedate: "July 2023",
      },
    ],
  },
  {
    image: require("../assets/images/2.jpg"),
    plan: "25 days",
    duration: "30 min/day",
    plantitle: "2022 Get Abs Challenge",
    programPoints: [
      {
        type: "Weight Loss, Full Body, Abs & Core",
        equipment: "Fitness Mat",
        releasedate: "July 2024",
      },
    ],
  },
  {
    image: require("../assets/images/3.jpg"),
    plan: "25 days",
    duration: "30 min/day",
    plantitle: "2022 Get Abs Challenge",
    programPoints: [
      {
        type: "Weight Loss, Full Body, Abs & Core",
        equipment: "Fitness Mat",
        releasedate: "July 2023",
      },
    ],
  },
  {
    image: require("../assets/images/1.jpg"),
    plan: "25 days",
    duration: "30 min/day",
    plantitle: "2021 2 Weeks Shred Challenge",
    programPoints: [
      {
        type: "Weight Loss, Full Body, Abs & Core",
        equipment: "Fitness Mat",
        releasedate: "July 2023",
      },
    ],
  },
  {
    image: require("../assets/images/1.jpg"),
    plan: "25 days",
    duration: "30 min/day",
    plantitle: "2021 2 Weeks Shred Challenge",
    programPoints: [
      {
        type: "Weight Loss, Full Body, Abs & Core",
        equipment: "Fitness Mat",
        releasedate: "July 2023",
      },
    ],
  },
];
