import React, { useEffect, useState } from "react";
import axios from "../../utils/api";
import { Link, useParams } from "react-router-dom";

const ViewBlog = ({ heading, recipeName, tag, tag2, function1 }) => {
  const [blogData, setBlogData] = useState([]);
  const {id}=useParams();
  console.log("🚀 ~ ViewBlog ~ id:", id)
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`/blog/getBlog/${id}`);
        console.log("🚀 ~ fetchBlogs ~ response:", response)
        if(response.status===200){
        setBlogData(response.data);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="max-w-[1240px] mx-auto px-4 pt-12">
      <div className="flex  justify-between items-center gap-4 pb-4">
        <h1 className="text-[#303033] font-medium	md:text-[16px] lg:text-[28px] leading-8 capitalize">
          {heading}
        </h1>
        <Link to="/blog">
          <button
            className="py-1 px-4 lg:px-6 md:px-4 text-[#fff] font-medium	border border-transparent hover:border hover:border-[#9C3022] hover:bg-transparent hover:text-[#303033]  text-[12px] lg:text-[18px]  sm:text-[12px] leading-8 capitalize  bg-[#9C3022] rounded-full  "
            // onClick={function1}
          >
            Back
          </button>
        </Link>
      </div>

    
        <div className="grid grid-cols-[1fr] lg:grid-cols-[1fr_1fr] md:grid-cols-[1fr_1fr] gap-6 ">
          <img
            className="hidden lg:block md:block w-[462px] h-[628px] "
            src={`https://api.mykilo.co.uk/blogImages/${blogData?.image}`}
            alt="pancake"
          />
          <div className="lg:p-0 md:p-12 sm:p-12 p-8">
            <div className="flex lg:justify-start md:justify-start justify-center">
              <button className="text-[14px] text-[#303033] cursor-default font-normal mb-7  rounded-full px-12 py-2 mr-4 capitalize">
                <Link to="/blog">{tag2}</Link>
              </button>
            </div>
            <h1 className="text-[28px] mb-2 font-medium text-[#303033] lg:pr-[92px] md:pr-[92px] pr-0">
              {/* Chocolate sweet potato pancakes */} {blogData?.title}
            </h1>
            <h2 className="text-[20px] mb-7 font-medium text-[#303033] lg:pr-[92px] md:pr-[92px] pr-0">
              {/* Chocolate sweet potato pancakes */} {blogData?.subtitle}
            </h2>
            <p className="text-[18px] mb-12  leading-8 font-normal text-[#303033] "  dangerouslySetInnerHTML={{__html: blogData?.description}}>
           
            </p>
          <Link to="/blog">  <div className="flex justify-center lg:justify-start  md:justify-start items-center ">
              <p className="text-[16px] text-[#303033] leading-8 font-medium pr-4">
               {tag}
              </p>
              {/* <img
                src={require("../../../assets/images/arrow.png")}
                alt="pancake"
              /> */}
            </div>
            </Link> 
          </div>
        </div>
     
    </div>
  );
};

export default ViewBlog;
