
// function formatMainName(name) {
//   if (name === "weight_shape") {
//     return name
//       .replace(/_/g, "/") // Replace underscores with spaces
//       .toLowerCase() // Convert to lowercase
//       .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
//   } else {
//     return name
//       .replace(/_/g, " ") // Replace underscores with spaces
//       .toLowerCase() // Convert to lowercase
//       .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
//   }
// }

function GenerateEmailTemplate(
  formData,
  selectedLanguage
) {
  console.log(
    "🚀 ~ generateEmailTemplate ~ selectedLanguage:",
    selectedLanguage
  );
  console.log("🚀 ~ generateEmailTemplate ~ formData:", formData);

  const labels = {
    name1: {
      english: "Name:",
      french: "Nom:",
      italian: "Nome:",
    },
    litresPerDay: {
      english: "litres/day",
      french: "litres/jour",
      italian: "litri/giorno"
    },
    gramsPerDay: {
      english: "g/day",
      french: "g/par jour",
      italian: "g/giorno"
    },
    breakfast: {
      english: "Breakfast:",
      french: "Petit-déjeuner:",
      italian: "Colazione:",
    },
    wellnessRoutine: {
      english: "WELLNESS ROUTINE",
      french: "ROUTINE DE BIEN-ÊTRE",
      italian: "ROUTINA DI BENESSERE",
    },
    water: {
      english: "Water:",
      french: "Eau:",
      italian: "Acqua:",
    },
    exercise: {
      english: "Exercise:",
      french: "Exercice:",
      italian: "Esercizio:",
    },
    exercises_type: {
      english: "Exercise Type:",
      french: "Type d’exercice:",
      italian: "Tipo di esercizio:",
    },
    nutrition: {
      english: "Nutrition:",
      french: "Nutrition:",
      italian: "Nutrizione:",
    },
    quick_meal: {
      english: "Quick Meal:",
      french: "Repas rapide:",
      italian: "Pasto veloce:",
    },
    dietary_options: {
      english: "Dietary Options:",
      french: "Options alimentaires:",
      italian: "Opzioni alimentari:",
    },
    energy: {
      english: "Energy:",
      french: "Énergie:",
      italian: "Energia:",
    },
    struggles: {
      english: "Struggles:",
      french: "Difficultés:",
      italian: "Difficoltà:",
    },
    goal_weight: {
      english: "Goal Weight:",
      french: "Poids cible:",
      italian: "Peso obiettivo:",
    },
    current_weight: {
      english: "Current Weight:",
      french: "Poids actuel:",
      italian: "Peso attuale:",
    },
    weight_shape: {
      english: "Weight/Shape:",
      french: "Poids/Forme:",
      italian: "Peso/Forma:",
    },
    protein_50_80g: {
      english: "50-80g Protein:",
      french: "50-80g Protéine:",
      italian: "50-80g Proteine:",
    },
    smoking: {
      english: "Smoking:",
      french: "Fumer:",
      italian: "Fumare:",
    },
    onlineWellnessProfile: {
      english: "Online Wellness Profile",
      french: "Profil de bien-être en ligne",
      italian: "Profilo Benessere Online",
    },
    age: {
      english: "Age:",
      french: "Âge:",
      italian: "Età:",
    },
    weight: {
      english: "WEIGHT",
      french: "POIDS",
      italian: "PESO",
    },
    current: {
      english: "Current:",
      french: "Actuel:",
      italian: "Attuale:",
    },
    goal: {
      english: "Goal:",
      french: "Objectif:",
      italian: "Obiettivo:",
    },
    targets: {
      english: "TARGETS",
      french: "OBJECTIFS",
      italian: "OBIETTIVI",
    },
    protien: {
      english: "Protein:",
      french: "Protéine:",
      italian: "Proteina:",
    },
    spend: {
      english: "SPEND",
      french: "DÉPENSE",
      italian: "SPESA",
    },
    takeaways: {
      english: "Takeaways:",
      french: "À emporter:",
      italian: "Da asporto:",
    },
    alcohol: {
      english: "Alcohol:",
      french: "Alcool:",
      italian: "Alcol:",
    },
    dietaryRequirements: {
      english: "DIETARY REQUIREMENTS",
      french: "EXIGENCES DIÉTÉTIQUES",
      italian: "REQUISITI DIETETICI",
    },
    otherInformation: {
      english: "OTHER INFORMATION",
      french: "AUTRES INFORMATIONS",
      italian: "ALTRE INFORMAZIONI",
    },
  };


  // Initialize variables for specific values
  let name,
    age,
    current_weight,
    goal_weight,
    commitment,
    gender,
    dietary,
    alcoholSpend,
    takeawaysSpend,
    energyBoostsSpend;
  // Extract specific values from formData
  formData.forEach((item) => {
    switch (item.mainName) {
      case "age":
        age =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "name":
        name =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "current_weight":
        current_weight =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "goal_weight":
        goal_weight =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "commitment":
        commitment =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "gender":
        gender =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "dietary":
        dietary =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "alcohol_spend":
        alcoholSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "takeaways_spend":
        takeawaysSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      case "energy_spend":
        energyBoostsSpend =
          typeof item.answer === "object"
            ? JSON.stringify(item.answer)
            : item.answer;
        break;
      default:
        break;
    }
  });

  const filteredItems = formData.filter(
    (item) =>
      ![
        "age",
        "goal",
        "commitment",
        "gender",
        "dietary",
        "energy_spend",
        "takeaways_spend",
        "alcohol_spend",
      ].includes(item.mainName)
  );

  // Split the filtered items into two arrays
  const lastSixItems = filteredItems.slice(-6); // Get the last 6 items
  const remainingItems = filteredItems.slice(1, -6); // Get all items except the last 6

  // Function to map and format each item
  const mapItemsToHtml = (items) =>
    items
      .map((item) => {
        const { mainName, answer, suggestions } = item;
        const formattedMainName = formatMainName(mainName, selectedLanguage);

        const answerString =
          typeof answer === "object" ? JSON.stringify(answer) : answer;
        const suggestionsString =
          typeof suggestions === "object"
            ? JSON.stringify(suggestions)
            : suggestions;

        return `
    <p>
      <span style="font-weight: bold;">${formattedMainName}</span> ${
          formattedMainName === "Breakfast:" ||
          formattedMainName === "Petit-déjeuner:" ||
          formattedMainName === "Colazione:" ||
          formattedMainName === "Eau:" ||
          formattedMainName === "Acqua:" ||
          formattedMainName === "Water:"
            ? `${answerString}/7`
            : answerString
        } ${suggestions ? ` - ${suggestions}` : ""}
    </p>
  `;
      })
      .join("");

  // Map the remaining items to HTML
  const wellnessItemsHtml = mapItemsToHtml(remainingItems);

  // Map the last 6 items to HTML
  const lastSixItemsHtml = mapItemsToHtml(lastSixItems);
  function formatMainName(name, selectedLanguage) {
    if (name === "weight_shape") {
      return (
        labels[name]?.[selectedLanguage] ||
        name
          .replace(/_/g, "/") // Replace underscores with slashes for 'weight_shape'
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
      ); // Capitalize the first letter of each word
    } else {
      return (
        labels[name]?.[selectedLanguage] ||
        name
          .replace(/_/g, " ") // Replace underscores with spaces
          .toLowerCase()
          .replace(/\b\w/g, (char) => char.toUpperCase())
      ); // Capitalize the first letter of each word
    }
  }
  const noneTranslation = {
  english: "None",
  italian: "Nessuna",
  french: "Aucun"
};
const noneText = noneTranslation[selectedLanguage] || noneTranslation?.english;
  // Complete email template HTML
  let htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Wellness Profile</title>
</head>
<body style=" background-image: url('https://api.mykilo.co.uk/cover/evaluationbg2.388d2ca3433925a628cf.png'); background-size: cover; background-position: center; background-repeat: no-repeat; margin: 0; font-family: Arial, sans-serif; background-color: #535252; color: white; padding: 20px; width: 1300px; margin: 50px auto; border: 1px solid #C2BFBF; box-sizing: border-box;">

    <div style="display: grid; grid-template-columns: 2fr 1fr 1fr; grid-gap: 10px; padding: 5px;">
        <!-- Header Section -->
      <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px; ">
 
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <img src="https://dev.mykilo.co.uk/static/media/Mykillo-New-Logo.20c123373bc6b3b11ef0.png" alt="Company Logo" style="max-width: 150px; height: auto;" />
    <h5 style="background-color:white; width: fit-content; color: black; margin-top: 0px; padding: 10px 20px; border-radius: 10px; font-size: 16px; margin-left: 20px; font-weight: bold; border:1px solid #9C3022;">${
      labels["onlineWellnessProfile"][selectedLanguage]
    }</h5>
  </div>
  

  <div style="display: flex; justify-content: space-between; align-items: center;">
    <p style="font-weight: bold; ">${
      labels["name1"][selectedLanguage]
    } <span style="color: black; font-weight: normal;">${name}</span></p>
    <p style="line-height: 1.5;"><span style="font-weight: bold;">${labels["age"][selectedLanguage]}</span> ${age}</p>
  </div>
</div>


        <!-- Weight Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color: white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">${
              labels["weight"][selectedLanguage]
            }</div>
            <p><span style="font-weight: bold;"> ${labels["current"][selectedLanguage]} </span> ${current_weight}</p>
            <p><span style="font-weight: bold;">${labels["goal"][selectedLanguage]} </span> ${goal_weight}</p>
        </div>

        <!-- Targets Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color: white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">${
              labels["targets"][selectedLanguage]
            }</div>
            <p><span style="font-weight: bold;">${labels["water"][selectedLanguage]}</span> 2.3 ${labels["litresPerDay"][selectedLanguage]}</p>
            <p><span style="font-weight: bold;">${labels["protien"][selectedLanguage]}</span> 52.5 - 98${labels["gramsPerDay"][selectedLanguage]}</p>
        </div>
    </div>

    <div style="display: grid; grid-template-columns: 2fr 1fr; grid-gap: 10px; padding: 5px;">
        <!-- Wellness Routine Section -->
        <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
            <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022">${
              labels["wellnessRoutine"][selectedLanguage]
            }</div>
            <div>
                ${wellnessItemsHtml}
            </div>
        </div>

        <!-- Right Column for Spend, Dietary Requirements, and Other Information -->
        <div style="display: flex; flex-direction: column; gap: 10px;">
            <!-- Spend Section -->
            <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 8px; font-weight: bold; border:1px solid #9C3022 "> ${labels["spend"][selectedLanguage]}</div>
                <div style="display: flex; flex-direction: column; margin-top:20px;">
                    <div style="display: flex; justify-content: left; gap:5px; font-size: 16px; margin: 4px 0;">
                        <span style="font-weight: bold;">${labels["takeaways"][selectedLanguage]}  </span>
                            <span> ${
                              takeawaysSpend ? "€" + takeawaysSpend : ""
                            }</span>
                    </div>
                    <div style="display: flex; justify-content: left; gap:5px; font-size: 16px; margin: 4px 0;">
                        <span style="font-weight: bold;">${labels["energy"][selectedLanguage]}  </span>
                        <span> ${
                          energyBoostsSpend ? "€" + energyBoostsSpend : ""
                        }</span>
                    </div>

                    <div style="display: flex; justify-content: left; gap:5px; font-size: 16px; margin: 4px 0;">
                        <span style="font-weight: bold;">${labels["alcohol"][selectedLanguage]} </span>
                       
                         <span> ${alcoholSpend ? "€" + alcoholSpend : ""}</span>
                    </div>
                 
               
                </div>
            </div>

            <!-- Dietary Requirements -->
            <div style="background-color: white; min-height:200px; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 14px; font-weight: bold; border:1px solid #9C3022">${labels["dietaryRequirements"][selectedLanguage]}</div>
                <p> ${dietary ? dietary : "None"}</p>
            </div>

            <!-- Other Information -->
            <div style="background-color: white; padding: 15px; border: 1px solid #C2BFBF; color: black; font-size: 16px;">
                <div style="background-color:white; width: fit-content; color: black; padding: 10px 30px; border-radius: 10px; font-size: 20px; margin-bottom: 14px; font-weight: bold; border:1px solid #9C3022">${labels["otherInformation"][selectedLanguage]}</div>
                <!-- Add content as needed -->
                <p>${noneText}</p>
            </div>
        </div>


    </div>
<div style="background-color: white; padding: 15px; margin-top: 5px; border: 1px solid #C2BFBF; color: black; font-size: 16px; margin: 5px;">
            
            <div>
                ${lastSixItemsHtml}
            </div>
        </div>
</body>
</html>




  `;

  return htmlContent;
}

export default GenerateEmailTemplate;
