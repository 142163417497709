import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link, useLocation } from "react-router-dom";
import { workOutParogramCategories as workOutProgramMenu } from "../../utils/workoutProgramContant";
import { menuItems as recipeMenu } from "../../utils/recipesConstant";
import { workOutCategories as workOutMenu } from "../../utils/workOutVideoConstant";
import { Routes, Route, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BrowseByCollectionBtn = () => {
  let location = useLocation();
  const params = useParams();
  const [selectedItem, setSelectedItem] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isLgScreen, setIsLgScreen] = useState(false);

  const param =
    params["workoutvideos-category"] || params["workoutprogram-category"];

  const menuArray =
    location.pathname.startsWith("/workoutvideos") ||
    location.pathname.startsWith("/favorites-videos")
      ? workOutMenu
      : location.pathname.startsWith("/recipes") ||
        location.pathname.startsWith("/favorite-recipes") ||
        location.pathname.startsWith("/favorite-recipes/:folderName")
      ? recipeMenu
      : location.pathname.startsWith("/workout-program")
      ? workOutProgramMenu
      : [];
  const capitalizeWords = (str) => {
    return str
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  useEffect(() => {
    if (params["workoutvideos-category"]) {
      setSelectedItem(param.replace(/_/g, " "));
    } else if (params["workoutprogram-category"]) {
      setSelectedItem(capitalizeWords(param));
    }
  }, [param, params]);

  const handleMenuItemClick = (menuItem) => {
    setSelectedItem(menuItem);
  };
  return (
    <div>
      {(!isSearchOpen || !isLgScreen) && (
        <Menu
          as="div"
          className="relative inline-block text-left  rounded-full"
        >
          <div>
            <Menu.Button className="inline-flex ml-3 mb-2 justify-center gap-x-1.5 group hover:bg-[#9C3022] rounded-full bg-white px-3 py-1 text-sm font-semibold text-[#868a93] shadow-sm ring-1 ring-inset hover:text-white ring-gray-300 ">
              <span>
                {selectedItem
                  ? selectedItem?.replace(/_/g, " ")
                  : "Browse by Collection"}
              </span>
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400 group-hover:text-white"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-2 z-10 mt-2 w-[10.5rem] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {menuArray?.map((menuItem, index) => (
                <div key={index} className="">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={menuItem.link}
                        onClick={() =>
                          handleMenuItemClick(
                            menuItem?.title?.replace(/_/g, " ")
                          )
                        }
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : selectedItem === menuItem
                            ? "bg-[#9C3022] text-white"
                            : "text-gray-700",
                          "block px-2 py-2 text-sm"
                        )}
                      >
                        {menuItem?.title?.replace(/_/g, " ")}
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
};

export default BrowseByCollectionBtn;
