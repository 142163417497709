import React from 'react'
import BlogDetailComponent from '../../components/blog-detail-component'

const BlogDetail = () => {
  return (
    <div>
      <BlogDetailComponent />
    </div>
  )
}

export default BlogDetail
